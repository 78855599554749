<div class="container-fluid">
    <app-menu> </app-menu>

    <div class="container-lg blever backdeign mt-3 " >
        <div class="container-fluid whiteback p-2">
        
            <p><strong>А. Въведение<br> </strong></p>
            <ol>
                <li style="list-style-type: none;">
                    <ol>
                        <li>Поверителността на уебсайта ни е много важна за нас и сме ангажирани да я пазим. Тази полица
                            обяснява какво ще направим с личните ви данни.</li>
                        <li>Съгласието за нашата употребата на бисквитки в съответствие с условията на тази полица,
                            когато посетите сайта ни за пръв път, ни позволява да използваме бисквитки всеки път, в
                            който посещавате уебсайта ни.</li>
                    </ol>
                </li>
            </ol>
            <p><strong>В. Събиране на лични данни<br> </strong>Следните видове лична информация може да бъдат събирани,
                съхранявани и използвани:</p>
            <ol>
                <li style="list-style-type: none;">
                    <ol>
                        <li>информация за компютъра ви, включваща вашия IP адрес, географско местоположение, тип и
                            версия на браузъра ви и операционна система;</li>
                        <li>информация за посещенията ви и употребата на този уебсайт, включително референтния източник,
                            продължителност на посещение, гледания на страници и уебсайт навигация;</li>
                        <li>информация като имейл адреса ви, която въвеждате, когато се регистрирате в нашия уебсайт;
                        </li>
                        <li>информация, която въвеждате, когато създавате профил в нашия уебсайт – например името ви,
                            профилна снимка, пол, рождена дата, семеен статус, интереси и хобита, образование и заетост;
                        </li>
                        <li>информация като името ви и имейл адреса ви, които сте въвели, за да настроите абонаменти към
                            нашите имейли и/или бюлетини;</li>
                        <li>информация, която въвеждате, докато използвате услугите на нашия уебсайт;</li>
                        <li>&nbsp;информация, генерирана докато използвате нашия уебсайт, включително кога, колко често
                            и при какви обстоятелства го използвате;</li>
                        <li>информация, свързана с всичко, което закупувате, услугите, които използвате или трансакции,
                            които правите чрез нашия уебсайт, които съдържат вашето име, адрес, телефонен номер, имейл
                            адрес и данни на кредитната карта;</li>
                        <li>информация, която публикувате в нашия уебсайт, с намерението да се публикува в интернет,
                            която включва потребителското ви име, профилна снимка и съдържанието на публикациите ви;
                        </li>
                        <li>информация, съдържаща се във всяка комуникация с нас, изпратена ни чрез имейл или чрез
                            уебсайта ни, включително съдържанието на комуникацията и метаданните;</li>
                        <li>всяка друга лична информация, която ни изпращате.</li>
                    </ol>
                </li>
            </ol>
            <p>Преди да ни разкриете личните данни на друг човек, трябва да получите съгласието му за това разкритие и
                за обработката на тази лична информация в съответствие с тази полица</p>
            <p><strong>Г. Използване на личната ви информация</strong></p>
            <p>Лична информация, подадена до нас чрез уебсайта ни, ще бъде използвана за целите, уточнени в тази полица
                или в съответните страници на уебсайта. Може да използваме личната ви информация за:</p>
            <ol>
                <li style="list-style-type: none;">
                    <ol>
                        <li>администриране на нашите уебсайт и бизнес;</li>
                        <li>персонализиране на уебсайта ни за вас;</li>
                        <li>активиране на употребата ви на услуги, налични в уебсайта ни;</li>
                        <li>да ви изпращаме артикули, закупени чрез уебсайта ни;</li>
                        <li>доставяне на услуги, закупени чрез уебсайта ни;</li>
                        <li>да ви изпращаме заявления, фактури и напомняния за плащане и събиране на плащания от вас;
                        </li>
                        <li>да ви изпращаме немаркетингови рекламни комуникации;</li>
                        <li>да ви изпращаме имейл известия, които конкретно сте заявили;</li>
                        <li>да ви изпращаме нашия имейл бюлетин, ако сте го заявили (можете да ни информирате по всяко
                            време, ако вече не желаете да получавате бюлетин);</li>
                        <li>да ви изпращаме маркетингови комуникации, свързани с нашия бизнес или бизнесите на
                            внимателно подбрани трети лица, които смятаме, че биха представлявали интерес за вас, чрез
                            публикация или, ако конкретно сте се съгласили с това, чрез имейл или подобен канал (можете
                            да ни информирате по всяко време, ако вече не желаете да получавате маркетингови
                            комуникации);</li>
                        <li>осигуряване на статистическа информация за потребителите ни на трети лица (но тези трети
                            лица няма да могат да идентифицират отделните потребители от тази информация);</li>
                        <li>справяне със запитвания и оплаквания, направени от или за вас, които са свързани с уебсайта
                            ни;</li>
                        <li>предпазване на уебсайта ни и предотвратяване на измама;</li>
                        <li>потвърждение на съгласие с нашите правила и условия, ръководени от употребата на уебсайта ни
                            (включително показването на лични съобщения, изпратени чрез услугата на сайта ни за лични
                            съобщения)</li>
                        <li>други цели.</li>
                    </ol>
                </li>
            </ol>
            <p>Ако предоставите лична информация за публикуване в уебсайта ни, ние ще публикуваме и в противен случай ще
                използваме тази информация в съответствие с разрешението, което ни давате.</p>
            <p>Личните ви настройки могат да се използват за ограничаване на публикуването на вашата информация в
                уебсайта ни и могат да бъдат регулирани с помощта на управлението на сайта ни.</p>
            <p>Ние няма да предоставим личната ви информация на трети лица за техния или нечий друг директен маркетинг
                без вашето изрично съгласие.</p>
            <p><strong>Д. Оповестяване на лични данни<br> </strong></p>
            <p>Може да оповестим личните ви данни на всеки от нашите служители, застрахователи, професионални съветници,
                агенти, доставчици или подизпълнители, както е необходимо за целите, изложени в тази полица.</p>
            <p>Може да оповестим личните ви данни на всеки член от групата на нашите компании (това означава нашите
                дъщерни дружества, нашата крайна холдингова компания и всичките ѝ дъщерни дружества), както е необходимо
                за целите, изложени в тази полица.</p>
            <p>Може да оповестим личната ви информация:</p>
            <ol>
                <li style="list-style-type: none;">
                    <ol>
                        <li>до степента, в която сме длъжни да го направим по закон;</li>
                        <li>&nbsp;във връзка с всеки процес или вероятни съдебни производства;</li>
                        <li>&nbsp;за да установим, упражним или защитим нашите права (включително осигуряване на
                            информация на други лица за целите на предотвратяване на измама и намаляване на кредитен
                            риск);</li>
                        <li>на купувача (или вероятния купувач) за всеки бизнес или актив, който ние продаваме (или
                            възнамеряваме да продадем);</li>
                        <li>&nbsp;на всеки човек, който вярваме, че може да с яви пред съд или друг подобен орган, за
                            оповестяване на тази лична информация, където според нашето разумно мнение подобен съд или
                            орган може да нареди оповестяване на тази лична информация.</li>
                    </ol>
                </li>
            </ol>
            <p>Освен в случаите, посочени в тази полица, ние няма да предоставим личната ви информация на трети лица.
            </p>
            <p><strong>Е. Международни трансфери на данни<br> </strong></p>
            <ol>
                <li style="list-style-type: none;">
                    <ol>
                        <li>Информацията, която сме събрали, може да бъде съхранявана, обработена и прехвърляна между
                            всяка една от държавите, с които работим, за да можем да използваме съответната информация в
                            съответствие с тази полица.</li>
                        <li>Информацията, която сме събрали, може да бъде прехвърлена на следните държави, които нямат
                            права за защита на данните, подобни на тези, които са в сила в Европейската Икономическа
                            Зона: Съединените Американски Щати, Русия, Япония, Китай и Индия.</li>
                        <li>Личната информация, която публикувате или предоставяте в публикации в нашия уебсайт, може да
                            бъде достъпна чрез интернет по целия свят. Ние не можем да предотвратим употребата или
                            злоупотребата на подобна информация от други.</li>
                        <li>Вие изрично се съгласявате с трансферите на лични данни, описани в този раздел Е.</li>
                    </ol>
                </li>
            </ol>
            <p><strong>Ж. Запазване на лична информация<br> </strong></p>
            <ol>
                <li style="list-style-type: none;">
                    <ol>
                        <li>&nbsp;Този раздел Ж излага нашите политики и процедури за запаметяване на данни, които са
                            разработени да подпомагат да гарантираме, че се съобразяваме с правните си задължения за
                            запаметяване и изтриване на лична информация.
                        </li>
                        <li>Личната информация, която обработваме за каквато и да е цел или цели, няма да бъде
                            съхранявана за по-дълъг период от нужния за съответната цел или цели.
                        </li>
                   
                
                        <li>Въпреки другите разпоредби в Секция Ж ще запаметим документи (включително електронни
                            документи), съдържащи лични данни:
                            <ol>
                                <li>&nbsp;до степента, в която законът изисква от нас;</li>
                                <li>ако вярваме, че документите може да са свързани с вероятни съдебни производства;
                                </li>
                                <li>за да установим, упражним или защитим нашите права (включително осигуряване на
                                    информация на други лица с цел предотвратяване на измама или намаляване на кредитен
                                    риск).</li>
                            </ol>
                        </li>
                    </ol>
                </li>
            </ol>
            <p><strong>З. Защита на личната ви информация</strong></p>
            <ol>
                <li style="list-style-type: none;">
                    <ol>
                        <li>Ще предприемем разумни технически и организационни мерки за предотвратяване на загуба,
                            злоупотреба или промяна на личната ви информация.</li>
                        <li>Ще съхраняваме цялата лична информация, която осигурявате на нашите сигурни (защитени от
                            парола и защитна стена) сървъри.</li>
                        <li>Всички електронни парични трансакции, въведени чрез нашия уебсайт, ще бъдат защитени от
                            криптираща технология.</li>
                        <li>Вие потвърждавате, че предаването на информация по интернет само по себе си е несигурно, и
                            ние не можем да гарантираме сигурността на данни, изпратени по интернет.</li>
                        <li>Вие сте отговорни за конфиденциалното съхранение на паролата, която използвате за достъп до
                            нашия сайт – ние няма да ви попитаме за вашата парола (освен когато се вписвате в уебсайта
                            ни).</li>
                    </ol>
                </li>
            </ol>
            <p><strong>И. Изменения<br> </strong><br> От време на време може да актуализираме тази полица, като
                публикуваме нова версия в нашия уебсайт. Трябва да проверявате тази страница, за да се уверите, че
                разбирате всички промени на полицата. Може да ви известим за промените в тази полица, като ви изпратим
                имейл или чрез личната система за съобщения на уебсайта ни.</p>
            <p><strong>Й. Вашите права<br> </strong><br> Можете да ни инструктирате да ви осигурим всяка лична
                информация, която сме запаметили за вас – осигуряването на подобна информация ще бъде предмет на
                следните:</p>
            <ol>
                <li style="list-style-type: none;">
                    <ol>
                        <li>&nbsp;Снабдяването с подходящи доказателства за вашата самоличност (ПРИЛОЖЕТЕ ТЕКСТ, ЗА ДА
                            СЕ ОТРАЗИ В ПОЛИЦАТА ВИ за тази цел обикновено приемаме копие на паспорта ви, заверено от
                            нотариус, плюс оригинално копие на битова сметка, показваща настоящия ви адрес).</li>
                    </ol>
                </li>
            </ol>
            <p>Можем да удържим лична информация, която сте поискали, до степента, позволена от закона.</p>
            <p>По всяко време можете да ни инструктирате да не обработваме личната ви информация за маркетингови цели.
            </p>
            <p>На практика или изрично ще се съгласите предварително за това да използваме личната ви информация за
                маркетингови цели, или ние ще ви осигурим възможност да се откажете от това да използваме личната ви
                информация за маркетингови цели.</p>
            <p><strong>К. Уебсайтове на трети страни<br> </strong><br> Нашият уебсайт включва хибервръзки към уебсайтове
                на трети страни и подробности за тях. Ние нямаме контрол и не сме отговорни за политиките за
                поверителност и практиките на трети страни.</p>
            <p><strong>Л. Актуализиране на информация<br> </strong><br> Молим да ни уведомите, ако личната ви
                информация, която съхраняваме, има нужда да бъде поправена или актуализирана.</p>
            <p><strong>М. Бисквитки<br> </strong><br> Нашият уебсайт използва бисквитки. Бисквитката е файл, който
                съдържа идентификатор (низ от букви и цифри), който се изпраща от уеб сървър към уеб браузър и се
                съхранява от браузъра. След това идентификаторът се изпраща обратно към сървъра всеки път, в който
                браузъра поисква страница от сървъра. Бисквитките могат да бъдат или „настойчиви“ бисквитки, или
                бисквитки „за сесия“: настойчивата бисквитка ще бъде съхранявана от уеб браузър и ще остане валидна до
                срока си на годност, освен ако не бъде изтрита от потребителя преди този срок да изтече; от друга
                страна, бисквитката за сесия ще изтече в края на потребителската сесия, когато уеб браузърът бъде
                затворен. Обикновено бисквитките не съдържат никаква информация, която може да идентифицира потребителя,
                но личната ви информация, която ние съхраняваме, може да бъде свързана с информацията, която се
                съхранява и се получава от бисквитките. ИЗБЕРЕТЕ ПОДХОДЯЩАТА ФРАЗА Ние използваме само бисквитки за
                сесия / само настойчиви бисквитки / бисквитки и настойчиви, и за сесия, в нашия уебсайт.</p>
            <ol>
                <li style="list-style-type: none;">
                    <ol>
                        <li>Имената на бисквитките, които използваме в уебсайта ни, и целта, поради която те се
                            използват, са изложени отдолу:<ol type="1">
                                <li>използваме Google Analytics и Adwords в уебсайта си, за да разпознаваме компютър,
                                    когато един потребител ИЗБРОЙТЕ ВСИЧКИ ПРИЧИНИ, ПОРАДИ КОИТО САЙТА ВИ ИЗПОЛЗВА
                                    БИСКВИТКИ посещава уебсайта / следи потребителите, докато те управляват уебсайта /
                                    подобрява използваемостта на уебсайта / анализира употребата на уебсайта /
                                    администрира уебсайта / предотвратява измама, за да подобри сигурността на уебсайта
                                    / персонализира уебсайта за всеки потребител / цели се в рекламите, които може да не
                                    са в интерес на конкретните потребители / обяснете вашата цел(и);</li>
                            </ol>
                        </li>
                        <li>Повечето браузъри ви позволяват да откажете приемането на бисквитки – например:<ol type="1">
                                <li>в Internet Explorer (версия 10) можете да блокирате бисквитки, използвайки наличните
                                    настройки за боравене с надхвърлянето, като натиснете “Tools (Инструменти)”,
                                    “Internet Options (Интернет опции)”, “Privacy (Поверителност)” и след това “Advanced
                                    (Специални)”;</li>
                                <li>във Firefox (версия 24) можете да блокирате всички бисквитки като натиснете “Tools”,
                                    “Options”, “Privacy”, избирайки “Use custom settings for history (Използвай
                                    персонализирани настройки за историята)” от спускащото меню и като премахнете
                                    отметката на “Accept cookies from sites (Приеми бисквитки от сайтовете)”;</li>
                                <li>в Chrome (версия 29) можете да блокирате всички бисквитки като влезете в менюто
                                    “Customize and control (Персонализиране и контрол)” и натиснете “Settings
                                    (Настройки)”, “Show advanced settings (Покажи специализирани настройки)” и “Content
                                    settings (Настройки на съдържанието” и след това изберете “Block sites from setting
                                    any data (Блокирай задаването на всякакви данни от сайтовете” под заглавието
                                    “Cookies (Бисквитки)”.</li>
                            </ol>
                        </li>
                    </ol>
                </li>
            </ol>
            <p>Блокирането на всички бисквитки ще има отрицателно влияние върху използваемостта на много сайтове. Ако
                блокирате бисквитките, няма да можете да използвате всички свойства на уебсайта ни.</p>
            <p style="padding-left: 40px;">3. Можете да изтриете бисквитки, които вече се съхраняват в компютъра ви –
                например:</p>
            <ol>
                <li style="list-style-type: none;">
                    <ol>
                        <li>&nbsp;в Internet Explorer (версия 10) трябва ръчно да изтриете файловете на бисквитките
                            (можете да намерите инструкции за това тук <a
                                href="http://support.microsoft.com/kb/278835">http://support.microsoft.com/kb/278835</a>
                            );</li>
                        <li>&nbsp;във Firefox (версия 24) можете да изтриете бисквитки като натиснете “Tools”, “Options”
                            и “Privacy”, след това да изберете “Use custom settings for history (Използвай
                            персонализирани настройки за историята)”, да натиснете “Show Cookies (Покажи бисквитки” и
                            след това да натиснете “Remove All Cookies (Премахни всички бисквитки”;</li>
                        <li>&nbsp;в Chrome (версия 29) можете да изтриете всички бисквитки като влезете в менюто
                            “Customize and control (Персонализиране и контрол)” и натиснете “Settings”, “Show advanced
                            settings” и “Clear browsing data (Изчисти данните за сърфирането”, след това изберете
                            “Delete cookies and other site and plug-in data (Изтрий бисквитки и всички други плъгин
                            данни и тези на сайта)” преди да натиснете “Clear browsing data.”</li>
                    </ol>
                </li>
            </ol>
            <p style="padding-left: 40px;">4. Изтриването на бисквитките ще има отрицателно влияние върху
                използваемостта на много уебсайтове.</p>
        </div>    
         

    </div>
    <app-scroll-to-top></app-scroll-to-top>
   
</div>