import { RequestsService } from './../../services/requests.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-olxredirect',
  templateUrl: './olxredirect.component.html',
  styleUrls: ['./olxredirect.component.css'],
})
export class OlxredirectComponent implements OnInit {
  constructor(private rout: Router, private req: RequestsService) {}

  ngOnInit(): void {}
  getOlxToken() {
    let testtok =
      'https://searchimot.cholev-agro.com/olxredirect?code=4f7355c2123f847130bbab7b3e79ece79b316de7&state=x93ld3v';
    let link = window.location.href;

    //za testove
    // let begin = testtok.indexOf('code=');
    // let halflink = testtok.substring(begin + 5);
    //
    let begin = link.indexOf('code=');
    let halflink = link.substring(begin + 5);

    let end = halflink.indexOf('&');
    let code = halflink.substring(0, end);
    if (code.length == 0) {
      this.rout.navigate(['/userpage']);
    } else {
      this.req.setTokenOlx(code).subscribe(
        (res: any) => {
          console.log(res.msg);
          if (res.msg == 'OK') {
            this.rout.navigate(['/userpage']);
          }
          console.log(code);
        },
        (err: any) => {
          console.log(err);
        }
      );
    }
  }
}
