import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-new-or-exist',
  templateUrl: './dialog-new-or-exist.component.html',
  styleUrls: ['./dialog-new-or-exist.component.css']
})
export class DialogNewOrExistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
