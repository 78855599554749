export class User{

    private userid: any;
    private token: any;
    private role: any;
    private name:any;
    private filterTipObqva:any;
    private filterGrad:any;
    private filterRaion:any;
    private filterTipImot:any;

    constructor(input:any){
        let v = Object.values(input);
        
        this.token = input.token;
        this.userid = input.userId;
        this.role = input.role;
        this.name = input.name;
        this.filterTipObqva=input.filterTipObqva;
        this.filterGrad = input.filterGrad;
        this.filterRaion=input.filterRaion;
        this.filterTipImot = input.filterTipImot;
    }

    
    getfilters(){

        let ret={
            filterTipObqva:this.filterTipObqva,
            filterGrad:this.filterGrad,
            filterRaion:this.filterRaion,
            filterTipImot:this.filterTipImot
        }
        return ret;
    }

    getuserid(){
        return this.userid;
    }

    getrole(){
        return this.role;
    }
    gettoken(){
        return this.token;
    }
    getname(){
        return this.name;
    }



}