import { SearchcatService } from './../../services/searchcat.service';
import { FormGroup, FormControl } from '@angular/forms';
import { RequestsService } from './../../services/requests.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-userpage',
  templateUrl: './userpage.component.html',
  styleUrls: ['./userpage.component.css'],
})
export class UserpageComponent implements OnInit {
  constructor(private req: RequestsService, private cat: SearchcatService) {}

  userdata: any;
  gradoblastcat: any[] = [];
  raioncat: any[] = [];
  typeimotcat: any[] = [];
  savedfilter = false;
  userfilter: any;
  isOlxtokenExpire = false;
  olxTokenExpireIn: any;
  tokens = new FormGroup({
    tokenOlx: new FormControl(''),
    tokenAlo: new FormControl(''),
  });
  favfilter1 = new FormGroup({
    tipObqva: new FormControl(''),
    grad: new FormControl(''),
    raion: new FormControl(''),
    tipImot: new FormControl(''),
  });
  favfilter2 = new FormGroup({
    tipObqva: new FormControl(''),
    grad: new FormControl(''),
    raion: new FormControl(''),
    tipImot: new FormControl(''),
  });

  ngOnInit(): void {
    this.gradoblastcat = this.cat.getgradoblast();
    this.typeimotcat = this.cat.gettypeimot();
    let id = localStorage.getItem('userid');
    this.req.getuserdate(id).subscribe(
      (result) => {
        this.userdata = result;

        this.req.getuserfilter(id).subscribe(
          (res) => {
            this.userfilter = res;
            this.favfilter1.setValue({
              tipObqva: this.userfilter.filterTipObqva,
              grad: this.userfilter.filterGrad,
              raion: this.userfilter.filterRaion,
              tipImot: this.userfilter.filterTipImot,
            });

            this.getraions(this.userfilter.filterGrad);
            this.req.getOlxTokenExpirein().subscribe(
              (resTok: any) => {
                let currentTimeInSeconds = Math.floor(Date.now() / 1000);
                //console.log(resTok.expireIn);

                if (currentTimeInSeconds > resTok.expireIn) {
                  //token is expired
                  this.isOlxtokenExpire = true;
                } else {
                  this.isOlxtokenExpire = false;

                  this.olxTokenExpireIn = Math.floor(resTok.expireIn * 1000);
                }
              },
              (err: any) => {
                console.log(err);
              }
            );
          },
          (err) => {
            console.log(err);
          }
        );
      },
      (err) => {
        console.log(err);
      }
    );
  }
  addOlxToken() {
    if (this.tokens.get('tokenOlx')?.value == null || this.tokens.get('tokenOlx')?.value == '') return;
    console.log(this.tokens.get('tokenOlx')?.value);
    this.req.setTokenOlx(this.tokens.get('tokenOlx')?.value).subscribe(
      (res: any) => {
        console.log(res);
        this.tokens.patchValue({
          tokenOlx: '',
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }
  olxTokens() {
    const variable = encodeURIComponent('https://searchimot.cholev-agro.com/olxredirect');
    let a = `https://www.olx.bg/oauth/authorize/?client_id=200148&response_type=code&state=x93ld3v&scope=read+write+v2&redirect_uri=${variable}`;
    let s = window.open(a, '_self');
    console.log(s);
  }
  changefilter() {
    if (this.userfilter.filterTipObqva != this.favfilter1.get('tipObqva')?.value) {
      this.savedfilter = true;
      return;
    }
    if (this.userfilter.filterGrad != this.favfilter1.get('grad')?.value) {
      this.savedfilter = true;

      return;
    }
    if (this.userfilter.filterRaion != this.favfilter1.get('raion')?.value) {
      this.savedfilter = true;

      return;
    }
    if (this.userfilter.filterTipImot != this.favfilter1.get('tipImot')?.value) {
      this.savedfilter = true;
      return;
    }
    this.savedfilter = false;
  }
  savefilter() {
    this.userfilter.filterTipObqva = this.favfilter1.get('tipObqva')?.value;
    this.userfilter.filterGrad = this.favfilter1.get('grad')?.value;
    this.userfilter.filterRaion = this.favfilter1.get('raion')?.value;
    this.userfilter.filterTipImot = this.favfilter1.get('tipImot')?.value;

    let pl = {
      id: localStorage.getItem('userid'),
      filterTipObqva: this.favfilter1.get('tipObqva')?.value,
      filterGrad: this.favfilter1.get('grad')?.value,
      filterRaion: this.favfilter1.get('raion')?.value,
      filterTipImot: this.favfilter1.get('tipImot')?.value,
    };

    this.req.setuserfilter(pl).subscribe(
      (res) => {
        this.savedfilter = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getraions(val: any) {
    val = val.toLowerCase();

    let t = this.userfilter.filterGrad;
    let k = t?.toLocaleLowerCase();

    if (k != val) {
      this.favfilter1.patchValue({
        raion: '',
      });
    }

    switch (val) {
      case 'област пловдив':
        {
          this.raioncat = this.cat.getPlovdivoblast();
        }
        break;
      case 'град пловдив':
        {
          this.raioncat = this.cat.getPlovdivgrad();
        }
        break;
      case 'град благоевград':
        {
          this.raioncat = this.cat.getBlagoevgradgrad();
        }
        break;
      case 'област благоевград':
        {
          this.raioncat = this.cat.getBlagoevgradoblast();
        }
        break;
      case 'град бургас':
        {
          this.raioncat = this.cat.getBurgasgrad();
        }
        break;
      case 'област бургас':
        {
          this.raioncat = this.cat.getBurgasoblast();
        }
        break;
      case 'град варна':
        {
          this.raioncat = this.cat.getVarnagrad();
        }
        break;
      case 'област варна':
        {
          this.raioncat = this.cat.getVarnaoblast();
        }
        break;
      case 'град велико търново':
        {
          this.raioncat = this.cat.getVelikoTyrnovograd();
        }
        break;
      case 'област велико търново':
        {
          this.raioncat = this.cat.getVelikotyrnovooblast();
        }
        break;
      case 'град видин':
        {
          this.raioncat = this.cat.getVidingrad();
        }
        break;
      case 'област видин':
        {
          this.raioncat = this.cat.getVidinoblast();
        }
        break;
      case 'град враца':
        {
          this.raioncat = this.cat.getVracagrad();
        }
        break;
      case 'област враца':
        {
          this.raioncat = this.cat.getVracaoblast();
        }
        break;
      case 'град габрово':
        {
          this.raioncat = this.cat.getGabrovograd();
        }
        break;
      case 'област габрово':
        {
          this.raioncat = this.cat.getGabrovooblast();
        }
        break;
      case 'град добрич':
        {
          this.raioncat = this.cat.getDobrichgrad();
        }
        break;
      case 'област добрич':
        {
          this.raioncat = this.cat.getDobrichoblast();
        }
        break;
      case 'град кърджали':
        {
          this.raioncat = this.cat.getKurdjaligrad();
        }
        break;
      case 'област кърджали':
        {
          this.raioncat = this.cat.getKurdjalioblast();
        }
        break;
      case 'град кюстендил':
        {
          this.raioncat = this.cat.getKustendilgrad();
        }
        break;
      case 'област кюстендил':
        {
          this.raioncat = this.cat.getKustendiloblast();
        }
        break;
      case 'град ловеч':
        {
          this.raioncat = this.cat.getLovechgrad();
        }
        break;
      case 'област ловеч':
        {
          this.raioncat = this.cat.getLovechoblast();
        }
        break;
      case 'град монтана':
        {
          this.raioncat = this.cat.getMontanagrad();
        }
        break;
      case 'област монтана':
        {
          this.raioncat = this.cat.getMontanaoblast();
        }
        break;
      case 'град пазарджик':
        {
          this.raioncat = this.cat.getPazardjikgrad();
        }
        break;
      case 'област пазарджик':
        {
          this.raioncat = this.cat.getPazardjikoblast();
        }
        break;
      case 'град перник':
        {
          this.raioncat = this.cat.getPernikgrad();
        }
        break;
      case 'област перник':
        {
          this.raioncat = this.cat.getPernikoblast();
        }
        break;
      case 'град плевен':
        {
          this.raioncat = this.cat.getPlevengrad();
        }
        break;
      case 'област плевен':
        {
          this.raioncat = this.cat.getPlevenoblast();
        }
        break;
      case 'град разград':
        {
          this.raioncat = this.cat.getRazgradgrad();
        }
        break;
      case 'област разград':
        {
          this.raioncat = this.cat.getRazgradoblast();
        }
        break;
      case 'град русе':
        {
          this.raioncat = this.cat.getRusegrad();
        }
        break;
      case 'област русе':
        {
          this.raioncat = this.cat.getRuseoblast();
        }
        break;
      case 'град силистра':
        {
          this.raioncat = this.cat.getSilistragrad();
        }
        break;
      case 'област силистра':
        {
          this.raioncat = this.cat.getSilistraoblast();
        }
        break;
      case 'град сливен':
        {
          this.raioncat = this.cat.getSlivengrad();
        }
        break;
      case 'област сливен':
        {
          this.raioncat = this.cat.getSlivenoblast();
        }
        break;
      case 'град смолян':
        {
          this.raioncat = this.cat.getSmolqngrad();
        }
        break;
      case 'област смолян':
        {
          this.raioncat = this.cat.getSmolqnoblast();
        }
        break;
      case 'град софия':
        {
          this.raioncat = this.cat.getSofiagrad();
        }
        break;
      case 'област софия':
        {
          this.raioncat = this.cat.getSofiaoblast();
        }
        break;
      case 'град стара загора':
        {
          this.raioncat = this.cat.getStarazagoragrad();
        }
        break;
      case 'област стара загора':
        {
          this.raioncat = this.cat.getStarazagoraoblast();
        }
        break;
      case 'град търговище':
        {
          this.raioncat = this.cat.getTyrgovishtegrad();
        }
        break;
      case 'област търговище':
        {
          this.raioncat = this.cat.getTyrgovishteoblast();
        }
        break;
      case 'град хасково':
        {
          this.raioncat = this.cat.getHaskovograd();
        }
        break;
      case 'област хасково':
        {
          this.raioncat = this.cat.getHaskovooblast();
        }
        break;
      case 'град шумен':
        {
          this.raioncat = this.cat.getShumengrad();
        }
        break;
      case 'област шумен':
        {
          this.raioncat = this.cat.getShumenoblast();
        }
        break;
      case 'град ямбол':
        {
          this.raioncat = this.cat.getQmbolgrad();
        }
        break;
      case 'област ямбол':
        {
          this.raioncat = this.cat.getQmboloblast();
        }
        break;
    }
  }
}
