<div class="container-lg" style="text-align: -webkit-center">
  <app-menu> </app-menu>

  <div class="col">
    <div class="row centercont">
      <h2>Акаунт</h2>
    </div>
    <div class="row centercont m-2" *appIsAdmin>
      <div class="col centercont" *ngIf="isOlxtokenExpire">
        <button class="btn btn-primary m-1" (click)="olxTokens()">Вземи токен за OLX</button>
      </div>
      <div class="col centercont" *ngIf="!isOlxtokenExpire">
        <div class="col">Изтича на: {{ olxTokenExpireIn | date: 'dd-MMM-yyyy hh:mm' }}</div>
        <div class="col">
          <button class="btn btn-primary m-1" (click)="olxTokens()">Презареди токен за OLX</button>
        </div>
      </div>

      <!-- <div class="col centercont">
        <div class="row">TOKEN OLX:</div>
        <form [formGroup]="tokens">
          <div class="row">
            <input type="text" class="form-control" formControlName="tokenOlx" />
          </div>
          <div class="row">
            <button class="btn btn-primary m-1" (click)="addOlxToken()">Запиши</button>
          </div>
        </form>
      </div> -->
    </div>
    <div class="container-sm centercont" *ngIf="userdata" style="font-size: large">
      <div class="row centercont m-2">
        <div class="col-md-auto blever">
          <div class="row">
            <div class="col-3">Име:</div>
            <div class="col">{{ userdata.firstName }} {{ userdata.lastName }}</div>
          </div>
          <div class="row">
            <div class="col-3">Имейл:</div>
            <div class="col">
              {{ userdata.mail }}
            </div>
          </div>
        </div>
      </div>

      <div class="row centercont m-2">
        <div class="col-6">Личен Филтър:</div>
      </div>
      <form [formGroup]="favfilter1">
        <div class="row centercont m-1">
          <div class="col-3">Тип обява:</div>
          <div class="col">
            <select class="form-control" (change)="changefilter()" formControlName="tipObqva">
              <option selectед value="">Всички обяви</option>
              <option select value="chasna">Само частни обяви</option>
            </select>
          </div>
        </div>
        <div class="row centercont m-1">
          <div class="col-3">Област или областен град:</div>
          <div class="col">
            <select
              class="form-control"
              #g
              (change)="getraions(g.value)"
              (change)="changefilter()"
              formControlName="grad"
            >
              <option selected value="">Всички</option>
              <option *ngFor="let gr of gradoblastcat" value="{{ gr }}">{{ gr }}</option>
            </select>
          </div>
        </div>
        <div class="row centercont m-1">
          <div class="col-3">Район:</div>
          <div class="col">
            <select class="form-control" (change)="changefilter()" formControlName="raion">
              <option selected value="">Всички</option>
              <option *ngFor="let gr of raioncat" value="{{ gr }}">{{ gr }}</option>
            </select>
          </div>
        </div>
        <div class="row centercont m-1">
          <div class="col-3">Тип имот:</div>
          <div class="col">
            <select class="form-control" (change)="changefilter()" formControlName="tipImot">
              <option selected value="">Всички</option>
              <option *ngFor="let gr of typeimotcat" value="{{ gr }}">{{ gr }}</option>
            </select>
          </div>
        </div>
        <div class="row centercont m-1">
          <div class="col">
            <button *ngIf="savedfilter" class="btn btn-primary m-1" (click)="savefilter()">Запази</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
