import { group } from '@angular/animations';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SearchcatService {
  constructor() {}

  getRaionsFromGrad(val: any) {
    val = val.toLowerCase();
    let ret;
    switch (val) {
      case 'област пловдив':
        {
          ret = this.getPlovdivoblast();
        }
        break;
      case 'град пловдив':
        {
          ret = this.getPlovdivgrad();
        }
        break;
      case 'град благоевград':
        {
          ret = this.getBlagoevgradgrad();
        }
        break;
      case 'област благоевград':
        {
          ret = this.getBlagoevgradoblast();
        }
        break;
      case 'град бургас':
        {
          ret = this.getBurgasgrad();
        }
        break;
      case 'област бургас':
        {
          ret = this.getBurgasoblast();
        }
        break;
      case 'град варна':
        {
          ret = this.getVarnagrad();
        }
        break;
      case 'област варна':
        {
          ret = this.getVarnaoblast();
        }
        break;
      case 'град велико търново':
        {
          ret = this.getVelikoTyrnovograd();
        }
        break;
      case 'област велико търново':
        {
          ret = this.getVelikotyrnovooblast();
        }
        break;
      case 'град видин':
        {
          ret = this.getVidingrad();
        }
        break;
      case 'област видин':
        {
          ret = this.getVidinoblast();
        }
        break;
      case 'град враца':
        {
          ret = this.getVracagrad();
        }
        break;
      case 'област враца':
        {
          ret = this.getVracaoblast();
        }
        break;
      case 'град габрово':
        {
          ret = this.getGabrovograd();
        }
        break;
      case 'област габрово':
        {
          ret = this.getGabrovooblast();
        }
        break;
      case 'град добрич':
        {
          ret = this.getDobrichgrad();
        }
        break;
      case 'област добрич':
        {
          ret = this.getDobrichoblast();
        }
        break;
      case 'град кърджали':
        {
          ret = this.getKurdjaligrad();
        }
        break;
      case 'област кърджали':
        {
          ret = this.getKurdjalioblast();
        }
        break;
      case 'град кюстендил':
        {
          ret = this.getKustendilgrad();
        }
        break;
      case 'област кюстендил':
        {
          ret = this.getKustendiloblast();
        }
        break;
      case 'град ловеч':
        {
          ret = this.getLovechgrad();
        }
        break;
      case 'област ловеч':
        {
          ret = this.getLovechoblast();
        }
        break;
      case 'град монтана':
        {
          ret = this.getMontanagrad();
        }
        break;
      case 'област монтана':
        {
          ret = this.getMontanaoblast();
        }
        break;
      case 'град пазарджик':
        {
          ret = this.getPazardjikgrad();
        }
        break;
      case 'област пазарджик':
        {
          ret = this.getPazardjikoblast();
        }
        break;
      case 'град перник':
        {
          ret = this.getPernikgrad();
        }
        break;
      case 'област перник':
        {
          ret = this.getPernikoblast();
        }
        break;
      case 'град плевен':
        {
          ret = this.getPlevengrad();
        }
        break;
      case 'област плевен':
        {
          ret = this.getPlevenoblast();
        }
        break;
      case 'град разград':
        {
          ret = this.getRazgradgrad();
        }
        break;
      case 'област разград':
        {
          ret = this.getRazgradoblast();
        }
        break;
      case 'град русе':
        {
          ret = this.getRusegrad();
        }
        break;
      case 'област русе':
        {
          ret = this.getRuseoblast();
        }
        break;
      case 'град силистра':
        {
          ret = this.getSilistragrad();
        }
        break;
      case 'област силистра':
        {
          ret = this.getSilistraoblast();
        }
        break;
      case 'град сливен':
        {
          ret = this.getSlivengrad();
        }
        break;
      case 'област сливен':
        {
          ret = this.getSlivenoblast();
        }
        break;
      case 'град смолян':
        {
          ret = this.getSmolqngrad();
        }
        break;
      case 'област смолян':
        {
          ret = this.getSmolqnoblast();
        }
        break;
      case 'град софия':
        {
          ret = this.getSofiagrad();
        }
        break;
      case 'област софия':
        {
          ret = this.getSofiaoblast();
        }
        break;
      case 'град стара загора':
        {
          ret = this.getStarazagoragrad();
        }
        break;
      case 'област стара загора':
        {
          ret = this.getStarazagoraoblast();
        }
        break;
      case 'град търговище':
        {
          ret = this.getTyrgovishtegrad();
        }
        break;
      case 'област търговище':
        {
          ret = this.getTyrgovishteoblast();
        }
        break;
      case 'град хасково':
        {
          ret = this.getHaskovograd();
        }
        break;
      case 'област хасково':
        {
          ret = this.getHaskovooblast();
        }
        break;
      case 'град шумен':
        {
          ret = this.getShumengrad();
        }
        break;
      case 'област шумен':
        {
          ret = this.getShumenoblast();
        }
        break;
      case 'град ямбол':
        {
          ret = this.getQmbolgrad();
        }
        break;
      case 'област ямбол':
        {
          ret = this.getQmboloblast();
        }
        break;
    }
    return ret;
  }

  gettypeimot() {
    let type = [
      '1-СТАЕН',
      '2-СТАЕН',
      '3-СТАЕН',
      '4-СТАЕН',
      'МНОГОСТАЕН',
      ' МЕЗОНЕТ',
      'АТЕЛИЕ, ТАВАН',
      'ОФИС',
      'МАГАЗИН',
      'ЗАВЕДЕНИЕ',
      'СКЛАД',
      ' ХОТЕЛ',
      'ПРОМ. ПОМЕЩЕНИЕ',
      'БИЗНЕС ИМОТ',
      'ЕТАЖ ОТ КЪЩА',
      'КЪЩА',
      'ВИЛА',
      'ПАРЦЕЛ',
      'ГАРАЖ',
      'ЗЕМЕДЕЛСКА ЗЕМЯ',
    ];
    return type;
  }
  getgradoblast() {
    let gradoblast = [
      'град Благоевград',
      'област Благоевград',
      'град Бургас',
      'област Бургас',
      'град Варна',
      'област Варна',
      'град Велико Търново',
      'област Велико Търново',
      'град Видин',
      'област Видин',
      'град Враца',
      'област Враца',
      'град Габрово',
      'област Габрово',
      'град Добрич',
      'област Добрич',
      'град Кърджали',
      'област Кърджали',
      'град Кюстендил',
      'област Кюстендил',
      'град Ловеч',
      'област Ловеч',
      'град Монтана',
      'област Монтана',
      'град Пазарджик',
      'област Пазарджик',
      'град Перник',
      'област Перник',
      'град Плевен',
      'област Плевен',
      'град Пловдив',
      'област Пловдив',
      'град Разград',
      'област Разград',
      'град Русе',
      'област Русе',
      'град Силистра',
      'област Силистра',
      'град Сливен',
      'област Сливен',
      'град Смолян',
      'област Смолян',
      'град София',
      'област София',
      'град Стара Загора',
      'област Стара Загора',
      'град Търговище',
      'област Търговище',
      'град Хасково',
      'област Хасково',
      'град Шумен',
      'област Шумен',
      'град Ямбол',
      'област Ямбол',
    ];
    return gradoblast;
  }
  getPlovdivoblast() {
    let plovdiv = [
      'гр.Асеновград',
      'гр. Баня',
      'гр. Брезово',
      'гр. Калофер',
      'гр. Карлово',
      'гр. Клисура',
      'гр. Кричим',
      'гр. Куклен',
      'гр. Лъки',
      'гр. Перущица',
      'гр. Първомай',
      'гр. Раковски',
      'гр. Садово',
      'гр. Сопот',
      'гр. Стамболийски',
      'гр. Съединение',
      'гр. Хисаря',
      'магистрала Тракия',
      'с. Анево',
      'с. Ахматово',
      'с. Бабек',
      'с. Балкан махала',
      'с. Бачково',
      'с. Бегово',
      'с. Бегунци',
      'с. Белащица',
      'с. Белица',
      'с. Беловица',
      'с. Белозем',
      'с. Бенковски',
      'с. Богдан',
      'с. Богданица',
      'с. Бойково',
      'с. Болярино',
      'с. Болярци',
      'с. Бор',
      'с. Борец',
      'с. Борово',
      'с. Боянци',
      'с. Браниполе',
      'с. Брестник',
      'с. Брестовица',
      'с. Брягово',
      'с. Буково',
      'с. Бяла река',
      'с. Васил Левски',
      'с. Ведраре',
      'с. Виница',
      'с. Воден',
      'с. Войводиново',
      'с. Войнягово',
      'с. Войсил',
      'с. Врата',
      'с. Върбен',
      'с. Главатар',
      'с. Голям чардак',
      'с. Горна махала',
      'с. Горни Домлян',
      'с. Горнослав',
      'с. Градина',
      'с. Граф Игнатиево',
      'с. Гълъбово',
      'с. Дедево',
      'с. Джурково',
      'с. Динк',
      'с. Добралък',
      'с. Добри дол',
      'с. Добростан',
      'с. Долна махала',
      'с. Долнослав',
      'с. Домлян',
      'с. Драгойново',
      'с. Драгомир',
      'с. Дрангово',
      'с. Дряново',
      'с. Дуванлии',
      'с. Дъбене',
      'с. Дълбок извор',
      'с. Дълго поле',
      'с. Езерово',
      'с. Желязно',
      'с. Житница',
      'с. Жълт камък',
      'с. Здравец',
      'с. Зелениково',
      'с. Златитрап',
      'с. Златовръх',
      'с. Златосел',
      'с. Иван Вазово',
      'с. Иганово',
      'с. Избеглии',
      'с. Извор',
      'с. Изворово',
      'с. Искра',
      'с. Йоаким Груево',
      'с. Кадиево',
      'с. Калековец',
      'с. Калояново',
      'с. Каравелово',
      'с. Караджалово',
      'с. Караджово',
      'с. Катуница',
      'с. Климент',
      'с. Козаново',
      'с. Конуш',
      'с. Косово',
      'с. Костиево',
      'с. Кочево',
      'с. Красново',
      'с. Крислово',
      'с. Крумово',
      'с. Крушево',
      'с. Крушово',
      'с. Кръстевич',
      'с. Куртово',
      'с. Куртово Конаре',
      'с. Кърнаре',
      'с. Леново',
      'с. Лилково',
      'с. Лъкавица',
      'с. Любен',
      'с. Лясково',
      'с. Мало Крушево',
      'с. Малък чардак',
      'с. Манастир',
      'с. Маноле',
      'с. Манолско Конаре',
      'с. Марино поле',
      'с. Марково',
      'с. Милево',
      'с. Михилци',
      'с. Момино село',
      'с. Моминско',
      'с. Московец',
      'с. Мостово',
      'с. Мраченик',
      'с. Мулдава',
      'с. Мътеница',
      'с. Найден Герово',
      'с. Нареченски бани',
      'с. Неделево',
      'с. Новаково',
      'с. Нови извор',
      'с. Ново Железаре',
      'с. Ново село',
      'с. Орешец',
      'с. Оризари',
      'с. Отец Кирилово',
      'с. Отец Паисиево',
      'с. Паничери',
      'с. Патриарх Евтимово',
      'с. Певците',
      'с. Песнопой',
      'с. Поповица',
      'с. Поройна',
      'с. Правище',
      'с. Православен',
      'с. Пролом',
      'с. Пъдарско',
      'с. Първенец',
      'с. Радиново',
      'с. Рогош',
      'с. Розино',
      'с. Розовец',
      'с. Руен',
      'с. Ръжево',
      'с. Ръжево Конаре',
      'с. Свежен',
      'с. Селци',
      'с. Сини връх',
      'с. Ситово',
      'с. Скобелево',
      'с. Скутаре',
      'с. Слатина',
      'с. Соколица',
      'с. Старо Железаре',
      'с. Старосел',
      'с. Стоево',
      'с. Столетово',
      'с. Стрелци',
      'с. Строево',
      'с. Стряма',
      'с. Сухозем',
      'с. Сърнегор',
      'с. Татарево',
      'с. Тополово',
      'с. Три могили',
      'с. Триводици',
      'с. Трилистник',
      'с. Труд',
      'с. Тюркмен',
      'с. Узуново',
      'с. Устина',
      'с. Храбрино',
      'с. Христо Даново',
      'с. Цалапица',
      'с. Цар Калоян',
      'с. Царацово',
      'с. Царимир',
      'с. Церетелево',
      'с. Чалъкови',
      'с. Червен',
      'с. Черничево',
      'с. Черноземен',
      'с. Чехларе',
      'с. Чешнегирово',
      'с. Чоба',
      'с. Чурен',
      'с. Шишманци',
      'с. Югово',
      'с. Яврово',
      'с. Ягодово',
      'с. Ясно поле',
      'хижа Здравец',
    ];
    return plovdiv;
  }
  getPlovdivgrad() {
    let plov = [
      'Въстанически',
      'Гагарин',
      'Западен',
      'Захарна фабрика',
      'Изгрев',
      'Индустриална зона - Изток',
      'Индустриална зона - Север',
      'Индустриална зона - Юг',
      'Индустриална зона -Тракия',
      'Институт по овощарство',
      'Каменица 1',
      'Каменица 2',
      'Коматево',
      'Коматевски възел',
      'Кършияка',
      'Кючук Париж',
      'Мараша',
      'Марица Север',
      'Младежки Хълм',
      'Остромила',
      'Прослав',
      'Саут Сити',
      'Старият град',
      'Столипиново',
      'Сточна гара',
      'Съдийски',
      'Тракия',
      'Филипово',
      'Христо Смирненски',
      'Централна гара',
      'Център',
      'Шекер махала',
      'Южен',
    ];
    return plov;
  }
  getBlagoevgradgrad() {
    let gr = [
      'Ален мак',
      'Баларбаши',
      'Бялата висота',
      'Вароша',
      'Втора промишлена зона',
      'Грамада',
      'Еленово 1',
      'Еленово 2',
      'Запад',
      'Идеален център',
      'Орлова чука',
      'Освобождение',
      'Първа промишлена зона',
      'Струмско',
      'Широк център',
    ];
    return gr;
  }
  getBlagoevgradoblast() {
    let gr = [
      'главен път Е79',
      'гр. Банско',
      'гр. Белица',
      'гр. Гоце Делчев',
      'гр. Добринище',
      'гр. Кресна',
      'гр. Мелник',
      'гр. Петрич',
      'гр. Разлог',
      'гр. Сандански',
      'гр. Симитли',
      'гр. Хаджидимово',
      'гр. Якоруда',
      'с. Абланица',
      'с. Аврамово',
      'с. Бабяк',
      'с. Балдево',
      'с. Баничан',
      'с. Баня',
      'с. Баскалци',
      'с. Бачево',
      'с. Бел камен',
      'с. Беласица',
      'с. Бело поле',
      'с. Бельово',
      'с. Беслен',
      'с. Бистрица',
      'с. Блатска',
      'с. Боголин',
      'с. Богородица',
      'с. Бождово',
      'с. Боровичене',
      'с. Борово',
      'с. Брежани',
      'с. Брезница',
      'с. Брестово',
      'с. Будилци',
      'с. Буково',
      'с. Бунцево',
      'с. Бучино',
      'с. Българчево',
      'с. Ваклиново',
      'с. Велющец',
      'с. Виногради',
      'с. Вихрен',
      'с. Вишлене',
      'с. Влахи',
      'с. Волно',
      'с. Вракуповица',
      'с. Враня',
      'с. Вълково',
      'с. Вълкосел',
      'с. Габрене',
      'с. Габрово',
      'с. Гайтаниново',
      'с. Гега',
      'с. Генерал Тодоров',
      'с. Годешево',
      'с. Годлево',
      'с. Голем Цалим',
      'с. Голешово',
      'с. Гореме',
      'с. Горна Брезница',
      'с. Горна Крушица',
      'с. Горна Рибница',
      'с. Горна Сушица',
      'с. Горно Драглище',
      'с. Горно Дряново',
      'с. Горно Краище',
      'с. Горно Осеново',
      'с. Горно Спанчево',
      'с. Горно Хърсово',
      'с. Горчево',
      'с. Господинци',
      'с. Гостун',
      'с. Градево',
      'с. Гълъбово',
      'с. Гърмен',
      'с. Гюргево',
      'с. Дагоново',
      'с. Дамяница',
      'с. Дебочица',
      'с. Дебрен',
      'с. Дебрене',
      'с. Делвино',
      'с. Делчево',
      'с. Джигурово',
      'с. Добри лаки',
      'с. Добротино',
      'с. Добърско',
      'с. Докатичево',
      'с. Долен',
      'с. Долене',
      'с. Долени',
      'с. Долна Градешница',
      'с. Долна Крушица',
      'с. Долна Рибница',
      'с. Долно Драглище',
      'с. Долно Дряново',
      'с. Долно Осеново',
      'с. Долно Спанчево',
      'с. Драгуш',
      'с. Драката',
      'с. Дрангово',
      'с. Дренково',
      'с. Дреновица',
      'с. Дреново',
      'с. Дъбница',
      'с. Дъбрава',
      'с. Езерец',
      'с. Еленово',
      'с. Елешница',
      'с. Железница',
      'с. Жижево',
      'с. Занога',
      'с. Зелендол',
      'с. Златарица',
      'с. Златолист',
      'с. Зойчене',
      'с. Зорница',
      'с. Иваново',
      'с. Игралище',
      'с. Изгрев',
      'с. Илинден',
      'с. Илинденци',
      'с. Кавракирово',
      'с. Калиманци',
      'с. Камена',
      'с. Каменица',
      'с. Капатово',
      'с. Катунци',
      'с. Кашина',
      'с. Кладенци',
      'с. Клепало',
      'с. Клисура',
      'с. Ключ',
      'с. Ковачевица',
      'с. Ковачево',
      'с. Коларово',
      'с. Колибите',
      'с. Конарско',
      'с. Копривлен',
      'с. Корница',
      'с. Кочан',
      'с. Краище',
      'с. Кремен',
      'с. Крибул',
      'с. Кромидово',
      'с. Крупник',
      'с. Крушево',
      'с. Крънджилица',
      'с. Кръстилци',
      'с. Кузьово',
      'с. Кукурахцево',
      'с. Кулата',
      'с. Кърланово',
      'с. Кърналово',
      'с. Кърпелево',
      'с. Ладарево',
      'с. Ласкарево',
      'с. Лебница',
      'с. Левуново',
      'с. Лехово',
      'с. Лешко',
      'с. Лешница',
      'с. Лещен',
      'с. Лиляново',
      'с. Лисия',
      'с. Логодаж',
      'с. Лозеница',
      'с. Лъжница',
      'с. Лъки',
      'с. Любовище',
      'с. Любовка',
      'с. Лютово',
      'с. Малки Цалим',
      'с. Марикостиново',
      'с. Марино поле',
      'с. Марулево',
      'с. Марчево',
      'с. Махалата',
      'с. Мендово',
      'с. Места',
      'с. Мечкул',
      'с. Микрево',
      'с. Митино',
      'с. Михнево',
      'с. Мощанец',
      'с. Мусомища',
      'с. Никудин',
      'с. Нова Ловча',
      'с. Ново Делчево',
      'с. Ново Кономлади',
      'с. Ново Лески',
      'с. Ново село',
      'с. Ново Ходжово',
      'с. Обел',
      'с. Обидим',
      'с. Огняново',
      'с. Ореше',
      'с. Орцево',
      'с. Осеново',
      'с. Осиково',
      'с. Осина',
      'с. Ощава',
      'с. Падеш',
      'с. Палат',
      'с. Палатик',
      'с. Парил',
      'с. Петрелик',
      'с. Петрово',
      'с. Пиперица',
      'с. Пирин',
      'с. Плетена',
      'с. Плоски',
      'с. Покровник',
      'с. Полена',
      'с. Поленица',
      'с. Полето',
      'с. Право бърдо',
      'с. Първомай',
      'с. Раздол',
      'с. Ракитна',
      'с. Рибник',
      'с. Рибново',
      'с. Рилци',
      'с. Рожен',
      'с. Рупите',
      'с. Ръждак',
      'с. Садово',
      'с. Самуилова крепост',
      'с. Самуилово',
      'с. Сатовча',
      'с. Седелец',
      'с. Селище',
      'с. Сенокос',
      'с. Склаве',
      'с. Скребатно',
      'с. Скрът',
      'с. Слащен',
      'с. Сливница',
      'с. Смолево',
      'с. Спатово',
      'с. Стара Кресна',
      'с. Старчево',
      'с. Стожа',
      'с. Струма',
      'с. Струмешница',
      'с. Струмяни',
      'с. Сугарево',
      'с. Сухострел',
      'с. Сушица',
      'с. Теплен',
      'с. Тешово',
      'с. Тонско дабе',
      'с. Тополница',
      'с. Тросково',
      'с. Туховища',
      'с. Филипово',
      'с. Фъргово',
      'с. Хвостяне',
      'с. Хотово',
      'с. Храсна',
      'с. Хърсово',
      'с. Цапарево',
      'с. Церово',
      'с. Черешница',
      'с. Черешово',
      'с. Черна Места',
      'с. Черниче',
      'с. Чурилово',
      'с. Чуричени',
      'с. Чучулигово',
      'с. Юруково',
      'с. Яворница',
      'с. Яково',
      'с. Яново',
    ];
    return gr;
  }
  getBurgasgrad() {
    let gr = [
      'Акациите',
      'Банево',
      'Братя Миладинови',
      'Ветрен',
      'Възраждане',
      'Горно Езерово',
      'Долно Езерово',
      'Зорница',
      'Изгрев',
      'Крайморие',
      'Лазур',
      'Лозово',
      'Меден рудник - зона А',
      'Меден рудник - зона Б',
      'Меден рудник - зона В',
      'Меден рудник - зона Г',
      'Меден рудник - зона Д',
      'Победа',
      'Пристанище Бургас',
      'Промишлена зона - Лозово',
      'Промишлена зона - Север',
      'Промишлена зона - Юг',
      'Сарафово',
      'Славейков',
      'Център',
    ];

    return gr;
  }
  getBurgasoblast() {
    let gr = [
      'гр. Айтос',
      'гр. Ахелой',
      'гр. Ахтопол',
      'гр. Българово',
      'гр. Каблешково',
      'гр. Камено',
      'гр. Карнобат',
      'гр. Китен',
      'гр. Малко Търново',
      'гр. Несебър',
      'гр. Обзор',
      'гр. Поморие',
      'гр. Приморско',
      'гр. Свети Влас',
      'гр. Созопол',
      'гр. Средец',
      'гр. Сунгурларе',
      'гр. Царево',
      'гр. Черноморец',
      'к.к. Дюни',
      'к.к. Елените',
      'к.к. Слънчев бряг',
      'магистрала Тракия',
      'с. Александрово',
      'с. Аспарухово',
      'с. Атия',
      'с. Балабанчево',
      'с. Баня',
      'с. Бата',
      'с. Белеврен',
      'с. Белила',
      'с. Белодол',
      'с. Бероново',
      'с. Билка',
      'с. Бистрец',
      'с. Близнак',
      'с. Богданово',
      'с. Босилково',
      'с. Братово',
      'с. Бродилово',
      'с. Бръшлян',
      'с. Брястовец',
      'с. Българи',
      'с. Бяла вода',
      'с. Варвара',
      'с. Варовник',
      'с. Ведрово',
      'с. Везенково',
      'с. Велика',
      'с. Велислав',
      'с. Венец',
      'с. Веселие',
      'с. Визица',
      'с. Винарско',
      'с. Вишна',
      'с. Вратица',
      'с. Вресово',
      'с. Вълчаново',
      'с. Вълчин',
      'с. Вършило',
      'с. Габерово',
      'с. Габър',
      'с. Глумче',
      'с. Голямо Буково',
      'с. Горица',
      'с. Горно Ябълково',
      'с. Горово',
      'с. Граматиково',
      'с. Гранитец',
      'с. Граничар',
      'с. Грозден',
      'с. Гълъбец',
      'с. Гюльовца',
      'с. Дебелт',
      'с. Деветак',
      'с. Деветинци',
      'с. Детелина',
      'с. Димчево',
      'с. Добра поляна',
      'с. Добриново',
      'с. Добромир',
      'с. Долно Ябълково',
      'с. Драганово',
      'с. Драганци',
      'с. Драгово',
      'с. Драка',
      'с. Драчево',
      'с. Дропла',
      'с. Дрянковец',
      'с. Дъбник',
      'с. Дъбовица',
      'с. Дъскотна',
      'с. Дюлево',
      'с. Дюля',
      'с. Евренозово',
      'с. Екзарх Антимово',
      'с. Емона',
      'с. Есен',
      'с. Железник',
      'с. Желязово',
      'с. Житосвят',
      'с. Заберново',
      'с. Завет',
      'с. Загорци',
      'с. Заимчево',
      'с. Зайчар',
      'с. Звезда',
      'с. Звездец',
      'с. Зетьово',
      'с. Зидарово',
      'с. Зимен',
      'с. Зорница',
      'с. Извор',
      'с. Изворище',
      'с. Изгрев',
      'с. Индже войвода',
      'с. Искра',
      'с. Калово',
      'с. Каменар',
      'с. Каменско',
      'с. Каменяк',
      'с. Камчия',
      'с. Каравельово',
      'с. Карагеоргиево',
      'с. Караново',
      'с. Кирово',
      'с. Китка',
      'с. Кликач',
      'с. Климаш',
      'с. Козаре',
      'с. Козичино',
      'с. Козница',
      'с. Кондолово',
      'с. Константиново',
      'с. Косовец',
      'с. Костен',
      'с. Кости',
      'с. Кошарица',
      'с. Крумово градище',
      'с. Крушевец',
      'с. Крушово',
      'с. Кръстина',
      'с. Кубадин',
      'с. Ливада',
      'с. Листец',
      'с. Лозарево',
      'с. Лозенец',
      'с. Лозица',
      'с. Лъка',
      'с. Люляково',
      'с. Лясково',
      'с. Малина',
      'с. Малка поляна',
      'с. Манолич',
      'с. Маринка',
      'с. Медово',
      'с. Миролюбово',
      'с. Младежко',
      'с. Момина църква',
      'с. Мрежичко',
      'с. Мъглен',
      'с. Мъдрино',
      'с. Невестино',
      'с. Ново Паничарево',
      'с. Огнен',
      'с. Оризаре',
      'с. Орлинци',
      'с. Паницово',
      'с. Пещерско',
      'с. Пирне',
      'с. Писменово',
      'с. Планиница',
      'с. Подвис',
      'с. Подгорец',
      'с. Полски извор',
      'с. Поляново',
      'с. Порой',
      'с. Преображенци',
      'с. Прилеп',
      'с. Припек',
      'с. Присад',
      'с. Приселци',
      'с. Просеник',
      'с. Проход',
      'с. Пчелин',
      'с. Пънчево',
      'с. Равадиново',
      'с. Равда',
      'с. Равна гора',
      'с. Равнец',
      'с. Радойново',
      'с. Разбойна',
      'с. Раклиново',
      'с. Раклица',
      'с. Раковсково',
      'с. Резово',
      'с. Речица',
      'с. Рожден',
      'с. Росен',
      'с. Росеново',
      'с. Рудина',
      'с. Рудник',
      'с. Руен',
      'с. Рупча',
      'с. Русокастро',
      'с. Ръжица',
      'с. Садово',
      'с. Сан-Стефано',
      'с. Светлина',
      'с. Свобода',
      'с. Сигмен',
      'с. Синеморец',
      'с. Сини рид',
      'с. Синьо камене',
      'с. Скала',
      'с. Скалак',
      'с. Славянци',
      'с. Сливарово',
      'с. Сливово',
      'с. Смолник',
      'с. Снежа',
      'с. Снягово',
      'с. Соколец',
      'с. Соколово',
      'с. Средна махала',
      'с. Стоилово',
      'с. Страцин',
      'с. Струя',
      'с. Суходол',
      'с. Съдиево',
      'с. Съединение',
      'с. Сърнево',
      'с. Твърдица',
      'с. Терзийско',
      'с. Тополица',
      'с. Топчийско',
      'с. Тракийци',
      'с. Трояново',
      'с. Трънак',
      'с. Тръстиково',
      'с. Тънково',
      'с. Фазаново',
      'с. Факия',
      'с. Хаджиите',
      'с. Церковски',
      'с. Череша',
      'с. Черково',
      'с. Черна могила',
      'с. Черни връх',
      'с. Черница',
      'с. Черно море',
      'с. Черноград',
      'с. Чубра',
      'с. Чукарка',
      'с. Шиварово',
      'с. Ябълчево',
      'с. Ясеново',
      'с. Ясна поляна',
    ];
    return gr;
  }
  getVarnagrad() {
    let gr = [
      'Автогара',
      'Аспарухово',
      'Базар Левски',
      'Бизнес парк Варна',
      'Бизнес хотел',
      'Бриз',
      'Виница',
      'ВИНС',
      'Владислав Варненчик',
      'Военна болница',
      'Възраждане 1',
      'Възраждане 2',
      'Възраждане 3',
      'Възраждане 4',
      'Галата',
      'Гаров район',
      'Генералите',
      'Гранд Мол Варна',
      'Гръцка махала',
      'Електрон',
      'Електроразпр. Варна',
      'ЖП Гара',
      'Завод Дружба',
      'Западна промишлена зона',
      'Зимно кино Тракия',
      'Изгрев',
      'Кайсиева градина',
      'Колхозен пазар',
      'Конфуто',
      'Корабостроителница Варна',
      'Левски',
      'Летище',
      'Лятно кино Тракия',
      'Максуда',
      'Метро',
      'Младост 1',
      'Младост 2',
      'Морска градина',
      'Нептун',
      'Образцов дом',
      'Общината',
      'Окръжна болница',
      'Операта',
      'Островна промишлена зона',
      'Планова промишлена зона',
      'Победа',
      'Погреби',
      'Пристанище Варна',
      'Проект Пристанище Варна',
      'Северна промишлена зона',
      'Спортна зала',
      'Терапевтична болница',
      'Трошево',
      'ХЕИ',
      'Христо Ботев',
      'Цветен квартал',
      'Централна поща',
      'Център',
      'Чайка',
      'Чаталджа',
      'Червен площад',
      'м-т Акчелар',
      'м-т Атанас Тарла',
      'м-т Балам Дере',
      'м-т Боклук Тарла',
      'м-т Боровец - север',
      'м-т Боровец - юг',
      'м-т Евксиноград',
      'м-т Зеленика',
      'м-т Кокарджа',
      'м-т Кочмар',
      'м-т Ментешето',
      'м-т Планова',
      'м-т Прибой',
      'м-т Пчелина',
      'м-т Ракитника',
      'м-т Св. Никола',
      'м-т Сотира',
      'м-т Сълзица',
      'м-т Траката',
      'м-т Фичоза',
      'м-т Франга Дере',
    ];
    return gr;
  }
  getVarnaoblast() {
    let gr = [
      'гр. Аксаково',
      'гр. Белослав',
      'гр. Бяла',
      'гр. Вълчи дол',
      'гр. Девня',
      'гр. Долни чифлик',
      'гр. Дългопол',
      'гр. Игнатиево',
      'гр. Провадия',
      'гр. Суворово',
      'к.к. Златни пясъци',
      'к.к. Камчия',
      'к.к. Св.Св. Константин и Елена',
      'к.к. Слънчев ден',
      'м-т Ален мак',
      'м-т Добрева чешма',
      'м-т Кабакум',
      'м-т Манастирски рид',
      'м-т Перчемлията',
      'с. Аврен',
      'с. Арковна',
      'с. Аспарухово',
      'с. Баново',
      'с. Белоградец',
      'с. Бенковски',
      'с. Близнаци',
      'с. Блъсково',
      'с. Бозвелийско',
      'с. Болярци',
      'с. Боряна',
      'с. Ботево',
      'с. Бояна',
      'с. Брестак',
      'с. Булаир',
      'с. Бърдарево',
      'с. Бързица',
      'с. Величково',
      'с. Венелин',
      'с. Венчан',
      'с. Ветрино',
      'с. Водица',
      'с. Войводино',
      'с. Въглен',
      'с. Габърница',
      'с. Генерал Кантарджиево',
      'с. Генерал Киселово',
      'с. Генерал Колево',
      'с. Голица',
      'с. Горен чифлик',
      'с. Горица',
      'с. Господиново',
      'с. Градинарово',
      'с. Гроздьово',
      'с. Дебелец',
      'с. Детелина',
      'с. Добри дол',
      'с. Добрина',
      'с. Доброглед',
      'с. Доброплодно',
      'с. Добротич',
      'с. Долище',
      'с. Дръндар',
      'с. Дъбравино',
      'с. Дюлино',
      'с. Езерово',
      'с. Есеница',
      'с. Житница',
      'с. Засмяно',
      'с. Звездица',
      'с. Звънец',
      'с. Здравец',
      'с. Златина',
      'с. Зорница',
      'с. Изворник',
      'с. Изворско',
      'с. Изгрев',
      'с. Искър',
      'с. Казашка река',
      'с. Казашко',
      'с. Калиманци',
      'с. Калоян',
      'с. Камен дял',
      'с. Каменар',
      'с. Караманите',
      'с. Кипра',
      'с. Китен',
      'с. Китка',
      'с. Кичево',
      'с. Климентово',
      'с. Комарево',
      'с. Комунари',
      'с. Константиново',
      'с. Кракра',
      'с. Красимир',
      'с. Кривини',
      'с. Кривня',
      'с. Крумово',
      'с. Круша',
      'с. Куманово',
      'с. Левски',
      'с. Лопушна',
      'с. Любен Каравелово',
      'с. Манастир',
      'с. Медовец',
      'с. Метличина',
      'с. Михалич',
      'с. Млада гвардия',
      'с. Момчилово',
      'с. Невша',
      'с. Неново',
      'с. Неофит Рилски',
      'с. Николаевка',
      'с. Нова Шипка',
      'с. Новаково',
      'с. Ново Оряхово',
      'с. Оборище',
      'с. Овчага',
      'с. Орешак',
      'с. Осеново',
      'с. Падина',
      'с. Партизани',
      'с. Петров дол',
      'с. Поляците',
      'с. Попович',
      'с. Припек',
      'с. Приселци',
      'с. Просечен',
      'с. Пчелник',
      'с. Равна',
      'с. Равна гора',
      'с. Радан войвода',
      'с. Радево',
      'с. Разделна',
      'с. Рояк',
      'с. Рудник',
      'с. Сава',
      'с. Садово',
      'с. Самотино',
      'с. Синдел',
      'с. Славейково',
      'с. Сладка вода',
      'с. Слънчево',
      'с. Снежина',
      'с. Солник',
      'с. Средно село',
      'с. Старо Оряхово',
      'с. Староселец',
      'с. Стефан Караджа',
      'с. Страхил',
      'с. Страшимирово',
      'с. Тополи',
      'с. Тръстиково',
      'с. Тутраканци',
      'с. Храброво',
      'с. Царевци',
      'с. Цонево',
      'с. Чайка',
      'с. Червенци',
      'с. Черковна',
      'с. Чернево',
      'с. Черноок',
      'с. Шкорпиловци',
      'с. Щипско',
      'с. Юнак',
      'с. Юнец',
      'с. Ягнило',
      'с. Яребична',
      'яз. Цонево',
    ];

    return gr;
  }
  getVelikoTyrnovograd() {
    let gr = [
      'Акация',
      'Асенов',
      'Бузлуджа',
      'Варуша',
      'Зона Б',
      'Картала',
      'Колю Фичето',
      'Промишлена зона - Запад',
      'Промишлена зона - Юг',
      'Света гора',
      'Стара част',
      'Централна гара',
      'Център',
      'Чолаковци',
      'м-т Козлуджа',
    ];
    return gr;
  }
  getVelikotyrnovooblast() {
    let gr = [
      'главен път Е83',
      'главен път Е85',
      'гр. Бяла черква',
      'гр. Горна Оряховица',
      'гр. Дебелец',
      'гр. Долна Оряховица',
      'гр. Елена',
      'гр. Златарица',
      'гр. Килифарево',
      'гр. Лясковец',
      'гр. Павликени',
      'гр. Полски Тръмбеш',
      'гр. Свищов',
      'гр. Стражица',
      'гр. Сухиндол',
      'с. Алеково',
      'с. Александрово',
      'с. Аплаци',
      'с. Арбанаси',
      'с. Асеново',
      'с. Багалевци',
      'с. Бадевци',
      'с. Баевци',
      'с. Баждари',
      'с. Балван',
      'с. Балканци',
      'с. Балуци',
      'с. Батак',
      'с. Беброво',
      'с. Бейковци',
      'с. Белчевци',
      'с. Беляковец',
      'с. Берковци',
      'с. Бижовци',
      'с. Благоево',
      'с. Блъсковци',
      'с. Богданско',
      'с. Бойковци',
      'с. Бойчеви колиби',
      'с. Бойчовци',
      'с. Босевци',
      'с. Бочковци',
      'с. Бояновци',
      'с. Бранковци',
      'с. Брезово',
      'с. Бръчковци',
      'с. Бряговица',
      'с. Буйновци',
      'с. Буковец',
      'с. Бутово',
      'с. Българско Сливово',
      'с. Бяла река',
      'с. Бялковци',
      'с. Валето',
      'с. Вардим',
      'с. Велковци',
      'с. Велчево',
      'с. Велювци',
      'с. Веселина',
      'с. Ветринци',
      'с. Виларе',
      'с. Виноград',
      'с. Високовци',
      'с. Вишовград',
      'с. Владислав',
      'с. Водно',
      'с. Водолей',
      'с. Войнежа',
      'с. Вонеща вода',
      'с. Въглевци',
      'с. Вълчовци',
      'с. Върбица',
      'с. Върбовка',
      'с. Вързилковци',
      'с. Вързулица',
      'с. Върлинка',
      'с. Габрака',
      'с. Габровци',
      'с. Ганев дол',
      'с. Гащевци',
      'с. Глоговец',
      'с. Големани',
      'с. Големаните',
      'с. Горановци',
      'с. Горен Еневец',
      'с. Горна Липница',
      'с. Горна Студена',
      'с. Горна Хаджийска',
      'с. Горни край',
      'с. Горни Танчевци',
      'с. Горска',
      'с. Горски горен Тръмбеш',
      'с. Горски долен Тръмбеш',
      'с. Горски Сеновец',
      'с. Горско Калугерово',
      'с. Горско Косово',
      'с. Горско ново село',
      'с. Горско Писарево',
      'с. Граматици',
      'с. Гърдевци',
      'с. Давери',
      'с. Дайновци',
      'с. Дебели рът',
      'с. Деветаците',
      'с. Дедина',
      'с. Дединци',
      'с. Делова махала',
      'с. Деляновци',
      'с. Джулюница',
      'с. Димитровци',
      'с. Димовци',
      'с. Димча',
      'с. Дичин',
      'с. Добревци',
      'с. Добри дял',
      'с. Дойновци',
      'с. Долен Еневец',
      'с. Долна Липница',
      'с. Долни Дамяновци',
      'с. Долни Марян',
      'с. Долни Танчевци',
      'с. Долно Шивачево',
      'с. Донковци',
      'с. Драганово',
      'с. Драгановци',
      'с. Драганосковци',
      'с. Драгижево',
      'с. Драгийци',
      'с. Драгневци',
      'с. Драгомирово',
      'с. Дрента',
      'с. Дуковци',
      'с. Дунавци',
      'с. Дуровци',
      'с. Дълги припек',
      'с. Дърлевци',
      'с. Дъскот',
      'с. Емен',
      'с. Железарци',
      'с. Зеленик',
      'с. Иванивановци',
      'с. Ивановци',
      'с. Иванча',
      'с. Игнатовци',
      'с. Илаков рът',
      'с. Илевци',
      'с. Илиювци',
      'с. Йовчевци',
      'с. Кавлак',
      'с. Калайджии',
      'с. Камен',
      'с. Каменари',
      'с. Кантари',
      'с. Караиванци',
      'с. Караисен',
      'с. Карандили',
      'с. Каранци',
      'с. Кесарево',
      'с. Киревци',
      'с. Кисьовци',
      'с. Кладни дял',
      'с. Климентово',
      'с. Клъшка река',
      'с. Коевци',
      'с. Кожлювци',
      'с. Козаревец',
      'с. Козловец',
      'с. Козя река',
      'с. Колари',
      'с. Константин',
      'с. Косевци',
      'с. Костел',
      'с. Котуци',
      'с. Красно градище',
      'с. Крилювци',
      'с. Крумчевци',
      'с. Крушето',
      'с. Куцаровци',
      'с. Куцина',
      'с. Къпиново',
      'с. Лагерите',
      'с. Лазарци',
      'с. Леденик',
      'с. Лесиче',
      'с. Лесичери',
      'с. Лозен',
      'с. Любенци',
      'с. Майско',
      'с. Малки чифлик',
      'с. Малчовци',
      'с. Марафелци',
      'с. Марговци',
      'с. Мариновци',
      'с. Марян',
      'с. Масларево',
      'с. Махалници',
      'с. Мерданя',
      'с. Мийковци',
      'с. Миндя',
      'с. Миневци',
      'с. Мирово',
      'с. Мирчовци',
      'с. Михалци',
      'с. Мишеморков хан',
      'с. Момин сбор',
      'с. Морава',
      'с. Мусина',
      'с. Мъртвината',
      'с. Нацовци',
      'с. Недан',
      'с. Недялковци',
      'с. Нешевци',
      'с. Николаево',
      'с. Николовци',
      'с. Николчовци',
      'с. Никюп',
      'с. Ничовци',
      'с. Нова Върбовка',
      'с. Ново градище',
      'с. Ново село',
      'с. Новогорци',
      'с. Нюшковци',
      'с. Обединение',
      'с. Овощна',
      'с. Овча могила',
      'с. Ореш',
      'с. Орловец',
      'с. Осенарите',
      'с. Павел',
      'с. Паисий',
      'с. Палици',
      'с. Папратлива',
      'с. Паскалевец',
      'с. Патреш',
      'с. Пейковци',
      'с. Петко Каравелово',
      'с. Петковци',
      'с. Пирамидата',
      'с. Писарево',
      'с. Плаково',
      'с. Пожерник',
      'с. Поликраище',
      'с. Полски Сеновец',
      'с. Поповци',
      'с. Попрусевци',
      'с. Попска',
      'с. Правда',
      'с. Присово',
      'с. Продановци',
      'с. Пушево',
      'с. Пчелище',
      'с. Първомайци',
      'с. Пъровци',
      'с. Равново',
      'с. Раданово',
      'с. Радковци',
      'с. Радовци',
      'с. Разсоха',
      'с. Райковци',
      'с. Райновци',
      'с. Ралиновци',
      'с. Рашевци',
      'с. Раювци',
      'с. Ребревци',
      'с. Резач',
      'с. Рекичка',
      'с. Ресен',
      'с. Родина',
      'с. Росица',
      'с. Росно',
      'с. Русаля',
      'с. Русковци',
      'с. Руховци',
      'с. Самоводене',
      'с. Самсиите',
      'с. Светославци',
      'с. Сеймените',
      'с. Семковци',
      'с. Сливовица',
      'с. Сломер',
      'с. Совата',
      'с. Средни колиби',
      'с. Средно село',
      'с. Стамболово',
      'с. Стефан Стамболово',
      'с. Стойчевци',
      'с. Стояновци',
      'с. Страхилово',
      'с. Стрелец',
      'с. Султани',
      'с. Суха река',
      'с. Сушица',
      'с. Събковци',
      'с. Сърненци',
      'с. Теменуга',
      'с. Терзиите',
      'с. Титевци',
      'с. Тодоровци',
      'с. Тодювци',
      'с. Томбето',
      'с. Топузи',
      'с. Трънковци',
      'с. Тумбевци',
      'с. Тънки рът',
      'с. Търкашени',
      'с. Угорялковци',
      'с. Ушeвци',
      'с. Хаджидимитрово',
      'с. Харваловци',
      'с. Хотница',
      'с. Христовци',
      'с. Хъневци',
      'с. Царевец',
      'с. Царски извор',
      'с. Цвеклювци',
      'с. Цепераните',
      'с. Церова кория',
      'с. Цонковци',
      'с. Чавдарци',
      'с. Чакали',
      'с. Червена',
      'с. Червенковци',
      'с. Черни дял',
      'с. Чешма',
      'с. Чистово',
      'с. Чуката',
      'с. Шемшево',
      'с. Шереметя',
      'с. Шиливери',
      'с. Шилковци',
      'с. Шодековци',
      'с. Шубеци',
      'с. Яковци',
      'с. Ялово',
      'с. Янтра',
      'яз. Ал. Стамболийски',
    ];

    return gr;
  }
  getVidingrad() {
    let gr = [
      'Акджамия',
      'Александър Стамболийски',
      'Баба Тонка',
      'Бонония',
      'Васил Левски',
      'Вида 1',
      'Вида 2',
      'Възраждане',
      'Гео Милев',
      'Георги Бенковски',
      'Калето',
      'Крум Бъчваров',
      'Кумбаир',
      'Нов път',
      'Панония',
      'Петко Каравелов',
      'Плиска',
      'Промишлена зона',
      'Промишлена зона - Запад',
      'Промишлена зона - Север',
      'Промишлена зона - Юг',
      'Стефан Стамболов',
      'Строител',
      'Съединение',
      'Химик',
      'Христо Ботев',
      'Център',
      'в.з. Акджамия',
      'в.з. Кумбаир',
    ];
    return gr;
  }
  getVidinoblast() {
    let gr = [
      'Гара Орешец',
      'гр. Белоградчик',
      'гр. Брегово',
      'гр. Грамада',
      'гр. Димово',
      'гр. Дунавци',
      'гр. Кула',
      'с. Акациево',
      'с. Антимово',
      'с. Арчар',
      'с. Балей',
      'с. Бела',
      'с. Бела Рада',
      'с. Бело поле',
      'с. Бойница',
      'с. Бориловец',
      'с. Боровица',
      'с. Ботево',
      'с. Бояново',
      'с. Бранковци',
      'с. Буковец',
      'с. Вещица',
      'с. Винарово',
      'с. Владиченци',
      'с. Водна',
      'с. Воднянци',
      'с. Войница',
      'с. Връв',
      'с. Вълчек',
      'с. Върба',
      'с. Върбово',
      'с. Върбовчец',
      'с. Въртоп',
      'с. Гайтанци',
      'с. Генерал Мариново',
      'с. Големаново',
      'с. Гомотарци',
      'с. Горни Лом',
      'с. Градец',
      'с. Градсковски колиби',
      'с. Гранитово',
      'с. Граничак',
      'с. Гъмзово',
      'с. Гюргич',
      'с. Делейна',
      'с. Динковица',
      'с. Динково',
      'с. Долни Бошняк',
      'с. Долни Лом',
      'с. Дражинци',
      'с. Дреновец',
      'с. Дружба',
      'с. Дъбравка',
      'с. Дълго поле',
      'с. Държаница',
      'с. Жеглица',
      'с. Ивановци',
      'с. Извор',
      'с. Извор махала',
      'с. Иново',
      'с. Каленик',
      'с. Калина',
      'с. Каниц',
      'с. Капитановци',
      'с. Карбинци',
      'с. Киреево',
      'с. Кладоруб',
      'с. Косово',
      'с. Коста Перчево',
      'с. Костичовци',
      'с. Кошава',
      'с. Крачимир',
      'с. Куделин',
      'с. Кутово',
      'с. Лагошевци',
      'с. Майор Узуново',
      'с. Макреш',
      'с. Мали Дреновец',
      'с. Медешевци',
      'с. Медовница',
      'с. Милчина лъка',
      'с. Неговановци',
      'с. Ново село',
      'с. Новоселци',
      'с. Орешец',
      'с. Острокапци',
      'с. Ошане',
      'с. Периловец',
      'с. Пешаково',
      'с. Плакудер',
      'с. Плешивец',
      'с. Подгоре',
      'с. Покрайна',
      'с. Полетковци',
      'с. Праужда',
      'с. Пролазница',
      'с. Протопопинци',
      'с. Рабиша',
      'с. Раброво',
      'с. Ракитница',
      'с. Раковица',
      'с. Раяновци',
      'с. Репляна',
      'с. Роглец',
      'с. Ружинци',
      'с. Рупци',
      'с. Салаш',
      'с. Септемврийци',
      'с. Синаговци',
      'с. Скомля',
      'с. Слана бара',
      'с. Сланотрън',
      'с. Сливовник',
      'с. Срацимирово',
      'с. Средогрив',
      'с. Стакевци',
      'с. Старопатица',
      'с. Струиндол',
      'с. Тияновци',
      'с. Толовица',
      'с. Тополовец (Кула)',
      'с. Тополовец (Ружинци)',
      'с. Тошевци',
      'с. Търговище',
      'с. Търняне',
      'с. Флорентин',
      'с. Цар Симеоново',
      'с. Цар Шишманово',
      'с. Цар-Петрово',
      'с. Черно поле',
      'с. Чифлик',
      'с. Чичил',
      'с. Чупрене',
      'с. Шипикова махала',
      'с. Шипот',
      'с. Шишенци',
      'с. Яньовец',
      'с. Ярловица',
      'с. Ясен',
    ];
    return gr;
  }
  getVracagrad() {
    let gr = [
      'Бистрец',
      'Вежен',
      'Дъбника',
      'Квартал 103',
      'Квартал 72',
      'Кемера - Художник',
      'Кулата',
      'Медковец',
      'Металург',
      'Младост',
      'Околчица',
      'Подбалканска',
      'Промишлена зона',
      'Промишлена зона - Изток',
      'Река Лева',
      'Самуил',
      'Сениче',
      'Складова база',
      'Студентски град',
      'Хранително-вкусова зона',
      'Център',
      'в.з. Бистрец',
      'м-т Водни градини',
    ];
    return gr;
  }
  getVracaoblast() {
    let gr = [
      'гр. Бяла Слатина',
      'гр. Козлодуй',
      'гр. Криводол',
      'гр. Мездра',
      'гр. Мизия',
      'гр. Оряхово',
      'гр. Роман',
      'с. Алтимир',
      'с. Баница',
      'с. Баурене',
      'с. Бели извор',
      'с. Боденец',
      'с. Борован',
      'с. Ботево',
      'с. Ботуня',
      'с. Брусен',
      'с. Буковец',
      'с. Бутан',
      'с. Бърдарски геран',
      'с. Бързина',
      'с. Бъркачево',
      'с. Веслец',
      'с. Вировско',
      'с. Власатица',
      'с. Войводово',
      'с. Враняк',
      'с. Върбешница',
      'с. Върбица',
      'с. Габаре',
      'с. Галатин',
      'с. Галиче',
      'с. Галово',
      'с. Главаци',
      'с. Гложене',
      'с. Големо Бабино',
      'с. Голямо Пещене',
      'с. Горна Бешовица',
      'с. Горна Кремена',
      'с. Горни Вадин',
      'с. Горно Пещене',
      'с. Градешница',
      'с. Девене',
      'с. Добролево',
      'с. Добруша',
      'с. Долна Бешовица',
      'с. Долна Кремена',
      'с. Долни Вадин',
      'с. Драшан',
      'с. Дърманци',
      'с. Елисейна',
      'с. Зверино',
      'с. Згориград',
      'с. Злидол',
      'с. Игнатица',
      'с. Кален',
      'с. Камено поле',
      'с. Караш',
      'с. Комарево',
      'с. Костелево',
      'с. Краводер',
      'с. Крапец',
      'с. Крета',
      'с. Крива бара',
      'с. Крушовица',
      'с. Кунино',
      'с. Курново',
      'с. Лесковец',
      'с. Лесура',
      'с. Лик',
      'с. Лиляче',
      'с. Липница',
      'с. Лютаджик',
      'с. Лютиброд',
      'с. Лютидол',
      'с. Мало Пещене',
      'с. Малорад',
      'с. Манастирище',
      'с. Марково равнище',
      'с. Михайлово',
      'с. Моравица',
      'с. Мраморен',
      'с. Нефела',
      'с. Нивянин',
      'с. Оселна',
      'с. Осен',
      'с. Ослен Криводол',
      'с. Остров',
      'с. Оходен',
      'с. Очиндол',
      'с. Паволче',
      'с. Попица',
      'с. Пудрия',
      'с. Радовене',
      'с. Ракево',
      'с. Ребърково',
      'с. Рогозен',
      'с. Руска Бела',
      'с. Сараево',
      'с. Селановци',
      'с. Синьо бърдо',
      'с. Сираково',
      'с. Соколаре',
      'с. Софрониево',
      'с. Средни рът',
      'с. Старо село',
      'с. Стояновци',
      'с. Струпец',
      'с. Типченица',
      'с. Тишевица',
      'с. Тлачене',
      'с. Три кладенци',
      'с. Търнава',
      'с. Търнак',
      'с. Уровене',
      'с. Фурен',
      'с. Хайредин',
      'с. Хубавене',
      'с. Хърлец',
      'с. Цаконица',
      'с. Царевец',
      'с. Челопек',
      'с. Чирен',
    ];
    return gr;
  }
  getGabrovograd() {
    let gr = [
      'Беленци',
      'Бичкиня',
      'Бойката',
      'Болта',
      'Борово',
      'Варчевци',
      'Велчевци',
      'Войново',
      'Гачевци',
      'Горни Бакойци',
      'Долни Бакойци',
      'Дядо Дянко',
      'Етъра',
      'Златари',
      'Илевци',
      'Йовчевци',
      'Киевци',
      'Кряковци',
      'Лисец',
      'Лютаци',
      'Младост',
      'Негенци',
      'Недевци',
      'Пройновци',
      'Промишлена зона',
      'Рачевци',
      'Русевци',
      'Сарани',
      'Сирмани',
      'Славовци',
      'Стойковци',
      'Тлъчници',
      'Тончевци',
      'Трендафил 1',
      'Трендафил 2',
      'Търсиите',
      'Хаджицонев мост',
      'Център',
      'Чехлевци',
      'Шенини',
      'Ябълка',
    ];
    return gr;
  }
  getGabrovooblast() {
    let gr = [
      'гр. Дряново',
      'гр. Плачковци',
      'гр. Севлиево',
      'гр. Трявна',
      'с. Агатово',
      'с. Азманите',
      'с. Ангелов',
      'с. Армените',
      'с. Армянковци',
      'с. Баева ливада',
      'с. Баевци',
      'с. Балалея',
      'с. Баланите',
      'с. Балванците',
      'с. Балиновци',
      'с. Банари',
      'с. Бангейци',
      'с. Банковци',
      'с. Батошево',
      'с. Бахреци',
      'с. Бекриите',
      'с. Белица',
      'с. Беломъжите',
      'с. Бериево',
      'с. Бижовци',
      'с. Билкини',
      'с. Боазът',
      'с. Бобевци',
      'с. Богатово',
      'с. Богданчовци',
      'с. Боженците',
      'с. Бойновци',
      'с. Бойчета',
      'с. Болтата',
      'с. Борики',
      'с. Борското',
      'с. Брежниците',
      'с. Брънеците',
      'с. Буря',
      'с. Бучуковци',
      'с. Българи',
      'с. Бърдарите',
      'с. Бърдени',
      'с. Бялково',
      'с. Валевци',
      'с. Веленци',
      'с. Велково',
      'с. Велковци',
      'с. Велчовци',
      'с. Ветрово',
      'с. Владовци',
      'с. Влайчовци',
      'с. Власатили',
      'с. Влаховци',
      'с. Войниците',
      'с. Войнишка',
      'с. Врабците',
      'с. Враниловци',
      'с. Вълков дол',
      'с. Вълковци',
      'с. Гайдари',
      'с. Гайкини',
      'с. Гайтаните',
      'с. Ганчовец',
      'с. Гарван',
      'с. Геновци',
      'с. Генчовци (Габрово)',
      'с. Генчовци (Трявна)',
      'с. Геня',
      'с. Гергини',
      'с. Геша',
      'с. Гледаци',
      'с. Глутниците',
      'с. Глушка',
      'с. Гоздейка',
      'с. Големи Българени',
      'с. Големи Станчовци',
      'с. Горна Росица',
      'с. Горни Върпища',
      'с. Горни Дамяновци',
      'с. Горни Драгойча',
      'с. Горни Маренци',
      'с. Горни Радковци',
      'с. Горни Цоневци',
      'с. Горнова могила',
      'с. Горяни',
      'с. Гостилица',
      'с. Градище',
      'с. Градница',
      'с. Гръблевци',
      'с. Гъбене',
      'с. Гърня',
      'с. Даевци',
      'с. Дамяново',
      'с. Дебел дял',
      'с. Дебелцово',
      'с. Денчевци',
      'с. Джумриите',
      'с. Джуровци',
      'с. Дивеци',
      'с. Димиевци',
      'с. Дисманица',
      'с. Длъгня',
      'с. Добревци',
      'с. Добрените',
      'с. Добромирка',
      'с. Долни Върпища',
      'с. Долни Драгойча',
      'с. Долни Маренци',
      'с. Долни Радковци',
      'с. Долни Томчевци',
      'с. Донино',
      'с. Донкино',
      'с. Дончовци',
      'с. Доча',
      'с. Драгановци',
      'с. Драганчетата',
      'с. Драгиевци',
      'с. Драгневци',
      'с. Драгомани',
      'с. Драндарите',
      'с. Дрянът',
      'с. Думници',
      'с. Дурча',
      'с. Душево',
      'с. Душевски колиби',
      'с. Дървари',
      'с. Дъскарите',
      'с. Дялък',
      'с. Езерото',
      'с. Еленците',
      'с. Енев рът',
      'с. Енчовци',
      'с. Живко',
      'с. Жълтеш',
      'с. Зая',
      'с. Здравковец',
      'с. Зеленика',
      'с. Зелено дърво',
      'с. Златевци',
      'с. Иван Димов',
      'с. Иванили',
      'с. Иванковци',
      'с. Иглика',
      'с. Игнатовци',
      'с. Идилево',
      'с. Източник',
      'с. Иринеци',
      'с. Искра',
      'с. Йововци',
      'с. Каломен',
      'с. Калчовци',
      'с. Камещица',
      'с. Караиванца',
      'с. Карали',
      'с. Карамичевци',
      'с. Кастел',
      'с. Катранджии',
      'с. Кашенци',
      'с. Керека',
      'с. Керените',
      'с. Киевци',
      'с. Киселковци',
      'с. Кисийците',
      'с. Кметовци',
      'с. Кметчета',
      'с. Коевци',
      'с. Кози рог',
      'с. Койчовци',
      'с. Колишовци',
      'с. Колю Ганев',
      'с. Конарското',
      'с. Копчелиите',
      'с. Корията',
      'с. Кормянско',
      'с. Косарка',
      'с. Косилка',
      'с. Костадините',
      'с. Костенковци',
      'с. Крамолин',
      'с. Креслювци',
      'с. Крушево',
      'с. Кръвеник',
      'с. Крънча',
      'с. Кръстеняците',
      'с. Кръстец',
      'с. Кукля',
      'с. Куманите',
      'с. Купен',
      'с. Къртипъня',
      'с. Лесичарка',
      'с. Ловнидол',
      'с. Лоза',
      'с. Малини',
      'с. Малиново',
      'с. Малки Българени',
      'с. Малки Вършец',
      'с. Малки Станчовци',
      'с. Малуша',
      'с. Малчовци',
      'с. Маневци',
      'с. Маноя',
      'с. Мариновци',
      'с. Маруцековци',
      'с. Матешовци',
      'с. Междени',
      'с. Мечковица',
      'с. Милевци',
      'с. Милковци',
      'с. Михайловци',
      'с. Миховци',
      'с. Мичковци',
      'с. Младен',
      'с. Млечево',
      'с. Могилите',
      'с. Моровеците',
      'с. Мрахори',
      'с. Мръзеци',
      'с. Музга',
      'с. Муця',
      'с. Недялковци',
      'с. Нейчовци',
      'с. Неновци',
      'с. Никачковци',
      'с. Николаево',
      'с. Николчовци',
      'с. Новаковци',
      'с. Ножерите',
      'с. Носеите',
      'с. Овощарци',
      'с. Околиите',
      'с. Орловци',
      'с. Ошаните',
      'с. Павлевци',
      'с. Парчовци',
      'с. Пейна',
      'с. Пейовци',
      'с. Пенковци',
      'с. Петко Славейков',
      'с. Петковци',
      'с. Петровци',
      'с. Пецовци',
      'с. Планинци',
      'с. Плачка',
      'с. Побък',
      'с. Попари',
      'с. Попгергевци',
      'с. Поповци',
      'с. Попрайковци',
      'с. Попска',
      'с. Поток',
      'с. Прахали',
      'с. Престой',
      'с. Продановци',
      'с. Пържиграх',
      'с. Пъртевци',
      'с. Пърша',
      'с. Раданчето',
      'с. Радевци',
      'с. Радино',
      'с. Радовци',
      'с. Радоевци',
      'с. Раевци',
      'с. Райновци',
      'с. Райнушковци',
      'с. Ралевци',
      'с. Раховци',
      'с. Рачевци',
      'с. Рачовци',
      'с. Рашовите',
      'с. Редешковци',
      'с. Ритя',
      'с. Рогулят',
      'с. Руевци',
      'с. Руйчовци',
      'с. Руня',
      'с. Русиновци',
      'с. Рязковци',
      'с. Ряховците',
      'с. Саласука',
      'с. Свинарски дол',
      'с. Свирци',
      'с. Седянковци',
      'с. Сейковци',
      'с. Селище',
      'с. Семерджиите',
      'с. Сенник',
      'с. Сечен камък',
      'с. Скалско',
      'с. Скорците',
      'с. Славейково',
      'с. Сливово',
      'с. Смиловци',
      'с. Соколово',
      'с. Солари',
      'с. Спанци',
      'с. Спасовци',
      'с. Стайновци',
      'с. Станча',
      'с. Станчов хан',
      'с. Старилковци',
      'с. Стефаново',
      'с. Стоевци',
      'с. Стойковци',
      'с. Стойчовци',
      'с. Стоките',
      'с. Столът',
      'с. Стоманеците',
      'с. Стражата',
      'с. Стръмци',
      'с. Сухолоевци',
      'с. Съботковци',
      'с. Сяровци',
      'с. Табашка',
      'с. Тодореците',
      'с. Тодоровци',
      'с. Тодорчета',
      'с. Томчевци',
      'с. Торбалъжите',
      'с. Трапесковци',
      'с. Трънито',
      'с. Тумбалово',
      'с. Туркинча',
      'с. Търхово',
      'с. Угорелец',
      'с. Узуните',
      'с. Урвата',
      'с. Фърговци',
      'с. Фъревци',
      'с. Фъртуни',
      'с. Харачерите',
      'с. Хирево',
      'с. Христовци',
      'с. Царева ливада',
      'с. Цвятковци',
      'с. Чавеи',
      'с. Чакалите',
      'с. Чарково',
      'с. Червена локва',
      'с. Черневци',
      'с. Черновръх',
      'с. Читаковци',
      'с. Чукилите',
      'с. Чуково',
      'с. Шарани',
      'с. Шипчените',
      'с. Шопите',
      'с. Шумата',
      'с. Шушня',
      'с. Ябълковци',
      'с. Явор',
      'с. Яворец',
      'с. Янковци',
      'с. Янтра',
      'с. Ясените',
      'яз. Ал. Стамболийски',
    ];
    return gr;
  }
  getDobrichgrad() {
    let gr = [
      'Автогара',
      'Балик',
      'Градска болница',
      'Добротица - Албенска',
      'Добротица - Варненска',
      'Дружба 1',
      'Дружба 2',
      'Дружба 3',
      'Дружба 4',
      'Дунавите',
      'Енергоразпределение',
      'ЖП Гара',
      'Иглика',
      'Изгрев',
      'Йовково',
      'Кралска зона',
      'Пинета',
      'Промишлена зона - Запад',
      'Промишлена зона - Север',
      'Рилци',
      'Свети Георги',
      'Северен парк',
      'Строител',
      'Христо Ботев',
      'Център',
      'Червената пръст',
      'в.з. Лозята',
      'м-т Газибаба',
      'м-т Минково',
    ];
    return gr;
  }
  getDobrichoblast() {
    let gr = [
      'гр. Балчик',
      'гр. Генерал Тошево',
      'гр. Каварна',
      'гр. Тервел',
      'гр. Шабла',
      'к.к. Албена',
      'с. Абрит',
      'с. Ал. Стамболийски',
      'с. Александрия',
      'с. Алцек',
      'с. Ангеларий',
      'с. Балик',
      'с. Балканци',
      'с. Батово',
      'с. Бдинци',
      'с. Бежаново',
      'с. Безводица',
      'с. Безмер',
      'с. Белгун',
      'с. Бенковски',
      'с. Било',
      'с. Бистрец',
      'с. Бобовец',
      'с. Богдан',
      'с. Божан',
      'с. Божаново',
      'с. Божурец',
      'с. Божурово',
      'с. Бонево',
      'с. Бранище',
      'с. Брестница',
      'с. Брястово',
      'с. Българево',
      'с. Ваклино',
      'с. Василево',
      'с. Ведрина',
      'с. Великово',
      'с. Видно',
      'с. Вичово',
      'с. Владимирово',
      'с. Воднянци',
      'с. Войниково',
      'с. Вранино',
      'с. Вратарите',
      'с. Врачанци',
      'с. Габер',
      'с. Генерал Колево',
      'с. Гешаново',
      'с. Главанци',
      'с. Горица',
      'с. Горичане',
      'с. Горун',
      'с. Градини',
      'с. Градница',
      'с. Граничар',
      'с. Гурково',
      'с. Гуслар',
      'с. Дебрене',
      'с. Добрево',
      'с. Добрин',
      'с. Долина',
      'с. Дончево',
      'с. Драганово',
      'с. Дропла',
      'с. Дряновец',
      'с. Дуранкулак',
      'с. Дъбовик',
      'с. Дъбрава',
      'с. Езерец',
      'с. Енево',
      'с. Ефрейтор Бакалово',
      'с. Жегларци',
      'с. Житен',
      'с. Житница',
      'с. Загорци',
      'с. Захари Стояново',
      'с. Земенци',
      'с. Зимница',
      'с. Златия',
      'с. Змеево',
      'с. Зограф',
      'с. Зърнево',
      'с. Изворово',
      'с. Иречек',
      'с. Йовково',
      'с. Каблешково',
      'с. Калина',
      'с. Камен',
      'с. Камен бряг',
      'с. Капитан Димитрово',
      'с. Карапелит',
      'с. Карвуна',
      'с. Кардам',
      'с. Кладенци',
      'с. Козлодуйци',
      'с. Коларци',
      'с. Конаре',
      'с. Коритен',
      'с. Котленци',
      'с. Кочмар',
      'с. Крагулево',
      'с. Краище',
      'с. Кранево',
      'с. Крапец',
      'с. Красен',
      'с. Кремена',
      'с. Крупен',
      'с. Крушари',
      'с. Къпиново',
      'с. Ловчанци',
      'с. Лозенец',
      'с. Лозница',
      'с. Ломница',
      'с. Люляково',
      'с. Лясково',
      'с. Ляхово',
      'с. Мали извор',
      'с. Малина',
      'с. Малка Смолница',
      'с. Медово',
      'с. Методиево',
      'с. Миладиновци',
      'с. Могилище',
      'с. Нейково',
      'с. Нова Камена',
      'с. Ново Ботево',
      'с. Оброчище',
      'с. Овчарово',
      'с. Огняново',
      'с. Огражден',
      'с. Одринци',
      'с. Одърци',
      'с. Оногур',
      'с. Опанец',
      'с. Орлова могила',
      'с. Орляк',
      'с. Паскалево',
      'с. Петлешково',
      'с. Писарово',
      'с. Плачидол',
      'с. Пленимир',
      'с. Победа',
      'с. Подслон',
      'с. Полковник Дяково',
      'с. Полковник Иваново',
      'с. Полковник Минково',
      'с. Полковник Савово',
      'с. Полковник Свещарово',
      'с. Попгригорово',
      'с. Попгруево',
      'с. Поручик Кърджиево',
      'с. Поручик Чунчево',
      'с. Преселенци',
      'с. Преспа',
      'с. Прилеп',
      'с. Приморци',
      'с. Присад',
      'с. Пролез',
      'с. Професор Златарски',
      'с. Пчеларово',
      'с. Пчелино',
      'с. Пчелник',
      'с. Равнец',
      'с. Раковски',
      'с. Рогачево',
      'с. Рогозина',
      'с. Росен',
      'с. Росеново',
      'с. Росица',
      'с. Самуилово',
      'с. Свети Никола',
      'с. Свобода',
      'с. Северняк',
      'с. Северци',
      'с. Селце',
      'с. Сенокос',
      'с. Септемврийци',
      'с. Сираково',
      'с. Славеево',
      'с. Сливенци',
      'с. Смин',
      'с. Смолница',
      'с. Сноп',
      'с. Снягово',
      'с. Соколник',
      'с. Соколово',
      'с. Спасово',
      'с. Средина',
      'с. Стаевци',
      'с. Стефан Караджа',
      'с. Стефаново',
      'с. Стожер',
      'с. Стражица',
      'с. Сърнец',
      'с. Сърнино',
      'с. Твърдица',
      'с. Телериг',
      'с. Топола',
      'с. Травник',
      'с. Тригорци',
      'с. Тюленово',
      'с. Тянево',
      'с. Узово',
      'с. Фелдфебел Денково',
      'с. Хаджи Димитър',
      'с. Хитово',
      'с. Храброво',
      'с. Царевец',
      'с. Царичино',
      'с. Църква',
      'с. Челопечене',
      'с. Черна',
      'с. Черноморци',
      'с. Чернооково',
      'с. Честименско',
    ];
    return gr;
  }
  getKurdjaligrad() {
    let gr = [
      'Байкал',
      'Боровец',
      'Веселчане',
      'Възрожденци',
      'Гледка',
      'Горна Гледка',
      'Прилепци',
      'Промишлена зона - А',
      'Промишлена зона - Б',
      'Промишлена зона - Изток',
      'Промишлена зона - Юг',
      'Резбарци',
      'Студен кладенец',
      'Център',
    ];
    return gr;
  }
  getKurdjalioblast() {
    let gr = [
      'гр. Ардино',
      'гр. Джебел',
      'гр. Крумовград',
      'гр. Момчилград',
      'с. Аврамово',
      'с. Аврен',
      'с. Айрово',
      'с. Албанци',
      'с. Априлци',
      'с. Ауста',
      'с. Ахрянско',
      'с. Багра',
      'с. Багрилци',
      'с. Багрянка',
      'с. Бакалите',
      'с. Балабаново',
      'с. Бараци',
      'с. Башево',
      'с. Бащино',
      'с. Бедрово',
      'с. Безводно',
      'с. Бели вир',
      'с. Бели пласт',
      'с. Бенковски',
      'с. Беснурка',
      'с. Биволяне',
      'с. Бистроглед',
      'с. Благун',
      'с. Бленика',
      'с. Богатино',
      'с. Божак',
      'с. Божурци',
      'с. Бойник',
      'с. Бойно',
      'с. Болярци',
      'с. Боровица',
      'с. Боровско',
      'с. Босилица',
      'с. Бостанци',
      'с. Брегово',
      'с. Брежана',
      'с. Брезен',
      'с. Брош',
      'с. Бряговец',
      'с. Бук',
      'с. Бърза река',
      'с. Бързея',
      'с. Бял извор',
      'с. Бяла поляна',
      'с. Бялка',
      'с. Велешани',
      'с. Великденче',
      'с. Верско',
      'с. Висока',
      'с. Висока поляна',
      'с. Вишеград',
      'с. Водач',
      'с. Воденичарско',
      'с. Вождово',
      'с. Войново',
      'с. Воловарци',
      'с. Вранско',
      'с. Врело',
      'с. Възел',
      'с. Вълкович',
      'с. Вълчанка',
      'с. Върбен',
      'с. Върбенци',
      'с. Върли дол',
      'с. Върхари',
      'с. Габрово',
      'с. Генерал Гешево',
      'с. Главатарци',
      'с. Главник',
      'с. Глухар',
      'с. Гняздово',
      'с. Голобрад',
      'с. Голям Девесил',
      'с. Голяма бара',
      'с. Голяма Чинка',
      'с. Голямо Каменяне',
      'с. Горна крепост',
      'с. Горна кула',
      'с. Горни Юруци',
      'с. Горно Кирково',
      'с. Горно Къпиново',
      'с. Горно Прахово',
      'с. Горски извор',
      'с. Горско Дюлево',
      'с. Гривка',
      'с. Гривяк',
      'с. Груево',
      'с. Гулийка',
      'с. Гулия',
      'с. Гургулица',
      'с. Гърбище',
      'с. Гъсково',
      'с. Даскалово',
      'с. Девесилица',
      'с. Девесилово',
      'с. Девинци',
      'с. Дедец',
      'с. Дедино',
      'с. Делвино',
      'с. Джанка',
      'с. Джелепско',
      'с. Джерово',
      'с. Доборско',
      'с. Добриново',
      'с. Добринци',
      'с. Добромирци',
      'с. Дойранци',
      'с. Долище',
      'с. Долна крепост',
      'с. Долна кула',
      'с. Долни Юруци',
      'с. Долно Къпиново',
      'с. Долно Прахово',
      'с. Домище',
      'с. Драганово',
      'с. Дрангово',
      'с. Дружинци',
      'с. Друмче',
      'с. Дрянова глава',
      'с. Душинково',
      'с. Душка',
      'с. Дъждино',
      'с. Дъждовник',
      'с. Дъждовница',
      'с. Дънгово',
      'с. Дюлица',
      'с. Дядовско',
      'с. Дядовци',
      'с. Егрек',
      'с. Едрино',
      'с. Енчец',
      'с. Еньовче',
      'с. Еровете',
      'с. Железник',
      'с. Желъдово',
      'с. Женда',
      'с. Жинзифово',
      'с. Житарник',
      'с. Житница',
      'с. Жълти рид',
      'с. Жълтика',
      'с. Жълтуша',
      'с. Завоя',
      'с. Загорски',
      'с. Загорско',
      'с. Зайчино',
      'с. Звездел',
      'с. Звезделина',
      'с. Звезден',
      'с. Звиница',
      'с. Звънарка',
      'с. Звъника',
      'с. Звънче',
      'с. Здравчец',
      'с. Зелениково',
      'с. Зимзелен',
      'с. Зиморница',
      'с. Златолист',
      'с. Зорница',
      'с. Иванци',
      'с. Илийско',
      'с. Илиница',
      'с. Искра',
      'с. Йончово',
      'с. Каблешково',
      'с. Казаците',
      'с. Калайджиево',
      'с. Калинка',
      'с. Калоянци',
      'с. Каменарци',
      'с. Каменец',
      'с. Каменка',
      'с. Каменяне',
      'с. Кандилка',
      'с. Каняк',
      'с. Карамфил',
      'с. Качулка',
      'с. Каялоба',
      'с. Кирково',
      'с. Китна',
      'с. Китница',
      'с. Кобиляне',
      'с. Ковил',
      'с. Кожухарци',
      'с. Козица',
      'с. Козлево',
      'с. Кокиче',
      'с. Кокошане',
      'с. Комунига',
      'с. Конево',
      'с. Контил',
      'с. Конче',
      'с. Копитник',
      'с. Кос',
      'с. Костино',
      'с. Костурино',
      'с. Котлари',
      'с. Крайно село',
      'с. Кран',
      'с. Красино',
      'с. Кремен',
      'с. Кременец',
      'с. Крилатица',
      'с. Крин',
      'с. Кроячево',
      'с. Крушевска',
      'с. Крушка',
      'с. Кукуряк',
      'с. Купците',
      'с. Куцово',
      'с. Къклица',
      'с. Кърчовско',
      'с. Кьосево',
      'с. Лале',
      'с. Латинка',
      'с. Лебед',
      'с. Левци',
      'с. Ленище',
      'с. Летовник',
      'с. Лещарка',
      'с. Лимец',
      'с. Лисиците',
      'с. Лозенградци',
      'с. Луличка',
      'с. Лъвово',
      'с. Любино',
      'с. Люляково',
      'с. Лясково',
      'с. Майсторово',
      'с. Мак',
      'с. Македонци',
      'с. Малка Чинка',
      'с. Малко Каменяне',
      'с. Малкоч',
      'с. Малък Девесил',
      'с. Манчево',
      'с. Мартино',
      'с. Медевци',
      'с. Метлика',
      'с. Метличина',
      'с. Метличка',
      'с. Миладиново',
      'с. Минзухар',
      'с. Мишевско',
      'с. Млечино',
      'с. Могиляне',
      'с. Модрен',
      'с. Момина сълза',
      'с. Морянци',
      'с. Мост',
      'с. Мрежичко',
      'с. Мурга',
      'с. Мургово',
      'с. Мусево',
      'с. Мъглене',
      'с. Мъдрец',
      'с. Нане',
      'с. Нановица',
      'с. Небеска',
      'с. Невестино',
      'с. Ненково',
      'с. Неофит Бозвелиево',
      'с. Нови пазар',
      'с. Новоселище',
      'с. Ночево',
      'с. Обичник',
      'с. Овчари',
      'с. Овчево',
      'с. Опълченско',
      'с. Орех',
      'с. Орешари',
      'с. Орешница',
      'с. Орлица',
      'с. Островец',
      'с. Островица',
      'с. Охлювец',
      'с. Падало',
      'с. Падина',
      'с. Пазарци',
      'с. Паничково',
      'с. Панчево',
      'с. Папрат',
      'с. Паспал',
      'с. Патица',
      'с. Пашинци',
      'с. Пелин',
      'с. Пеньово',
      'с. Пепелище',
      'с. Перперек',
      'с. Перуника',
      'с. Песнопой',
      'с. Петелово',
      'с. Петлино',
      'с. Пиявец',
      'с. Плазище',
      'с. Плешинци',
      'с. Пловка',
      'с. Повет',
      'с. Подвръх',
      'с. Подкова',
      'с. Подрумче',
      'с. Полковник Желязово',
      'с. Полянец',
      'с. Постник',
      'с. Поточарка',
      'с. Поточе',
      'с. Поточница',
      'с. Правдолюб',
      'с. Пресека',
      'с. Прилепци',
      'с. Припек',
      'с. Прогрес',
      'с. Пропаст',
      'с. Пряпорец',
      'с. Птичар',
      'с. Пчеларово',
      'с. Пъдарци',
      'с. Първенци',
      'с. Първица',
      'с. Равен',
      'с. Ралица',
      'с. Раличево',
      'с. Рани лист',
      'с. Растник',
      'с. Резбарци',
      'с. Рибарци',
      'с. Рибино',
      'с. Ридино',
      'с. Ридово',
      'с. Рогач',
      'с. Рогозари',
      'с. Рогозче',
      'с. Родопско',
      'с. Рожденско',
      'с. Рудина',
      'с. Русалина',
      'с. Русалско',
      'с. Ручей',
      'с. Рът',
      'с. Садовица',
      'с. Самовила',
      'с. Самодива',
      'с. Самокитка',
      'с. Сбор',
      'с. Сватбаре',
      'с. Светлен',
      'с. Светулка',
      'с. Свобода',
      'с. Свободиново',
      'с. Севдалина',
      'с. Седефче',
      'с. Седлари',
      'с. Седларци',
      'с. Седловина',
      'с. Секирка',
      'с. Сенце',
      'с. Сестринско',
      'с. Синделци',
      'с. Синигер',
      'с. Синчец',
      'с. Сипей',
      'с. Сипец',
      'с. Скалак',
      'с. Скалина',
      'с. Скалище',
      'с. Скална глава',
      'с. Скърбино',
      'с. Сладкодум',
      'с. Сливарка',
      'с. Слънчоглед',
      'с. Снежинка',
      'с. Соколино',
      'с. Соколите',
      'с. Соколско',
      'с. Соколяне',
      'с. Солище',
      'с. Софийци',
      'с. Сполука',
      'с. Срединка',
      'с. Среднево',
      'с. Средска',
      'с. Средско',
      'с. Срънско',
      'с. Стар читак',
      'с. Старейшино',
      'с. Стари чал',
      'с. Старо място',
      'с. Старово',
      'с. Стоманци',
      'с. Стояново',
      'с. Стражевци',
      'с. Стражец',
      'с. Стражница',
      'с. Странджево',
      'с. Страхил войвода',
      'с. Стремово',
      'с. Стремци',
      'с. Стрижба',
      'с. Студен кладенец',
      'с. Сухово',
      'с. Сърнак',
      'с. Сярци',
      'с. Татково',
      'с. Татул',
      'с. Телчарка',
      'с. Теменуга',
      'с. Тинтява',
      'с. Тихомир',
      'с. Токачка',
      'с. Тополка',
      'с. Тополчане',
      'с. Три могили',
      'с. Търна',
      'с. Търновци',
      'с. Търносливка',
      'с. Тютюнче',
      'с. Устрен',
      'с. Фотиново',
      'с. Хаджийско',
      'с. Хисар',
      'с. Ходжовци',
      'с. Храстово',
      'с. Хромица',
      'с. Царевец',
      'с. Царино',
      'с. Цвятово',
      'с. Църквица',
      'с. Чавка',
      'с. Чайка',
      'с. Чакаларово',
      'с. Чакалци',
      'с. Чал',
      'с. Чеганци',
      'с. Червена скала',
      'с. Черешица',
      'с. Черешка',
      'с. Черна нива',
      'с. Черна скала',
      'с. Чернигово',
      'с. Черничево',
      'с. Чернооки',
      'с. Черноочене',
      'с. Черньовци',
      'с. Чилик',
      'с. Чифлик',
      'с. Чичево',
      'с. Чобанка',
      'с. Чомаково',
      'с. Чорбаджийско',
      'с. Чубрика',
      'с. Чуково',
      'с. Шипок',
      'с. Широко поле',
      'с. Шопци',
      'с. Шумнатица',
      'с. Щерна',
      'с. Юнаци',
      'с. Ябълковец',
      'с. Ябълчени',
      'с. Яворово',
      'с. Яковица',
      'с. Ямино',
      'с. Янино',
      'с. Яребица',
      'с. Ястреб',
      'яз. Кърджали',
      'яз. Студен Кладенец',
    ];
    return gr;
  }
  getKustendilgrad() {
    let gr = [
      'Бузлуджа',
      'Бял камък',
      'Върташево',
      'Герена',
      'Запад',
      'Изток',
      'Ильо войвода',
      'Колуша',
      'Могилата',
      'Никола Въжаров',
      'Осогово',
      'Промишлена зона - Изток',
      'Промишлена зона - Север',
      'Румена войвода',
      'Христо Ботев',
      'Център',
      'в.з. Север',
      'в.з. Юг',
    ];
    return gr;
  }
  getKustendiloblast() {
    let gr = [
      'главен път Е79',
      'гр. Бобов дол',
      'гр. Бобошево',
      'гр. Дупница',
      'гр. Кочериново',
      'гр. Рила',
      'гр. Сапарева баня',
      'с. Бабино',
      'с. Бабинска река',
      'с. Багренци',
      'с. Бадино',
      'с. Баланово',
      'с. Бараково',
      'с. Берсин',
      'с. Бистрица',
      'с. Блажиево',
      'с. Блатец',
      'с. Блатино',
      'с. Блато',
      'с. Бобешино',
      'с. Богослов',
      'с. Боровец',
      'с. Брест',
      'с. Буново',
      'с. Бураново',
      'с. Бъзовица',
      'с. Ваксево',
      'с. Ветрен',
      'с. Висока могила',
      'с. Вратца',
      'с. Вуково',
      'с. Габрешевци',
      'с. Гирчевци',
      'с. Голема Фуча',
      'с. Големо село',
      'с. Голям Върбовник',
      'с. Горановци',
      'с. Горна Брестница',
      'с. Горна Гращица',
      'с. Горна Козница',
      'с. Горни Коритен',
      'с. Горно Кобиле',
      'с. Горно Уйно',
      'с. Грамаде',
      'с. Грамаждано',
      'с. Граница',
      'с. Гурбановци',
      'с. Гърбино',
      'с. Гърляно',
      'с. Гюешево',
      'с. Дворище',
      'с. Делян',
      'с. Джерман',
      'с. Длъхчево-Сабляр',
      'с. Добри дол',
      'с. Доброво',
      'с. Дождевица',
      'с. Долистово',
      'с. Долна Гращица',
      'с. Долна Козница',
      'с. Долни Коритен',
      'с. Долно Кобиле',
      'с. Долно село',
      'с. Долно Уйно',
      'с. Драговищица',
      'с. Драгодан',
      'с. Драгойчинци',
      'с. Друмохар',
      'с. Дяково',
      'с. Еремия',
      'с. Жабокрът',
      'с. Жеравино',
      'с. Жиленци',
      'с. Згурово',
      'с. Злогош',
      'с. Ивановци',
      'с. Илия',
      'с. Кадровица',
      'с. Каменик',
      'с. Каменичка Скакавица',
      'с. Катрище',
      'с. Киселица',
      'с. Коняво',
      'с. Копиловци',
      'с. Коприва',
      'с. Коркина',
      'с. Косово',
      'с. Крайни дол',
      'с. Крайници',
      'с. Кременик',
      'с. Крумово',
      'с. Кутугерци',
      'с. Кършалево',
      'с. Лелинци',
      'с. Леска',
      'с. Лиляч',
      'с. Лисец',
      'с. Лозно',
      'с. Локвата',
      'с. Ломница',
      'с. Мазарачево',
      'с. Мала Фуча',
      'с. Мали Върбовник',
      'с. Мало село',
      'с. Метохия',
      'с. Мламолово',
      'с. Мурсалево',
      'с. Мърводол',
      'с. Невестино',
      'с. Неделкова Гращица',
      'с. Николичевци',
      'с. Нови чифлик',
      'с. Ново село',
      'с. Новоселяне',
      'с. Овчарци',
      'с. Падала',
      'с. Палатово',
      'с. Паничарево',
      'с. Паничище',
      'с. Пастра',
      'с. Пастух',
      'с. Пелатиково',
      'с. Пиперево',
      'с. Пиперков чифлик',
      'с. Побит камък',
      'с. Полетинци',
      'с. Полска Скакавица',
      'с. Пороминово',
      'с. Преколница',
      'с. Радловци',
      'с. Раждавица',
      'с. Раково',
      'с. Раненци',
      'с. Рашка Гращица',
      'с. Режинци',
      'с. Ресилово',
      'с. Ръсово',
      'с. Савойски',
      'с. Сажденик',
      'с. Самораново',
      'с. Сапарево',
      'с. Скрино',
      'с. Скриняно',
      'с. Слатино',
      'с. Слокощица',
      'с. Смоличано',
      'с. Смочево',
      'с. Соволяно',
      'с. Сопово',
      'с. Средорек',
      'с. Стенско',
      'с. Стоб',
      'с. Страдалово',
      'с. Сушица',
      'с. Таваличево',
      'с. Тишаново',
      'с. Тополница',
      'с. Трекляно',
      'с. Търновлаг',
      'с. Търсино',
      'с. Усойка',
      'с. Уши',
      'с. Фролош',
      'с. Церовица',
      'с. Циклово',
      'с. Црешново',
      'с. Църварица',
      'с. Цървена ябълка',
      'с. Цървендол',
      'с. Цървеняно',
      'с. Цървище',
      'с. Чеканец',
      'с. Червен брег',
      'с. Четирци',
      'с. Чешлянци',
      'с. Чудинци',
      'с. Шатрово',
      'с. Шипочано',
      'с. Шишковци',
      'с. Ябълково',
      'с. Яхиново',
    ];
    return gr;
  }
  getLovechgrad() {
    let gr = [
      'Вароша',
      'Гозница',
      'Дикисана',
      'Дръстене',
      'Здравец',
      'Младост',
      'Продимчец',
      'Промишлена зона',
      'Промишлена зона - Изток',
      'Промишлена зона - Север',
      'Център',
      'Червен бряг',
      'Широк център',
      'в.з. Бабаковец',
      'в.з. Синан тепе',
    ];
    return gr;
  }
  getLovechoblast() {
    let gr = [
      'главен път Е83',
      'гр. Априлци',
      'гр. Летница',
      'гр. Луковит',
      'гр. Тетевен',
      'гр. Троян',
      'гр. Угърчин',
      'гр. Ябланица',
      'магистрала Хемус',
      'с. Абланица',
      'с. Александрово',
      'с. Баба Стана',
      'с. Бабинци',
      'с. Балабанско',
      'с. Балканец',
      'с. Батулци',
      'с. Баховица',
      'с. Бежаново',
      'с. Беленци',
      'с. Бели Осъм',
      'с. Белиш',
      'с. Борима',
      'с. Брестница',
      'с. Брестово',
      'с. Българене',
      'с. Български извор',
      'с. Василковска махала',
      'с. Васильово',
      'с. Велчево',
      'с. Владиня',
      'с. Врабево',
      'с. Габърска',
      'с. Галата',
      'с. Глогово',
      'с. Гложене',
      'с. Голец',
      'с. Голям извор',
      'с. Голяма Брестница',
      'с. Голяма Желязна',
      'с. Горан',
      'с. Горно Павликене',
      'с. Горно трапе',
      'с. Горско Сливово',
      'с. Гостиня',
      'с. Градежница',
      'с. Гумощник',
      'с. Дебнево',
      'с. Деветаки',
      'с. Дерманци',
      'с. Дивчовото',
      'с. Добревци',
      'с. Добродан',
      'с. Дойренци',
      'с. Долна Маргатина',
      'с. Драгана',
      'с. Драшкова поляна',
      'с. Дренов',
      'с. Дрянска',
      'с. Дъбен',
      'с. Дъбрава',
      'с. Дъбравата',
      'с. Дълбок дол',
      'с. Жеравица',
      'с. Жидов дол',
      'с. Златна Панега',
      'с. Иваншница',
      'с. Изворче',
      'с. Йоглав',
      'с. Казачево',
      'с. Калейца',
      'с. Каленик',
      'с. Калчевска',
      'с. Карлуково',
      'с. Катунец',
      'с. Киркова махала',
      'с. Кирчево',
      'с. Крушуна',
      'с. Къкрина',
      'с. Кърпачево',
      'с. Лакарево',
      'с. Лесидрен',
      'с. Лешко пресои',
      'с. Лешница',
      'с. Лисец',
      'с. Ломец',
      'с. Малиново',
      'с. Малка Желязна',
      'с. Малък извор',
      'с. Микре',
      'с. Миленча',
      'с. Орешак',
      'с. Орешене',
      'с. Орляне',
      'с. Патрешко',
      'с. Петревене',
      'с. Пещерна',
      'с. Прелом',
      'с. Пресяка',
      'с. Радоевското',
      'с. Радювене',
      'с. Райковска',
      'с. Рибарица',
      'с. Румянцево',
      'с. Селце',
      'с. Скандалото',
      'с. Скобелево',
      'с. Славщица',
      'с. Славяни',
      'с. Слатина',
      'с. Сливек',
      'с. Смочан',
      'с. Соколово',
      'с. Сопот',
      'с. Старо село',
      'с. Стефаново',
      'с. Стойновското',
      'с. Стругът',
      'с. Тепава',
      'с. Терзийско',
      'с. Тодоричене',
      'с. Торос',
      'с. Умаревци',
      'с. Хлевене',
      'с. Чавдарци',
      'с. Черни Вит',
      'с. Черни Осъм',
      'с. Чифлик',
      'с. Шипково',
      'с. Ъглен',
      'яз. Сопот',
    ];
    return gr;
  }
  getMontanagrad() {
    let gr = [
      'Жерави',
      'Жеравица',
      'Идеален център',
      'Изгрев',
      'Кошарник',
      'Мала Кутловица',
      'Махала Барселона',
      'Младост 1',
      'Младост 2',
      'Огоста',
      'Парта 1',
      'Парта 2',
      'Плиска',
      'Промишлена зона',
      'Пъстрина',
      'Широк център',
      'в.з. Запад',
      'в.з. Юг',
      'м-т Палековото',
    ];
    return gr;
  }
  getMontanaoblast() {
    let gr = [
      'гр. Берковица',
      'гр. Бойчиновци',
      'гр. Брусарци',
      'гр. Вълчедръм',
      'гр. Вършец',
      'гр. Лом',
      'гр. Чипровци',
      'с. Аспарухово',
      'с. Балювица',
      'с. Безденица',
      'с. Бели брег',
      'с. Бели брод',
      'с. Белимел',
      'с. Белотинци',
      'с. Бистрилица',
      'с. Благово',
      'с. Бокиловци',
      'с. Боровци',
      'с. Ботево',
      'с. Буковец',
      'с. Бъзовец',
      'с. Бързия',
      'с. Василовци',
      'с. Видлица',
      'с. Винище',
      'с. Вирове',
      'с. Владимирово',
      'с. Войници',
      'с. Габровница',
      'с. Гаврил Геново',
      'с. Гаганица',
      'с. Георги Дамяново',
      'с. Главановци',
      'с. Говежда',
      'с. Горна Бела речка',
      'с. Горна Вереница',
      'с. Горна Ковачица',
      'с. Горна Лука',
      'с. Горни Цибър',
      'с. Горно Озирово',
      'с. Горно Церовене',
      'с. Громшин',
      'с. Дива Слатина',
      'с. Добри дол',
      'с. Доктор Йосифово',
      'с. Долна Бела речка',
      'с. Долна Вереница',
      'с. Долна Рикса',
      'с. Долни Цибър',
      'с. Долно Белотинци',
      'с. Долно Линево',
      'с. Долно Озирово',
      'с. Долно Церовене',
      'с. Дондуково',
      'с. Драганица',
      'с. Дъбова махала',
      'с. Дълги дел',
      'с. Дългоделци',
      'с. Еловица',
      'с. Ерден',
      'с. Железна',
      'с. Замфир',
      'с. Замфирово',
      'с. Златия',
      'с. Игнатово',
      'с. Каменна Рикса',
      'с. Киселево',
      'с. Клисурица',
      'с. Княжева махала',
      'с. Кобиляк',
      'с. Ковачица',
      'с. Комарево',
      'с. Комощица',
      'с. Копиловци',
      'с. Костенци',
      'с. Котеновци',
      'с. Крапчене',
      'с. Крива бара',
      'с. Лесковец',
      'с. Лехчево',
      'с. Липен',
      'с. Мадан',
      'с. Мартиново',
      'с. Медковец',
      'с. Мездрея',
      'с. Меляне',
      'с. Митровци',
      'с. Мокреш',
      'с. Мърчево',
      'с. Николово',
      'с. Одоровци',
      'с. Орсоя',
      'с. Охрид',
      'с. Палилула',
      'с. Песочница',
      'с. Пишурка',
      'с. Помеждин',
      'с. Портитовци',
      'с. Превала',
      'с. Пърличево',
      'с. Равна',
      'с. Разград',
      'с. Расово',
      'с. Рашовица',
      'с. Септемврийци',
      'с. Славотин',
      'с. Слатина',
      'с. Сливата',
      'с. Сливовик',
      'с. Смирненски',
      'с. Смоляновци',
      'с. Спанчевци',
      'с. Сталийска махала',
      'с. Станево',
      'с. Стояново',
      'с. Стубел',
      'с. Студено буче',
      'с. Сумер',
      'с. Трайково',
      'с. Трифоново',
      'с. Цветкова бара',
      'с. Челюстница',
      'с. Чемиш',
      'с. Черешовица',
      'с. Черкаски',
      'с. Черни връх',
      'с. Ягодово',
      'с. Якимово',
    ];
    return gr;
  }
  getPazardjikgrad() {
    let gr = [
      'Запад',
      'Идеален център',
      'Изток',
      'Младост',
      'Моста на Лютата',
      'Окръжна болница',
      'Промишлена зона',
      'Промишлена зона - Изток',
      'Руски',
      'Ставропол',
      'Устрем',
      'Център',
      'Ябълките',
    ];
    return gr;
  }
  getPazardjikoblast() {
    let gr = [
      'гр. Батак',
      'гр. Белово',
      'гр. Брацигово',
      'гр. Велинград',
      'гр. Ветрен',
      'гр. Костандово',
      'гр. Панагюрище',
      'гр. Пещера',
      'гр. Ракитово',
      'гр. Септември',
      'гр. Стрелча',
      'гр. Сърница',
      'м-т Цигов чарк',
      'магистрала Тракия',
      'с. Абланица',
      'с. Аканджиево',
      'с. Алеко Константиново',
      'с. Алендарова',
      'с. Априлци',
      'с. Баня',
      'с. Биркова',
      'с. Блатница',
      'с. Бозьова',
      'с. Боримечково',
      'с. Бошуля',
      'с. Братаница',
      'с. Бутрева',
      'с. Бъта',
      'с. Бяга',
      'с. Варвара',
      'с. Величково',
      'с. Ветрен дол',
      'с. Виноградец',
      'с. Враненци',
      'с. Всемирци',
      'с. Габровица',
      'с. Гелеменово',
      'с. Главиница',
      'с. Говедаре',
      'с. Голямо Белово',
      'с. Горна Биркова',
      'с. Горна Дъбева',
      'с. Горно вършило',
      'с. Грашево',
      'с. Дебръщица',
      'с. Динката',
      'с. Добровница',
      'с. Долна Дъбева',
      'с. Долно вършило',
      'с. Дорково',
      'с. Драгиново',
      'с. Драгор',
      'с. Дъбравите',
      'с. Дюлево',
      'с. Елшица',
      'с. Жребичко',
      'с. Звъничево',
      'с. Злокучене',
      'с. Ивайло',
      'с. Исперихово',
      'с. Калугерово',
      'с. Кандови',
      'с. Капитан Димитриево',
      'с. Карабунар',
      'с. Ковачево',
      'с. Козарско',
      'с. Крали Марко',
      'с. Кръстава',
      'с. Левски',
      'с. Лесичово',
      'с. Лозен',
      'с. Ляхово',
      'с. Мало Конаре',
      'с. Медени поляни',
      'с. Мененкьово',
      'с. Мирянци',
      'с. Мокрище',
      'с. Момина клисура',
      'с. Нова махала',
      'с. Оборище',
      'с. Овчеполци',
      'с. Огняново',
      'с. Памидово',
      'с. Панагюрски колонии',
      'с. Паталеница',
      'с. Пашови',
      'с. Пищигово',
      'с. Побит камък',
      'с. Поибрене',
      'с. Попинци',
      'с. Равногор',
      'с. Радилово',
      'с. Розово',
      'с. Росен',
      'с. Рохлева',
      'с. Сарая',
      'с. Сбор',
      'с. Света Петка',
      'с. Свобода',
      'с. Семчиново',
      'с. Сестримо',
      'с. Симеоновец',
      'с. Синитово',
      'с. Славовица',
      'с. Смилец',
      'с. Сребриново',
      'с. Тополи дол',
      'с. Фотиново',
      'с. Хаджиево',
      'с. Цар Асен',
      'с. Цветино',
      'с. Церово',
      'с. Црънча',
      'с. Черногорово',
      'с. Чолакова',
      'с. Щърково',
      'с. Юнаците',
      'с. Юндола',
      'яз. Батак',
    ];
    return gr;
  }
  getPernikgrad() {
    let gr = [
      'Байкушева махала',
      'Бела вода',
      'Бели брег',
      'Варош',
      'Васил Левски',
      'Димова махала',
      'Драгановец',
      'Захаринова махала',
      'Изток',
      'Илчова махала',
      'Калкас',
      'Каменина',
      'Клепало',
      'Кошаревска махала',
      'Красно село',
      'Кристал',
      'Куциян',
      'Ладовица',
      'Могиличе',
      'Монте Карло',
      'Мошино',
      'Нов Драгановец',
      'Парлева махала',
      'Пригаров район',
      'Промишлена зона',
      'Проучване',
      'Ралица',
      'Рено',
      'Рудничар',
      'Селото',
      'Стара Тева',
      'Табана',
      'Твърди ливади',
      'Тева',
      'Христо Ботев',
      'Христо Смирненски',
      'Хумни дол',
      'Център',
      'Църква',
      'Чишкарева махала',
      'Шумачка махала',
    ];
    return gr;
  }
  getPernikoblast() {
    let gr = [
      'главен път Е79',
      'гр. Батановци',
      'гр. Брезник',
      'гр. Земен',
      'гр. Радомир',
      'гр. Трън',
      'магистрала Люлин',
      'с. Арзан',
      'с. Бабица',
      'с. Байкалско',
      'с. Банище',
      'с. Банкя',
      'с. Бегуновци',
      'с. Беланица',
      'с. Берайнци',
      'с. Беренде',
      'с. Билинци',
      'с. Блатешница',
      'с. Бобораци',
      'с. Богданов дол',
      'с. Богойна',
      'с. Борнарево',
      'с. Боснек',
      'с. Бохова',
      'с. Брезнишки извор',
      'с. Брусник',
      'с. Бусинци',
      'с. Бутроинци',
      'с. Велиново',
      'с. Велковци',
      'с. Видрар',
      'с. Видрица',
      'с. Вискяр',
      'с. Витановци',
      'с. Владимир',
      'с. Врабча',
      'с. Враня стена',
      'с. Вукан',
      'с. Габровдол',
      'с. Гигинци',
      'с. Главановци',
      'с. Глоговица',
      'с. Гоз',
      'с. Големо Бучино',
      'с. Горна Врабча',
      'с. Горна Глоговица',
      'с. Горна Диканя',
      'с. Горна Мелна',
      'с. Горна Секирна',
      'с. Горни Романци',
      'с. Горочевци',
      'с. Гълъбник',
      'с. Гърло',
      'с. Дебели лаг',
      'с. Джинчовци',
      'с. Дивля',
      'с. Дивотино',
      'с. Докьовци',
      'с. Долна Врабча',
      'с. Долна Диканя',
      'с. Долна Мелна',
      'с. Долна Секирна',
      'с. Долни Раковец',
      'с. Долни Романци',
      'с. Драгичево',
      'с. Драгомирово',
      'с. Дрен',
      'с. Друган',
      'с. Душинци',
      'с. Дълга лука',
      'с. Егълница',
      'с. Ездимирци',
      'с. Еловдол',
      'с. Еловица',
      'с. Ерул',
      'с. Жабляно',
      'с. Жедна',
      'с. Житуша',
      'с. Забел',
      'с. Завала',
      'с. Зелениград',
      'с. Зидарци',
      'с. Извор',
      'с. Калище',
      'с. Калотинци',
      'с. Касилаг',
      'с. Кладница',
      'с. Кленовик',
      'с. Ковачевци',
      'с. Кожинци',
      'с. Кондофрей',
      'с. Конска',
      'с. Копаница',
      'с. Косача',
      'с. Костуринци',
      'с. Кошарево',
      'с. Кошарите',
      'с. Кралев дол',
      'с. Красава',
      'с. Кривонос',
      'с. Къшле',
      'с. Лева река',
      'с. Лесковец',
      'с. Лешниковци',
      'с. Лобош',
      'с. Ломница',
      'с. Люлин',
      'с. Лялинци',
      'с. Мещица',
      'с. Милкьовци',
      'с. Милославци',
      'с. Мракетинци',
      'с. Мрамор',
      'с. Мурено',
      'с. Муртинци',
      'с. Насалевци',
      'с. Негованци',
      'с. Неделково',
      'с. Непразненци',
      'с. Николаево',
      'с. Ноевци',
      'с. Одраница',
      'с. Озърновци',
      'с. Падине',
      'с. Парамун',
      'с. Пенкьовци',
      'с. Пещера',
      'с. Планиница',
      'с. Поцърненци',
      'с. Прибой',
      'с. Проданча',
      'с. Радибош',
      'с. Радово',
      'с. Радуй',
      'с. Ракиловци',
      'с. Рани луг',
      'с. Расник',
      'с. Раянци',
      'с. Ребро',
      'с. Режанци',
      'с. Реяновци',
      'с. Рударци',
      'с. Ръжавец',
      'с. Садовик',
      'с. Светля',
      'с. Селищен дол',
      'с. Сирищник',
      'с. Слаковци',
      'с. Слатино',
      'с. Слишовци',
      'с. Смиров дол',
      'с. Сопица',
      'с. Стайчовци',
      'с. Станьовци',
      'с. Старо село',
      'с. Стефаново',
      'с. Стрезимировци',
      'с. Студен извор',
      'с. Студена',
      'с. Туроковци',
      'с. Углярци',
      'с. Филиповци',
      'с. Цегриловци',
      'с. Чепино',
      'с. Червена могила',
      'с. Черна гора',
      'с. Чуйпетлово',
      'с. Чуковец',
      'с. Шипковица',
      'с. Ярджиловци',
      'с. Ярловци',
      'с. Ярославци',
      'яз. Пчелина',
    ];
    return gr;
  }
  getPlevengrad() {
    let gr = [
      '9-ти квартал',
      'Дружба 1',
      'Дружба 2',
      'Дружба 3',
      'Дружба 4',
      'Идеален център',
      'Индустриална зона',
      'Индустриална зона - Запад',
      'Индустриална зона - Изток',
      'Кайлъка',
      'Мара Денчева',
      'Сторгозия',
      'Широк център',
      'м-т Кожухарска чешма',
      'м-т Мъртва долина',
      'м-т Орлето',
      'м-т Плочата',
      'м-т Стражата',
    ];
    return gr;
  }
  getPlevenoblast() {
    let gr = [
      'главен път Е83',
      'гр. Белене',
      'гр. Гулянци',
      'гр. Долна Митрополия',
      'гр. Долни Дъбник',
      'гр. Искър',
      'гр. Кнежа',
      'гр. Койнаре',
      'гр. Левски',
      'гр. Никопол',
      'гр. Пордим',
      'гр. Славяново',
      'гр. Тръстеник',
      'гр. Червен бряг',
      'с. Асеново',
      'с. Асеновци',
      'с. Аспарухово',
      'с. Байкал',
      'с. Бацова махала',
      'с. Беглеж',
      'с. Биволаре',
      'с. Божурица',
      'с. Божурлук',
      'с. Борислав',
      'с. Бохот',
      'с. Брегаре',
      'с. Бреница',
      'с. Брест',
      'с. Бресте',
      'с. Брестовец',
      'с. Бръшляница',
      'с. Буковлък',
      'с. Българене',
      'с. Бъркач',
      'с. Бяла вода',
      'с. Варана',
      'с. Въбел',
      'с. Вълчитрън',
      'с. Върбица',
      'с. Гиген',
      'с. Глава',
      'с. Горна Митрополия',
      'с. Горни Дъбник',
      'с. Горник',
      'с. Горталово',
      'с. Гостиля',
      'с. Градина',
      'с. Градище',
      'с. Гривица',
      'с. Дебово',
      'с. Девенци',
      'с. Деков',
      'с. Дисевица',
      'с. Долни Вит',
      'с. Долни Луковит',
      'с. Драгаш войвода',
      'с. Дъбован',
      'с. Евлогиево',
      'с. Еница',
      'с. Жернов',
      'с. Загражден',
      'с. Згалево',
      'с. Изгрев',
      'с. Искър',
      'с. Каменец',
      'с. Катерица',
      'с. Козар Белене',
      'с. Коиловци',
      'с. Комарево',
      'с. Крета',
      'с. Крушовене',
      'с. Крушовица',
      'с. Кулина вода',
      'с. Къртожабене',
      'с. Къшин',
      'с. Лазарово',
      'с. Ласкар',
      'с. Ленково',
      'с. Лепица',
      'с. Лозица',
      'с. Любеново',
      'с. Малчика',
      'с. Мечка',
      'с. Милковица',
      'с. Муселиево',
      'с. Николаево',
      'с. Новачене',
      'с. Обнова',
      'с. Одърне',
      'с. Опанец',
      'с. Ореховица',
      'с. Пелишат',
      'с. Петокладенци',
      'с. Петърница',
      'с. Писарово',
      'с. Победа',
      'с. Подем',
      'с. Радишево',
      'с. Радомирци',
      'с. Ракита',
      'с. Ралево',
      'с. Реселец',
      'с. Рибен',
      'с. Рупци',
      'с. Садовец',
      'с. Санадиново',
      'с. Славовица',
      'с. Сомовит',
      'с. Ставерци',
      'с. Староселци',
      'с. Стежерово',
      'с. Сухаче',
      'с. Татари',
      'с. Телиш',
      'с. Тодорово',
      'с. Тотлебен',
      'с. Трънчовица',
      'с. Тученица',
      'с. Търнене',
      'с. Черковица',
      'с. Чомаковци',
      'с. Шияково',
      'с. Ясен',
      'яз. Горни Дъбник',
    ];
    return gr;
  }
  getRazgradgrad() {
    let gr = [
      'Абитрус',
      'Бели Лом',
      'Варош',
      'Васил Левски',
      'Възраждане',
      'Житница',
      'Лудогорие',
      'Орел',
      'Освобождение',
      'Промишлена зона - Запад',
      'Промишлена зона - Изток',
      'Стефан Караджа',
      'Център',
    ];
    return gr;
  }
  getRazgradoblast() {
    let gr = [
      'гр. Завет',
      'гр. Исперих',
      'гр. Кубрат',
      'гр. Лозница',
      'гр. Цар Калоян',
      'с. Балкански',
      'с. Бели Лом',
      'с. Белинци',
      'с. Беловец',
      'с. Бисерци',
      'с. Благоево',
      'с. Богданци',
      'с. Богомилци',
      'с. Божурово',
      'с. Брестовене',
      'с. Бърдоква',
      'с. Вазово',
      'с. Веселец',
      'с. Веселина',
      'с. Владимировци',
      'с. Гецово',
      'с. Голям извор',
      'с. Голям Поровец',
      'с. Голяма вода',
      'с. Горичево',
      'с. Гороцвет',
      'с. Градина',
      'с. Делчево',
      'с. Драгомъж',
      'с. Дряновец',
      'с. Духовец',
      'с. Дянково',
      'с. Езерче',
      'с. Желязковец',
      'с. Задруга',
      'с. Звънарци',
      'с. Здравец',
      'с. Иван Шишманово',
      'с. Йонково',
      'с. Каменар',
      'с. Каменово',
      'с. Кара Михал',
      'с. Китанчево',
      'с. Киченица',
      'с. Конево',
      'с. Костанденец',
      'с. Кривица',
      'с. Крояч',
      'с. Къпиновци',
      'с. Липник',
      'с. Ловско',
      'с. Лудогорци',
      'с. Лъвино',
      'с. Малко Йонково',
      'с. Малък Поровец',
      'с. Манастирско',
      'с. Манастирци',
      'с. Медовене',
      'с. Мортагоново',
      'с. Мъдрево',
      'с. Недоклан',
      'с. Ножарово',
      'с. Осенец',
      'с. Острово',
      'с. Островче',
      'с. Печеница',
      'с. Побит камък',
      'с. Подайва',
      'с. Пороище',
      'с. Прелез',
      'с. Просторно',
      'с. Пчелина',
      'с. Равно',
      'с. Радинград',
      'с. Райнино',
      'с. Раковски',
      'с. Савин',
      'с. Самуил',
      'с. Свещари',
      'с. Севар',
      'с. Сейдол',
      'с. Сеслав',
      'с. Синя вода',
      'с. Средоселци',
      'с. Старо селище',
      'с. Стражец',
      'с. Студенец',
      'с. Сушево',
      'с. Тертер',
      'с. Тодорово',
      'с. Топчии',
      'с. Точилари',
      'с. Трапище',
      'с. Тръбач',
      'с. Ушинци',
      'с. Хума',
      'с. Хърсово',
      'с. Черковна',
      'с. Чудомир',
      'с. Юпер',
      'с. Яким Груево',
      'с. Ясеновец',
    ];
    return gr;
  }
  getRusegrad() {
    let gr = [
      'Алеи Възраждане',
      'Басарбово',
      'Веждата',
      'Възраждане',
      'Долапите',
      'Дружба 1',
      'Дружба 2',
      'Дружба 3',
      'Завод Найден Киров',
      'Захарна фабрика',
      'Здравец',
      'Здравец Изток',
      'Здравец Север 1',
      'Здравец Север 2',
      'КТМ',
      'Мальовица',
      'Мидия Енос',
      'Нова промишлена зона',
      'Новата махала',
      'Промишлена зона - Запад',
      'Промишлена зона - Изток',
      'Родина 1',
      'Родина 2',
      'Родина 3',
      'Родина 4',
      'Сарая',
      'Средна кула',
      'Товарна гара',
      'Тракцията',
      'Търговия на едро',
      'Хъшове',
      'Цветница',
      'Централен кооп. пазар',
      'Централен южен район',
      'Център',
      'Чародейка - Север',
      'Чародейка - Юг',
      'Широк център',
      'Ялта',
      'в.з. Д. Басарбовски',
      'в.з. Касева Чешма',
      'в.з. Кръста',
      'в.з. Кулата',
      'в.з. Левента',
      'в.з. Липака',
      'в.з. Русофили',
      'в.з. Средна кула',
      'в.з. Трите гълъба',
    ];
    return gr;
  }
  getRuseoblast() {
    let gr = [
      'главен път Е85',
      'гр. Борово',
      'гр. Бяла',
      'гр. Ветово',
      'гр. Глоджево',
      'гр. Две могили',
      'гр. Мартен',
      'гр. Сеново',
      'гр. Сливо поле',
      'с. Бабово',
      'с. Баниска',
      'с. Басарбово',
      'с. Батин',
      'с. Батишница',
      'с. Белцов',
      'с. Беляново',
      'с. Бистренци',
      'с. Божичен',
      'с. Борисово',
      'с. Босилковци',
      'с. Ботров',
      'с. Брестовица',
      'с. Бръшлен',
      'с. Бъзовец',
      'с. Бъзън',
      'с. Волово',
      'с. Голямо Враново',
      'с. Горно Абланово',
      'с. Джулюница',
      'с. Долна Студена',
      'с. Долно Абланово',
      'с. Дряновец',
      'с. Екзарх Йосиф',
      'с. Иваново',
      'с. Караманово',
      'с. Каран Върбовка',
      'с. Кацелово',
      'с. Копривец',
      'с. Кошарна',
      'с. Кошов',
      'с. Красен',
      'с. Кривина',
      'с. Кривня',
      'с. Лом Черковна',
      'с. Малко Враново',
      'с. Мечка',
      'с. Могилино',
      'с. Николово',
      'с. Нисово',
      'с. Новград',
      'с. Ново село',
      'с. Обретеник',
      'с. Острица',
      'с. Пейчиново',
      'с. Пепелина',
      'с. Пет кладенци',
      'с. Пиперково',
      'с. Пиргово',
      'с. Писанец',
      'с. Полско Косово',
      'с. Помен',
      'с. Просена',
      'с. Ряхово',
      'с. Сандрово',
      'с. Сваленик',
      'с. Семерджиево',
      'с. Смирненски',
      'с. Стамболово',
      'с. Стърмен',
      'с. Табачка',
      'с. Тетово',
      'с. Тръстеник',
      'с. Хотанца',
      'с. Ценово',
      'с. Церовец',
      'с. Червен',
      'с. Червена вода',
      'с. Черешово',
      'с. Чилнов',
      'с. Широково',
      'с. Щръклево',
      'с. Юделник',
      'с. Ястребово',
    ];
    return gr;
  }
  getSilistragrad() {
    let gr = [
      'Запад',
      'Изток',
      'Малчика',
      'Мджиди Табия',
      'Митница',
      'Промишлена зона - Запад',
      'Промишлена зона - Юг',
      'Римска гробница',
      'Север',
      'Център',
      'Юг',
      'в.з. Юг',
    ];
    return gr;
  }
  getSilistraoblast() {
    let gr = [
      'гр. Алфатар',
      'гр. Главиница',
      'гр. Дулово',
      'гр. Тутракан',
      'с. Айдемир',
      'с. Алеково',
      'с. Антимово',
      'с. Бабук',
      'с. Бащино',
      'с. Белица',
      'с. Бистра',
      'с. Богданци',
      'с. Богорово',
      'с. Боил',
      'с. Босна',
      'с. Брадвари',
      'с. Бреница',
      'с. Българка',
      'с. Варненци',
      'с. Васил Левски',
      'с. Ветрен',
      'с. Водно',
      'с. Войново',
      'с. Вокил',
      'с. Вълкан',
      'с. Върбино',
      'с. Гарван',
      'с. Главан',
      'с. Голеш',
      'с. Господиново',
      'с. Грънчарово',
      'с. Давидово',
      'с. Дичево',
      'с. Добротица',
      'с. Добруджанка',
      'с. Долец',
      'с. Долно Ряхово',
      'с. Дунавец',
      'с. Зарица',
      'с. Зарник',
      'с. Зафирово',
      'с. Звенимир',
      'с. Зебил',
      'с. Златоклас',
      'с. Ирник',
      'с. Искра',
      'с. Йорданово',
      'с. Казимир',
      'с. Кайнарджа',
      'с. Калипетрово',
      'с. Калугерене',
      'с. Каменци',
      'с. Козяк',
      'с. Коларово',
      'с. Колобър',
      'с. Косара',
      'с. Краново',
      'с. Кутловица',
      'с. Листец',
      'с. Любен',
      'с. Малък Преславец',
      'с. Межден',
      'с. Нова Попина',
      'с. Нова Черна',
      'с. Ножарево',
      'с. Овен',
      'с. Окорш',
      'с. Орешене',
      'с. Осен',
      'с. Падина',
      'с. Паисиево',
      'с. Подлес',
      'с. Пожарево',
      'с. Полковник Ламбриново',
      'с. Полковник Таслаково',
      'с. Полковник Чолаково',
      'с. Поляна',
      'с. Попина',
      'с. Попкралево',
      'с. Попрусаново',
      'с. Поройно',
      'с. Посев',
      'с. Правда',
      'с. Преславци',
      'с. Професор Иширково',
      'с. Прохлада',
      'с. Раздел',
      'с. Руйно',
      'с. Светослав',
      'с. Секулово',
      'с. Ситово',
      'с. Скала',
      'с. Слатина',
      'с. Смилец',
      'с. Сокол',
      'с. Срацимир',
      'с. Сребърна',
      'с. Средище',
      'с. Старо село',
      'с. Стефан Караджа',
      'с. Стрелково',
      'с. Суходол',
      'с. Сърпово',
      'с. Сяново',
      'с. Търновци',
      'с. Цар Асен',
      'с. Цар Самуил',
      'с. Царев дол',
      'с. Ценович',
      'с. Черковна',
      'с. Черник',
      'с. Черногор',
      'с. Чернолик',
      'с. Чуковец',
      'с. Шуменци',
      'с. Яребица',
      'с. Ястребна',
    ];
    return gr;
  }
  getSlivengrad() {
    let gr = [
      'Българка',
      'Даме Груев',
      'Дружба',
      'Клуцохор',
      'Колю Фичето',
      'Комлука',
      'Младост',
      'Надежда',
      'Ново село',
      'Промишлена зона',
      'Република',
      'Речица',
      'Руски',
      'Сини камъни',
      'Стоян Заимов',
      'Център',
      'в.з. Сливен',
    ];
    return gr;
  }
  getSlivenoblast() {
    let gr = [
      'главен път Е85',
      'гр. Кермен',
      'гр. Котел',
      'гр. Нова Загора',
      'гр. Твърдица',
      'гр. Шивачево',
      'магистрала Тракия',
      'с. Асеновец',
      'с. Баня',
      'с. Биково',
      'с. Бинкос',
      'с. Блатец',
      'с. Близнец',
      'с. Богданово',
      'с. Божевци',
      'с. Бозаджии',
      'с. Боринци',
      'с. Боров дол',
      'с. Братан',
      'с. Брястово',
      'с. Бял кладенец',
      'с. Бяла',
      'с. Бяла паланка',
      'с. Въглен',
      'с. Гавраилово',
      'с. Гергевец',
      'с. Глуфишево',
      'с. Глушник',
      'с. Голямо Чочовени',
      'с. Горно Александрово',
      'с. Градец',
      'с. Градско',
      'с. Графитово',
      'с. Драгоданово',
      'с. Дъбова',
      'с. Дядово',
      'с. Езеро',
      'с. Еленово',
      'с. Желю войвода',
      'с. Жеравна',
      'с. Жълт бряг',
      'с. Загорци',
      'с. Зайчари',
      'с. Злати войвода',
      'с. Изгрев',
      'с. Ичера',
      'с. Калояново',
      'с. Камен',
      'с. Каменово',
      'с. Караново',
      'с. Катунище',
      'с. Кипилово',
      'с. Ковачите',
      'с. Коньово',
      'с. Кортен',
      'с. Крива круша',
      'с. Крушаре',
      'с. Любенец',
      'с. Любенова махала',
      'с. Малко село',
      'с. Малко Чочовени',
      'с. Медвен',
      'с. Мечкарево',
      'с. Младово',
      'с. Млекарево',
      'с. Мокрен',
      'с. Научене',
      'с. Нейково',
      'с. Николаево',
      'с. Новачево',
      'с. Новоселец',
      'с. Омарчево',
      'с. Оризари',
      'с. Орлово',
      'с. Остра могила',
      'с. Панаретовци',
      'с. Пет могили',
      'с. Питово',
      'с. Полско Пъдарево',
      'с. Прохорово',
      'с. Пъдарево',
      'с. Радево',
      'с. Радецки',
      'с. Раково',
      'с. Самуилово',
      'с. Сборище',
      'с. Седларево',
      'с. Селиминово',
      'с. Скобелево',
      'с. Сокол',
      'с. Соколарци',
      'с. Сотиря',
      'с. Средорек',
      'с. Стара река',
      'с. Старо село',
      'с. Стоил войвода',
      'с. Стрелци',
      'с. Струпец',
      'с. Събрано',
      'с. Съдиево',
      'с. Съдийско поле',
      'с. Сърцево',
      'с. Тича',
      'с. Тополчане',
      'с. Топузево',
      'с. Трапоклово',
      'с. Филаретово',
      'с. Ценино',
      'с. Червенаково',
      'с. Чинтулово',
      'с. Чокоба',
      'с. Ябланово',
    ];
    return gr;
  }
  getSmolqngrad() {
    let gr = [
      'Горно Влахово',
      'Горно Райково',
      'Долно Райково',
      'Езерово',
      'Каптажа',
      'Невястата',
      'Нов център',
      'Петровица',
      'Пром. зона - Трандевица',
      'Промишлена зона - Север',
      'Средок',
      'Станевска махала',
      'Стар център',
      'Устово',
      'в.з. Смолян',
    ];
    return gr;
  }
  getSmolqnoblast() {
    let gr = [
      'гр. Девин',
      'гр. Доспат',
      'гр. Златоград',
      'гр. Мадан',
      'гр. Неделино',
      'гр. Рудозем',
      'гр. Чепеларе',
      'к.к. Пампорово',
      'с. Аламовци',
      'с. Алиговска',
      'с. Арда',
      'с. Арпаджик',
      'с. Баблон',
      'с. Баните',
      'с. Барутин',
      'с. Беден',
      'с. Белев дол',
      'с. Билянска',
      'с. Богутево',
      'с. Боево',
      'с. Борие',
      'с. Борика',
      'с. Бориково',
      'с. Борино',
      'с. Бориново',
      'с. Боровина',
      'с. Босилково',
      'с. Бостина',
      'с. Бреза',
      'с. Брезе',
      'с. Бръщен',
      'с. Буйново',
      'с. Буката',
      'с. Букаците',
      'с. Букова поляна',
      'с. Буково',
      'с. Бурево',
      'с. Бърчево',
      'с. Бяла река',
      'с. Вехтино',
      'с. Виево',
      'с. Високите',
      'с. Витина',
      'с. Вишнево',
      'с. Влахово',
      'с. Водата',
      'с. Водни пад',
      'с. Войкова лъка',
      'с. Вранинци',
      'с. Вълчан',
      'с. Вълчан дол',
      'с. Върба',
      'с. Върбина',
      'с. Върбово',
      'с. Въргов дол',
      'с. Върли дол',
      'с. Върлино',
      'с. Габрина',
      'с. Габрица',
      'с. Галище',
      'с. Гела',
      'с. Глогино',
      'с. Гоздевица',
      'с. Горна Арда',
      'с. Горово',
      'с. Градът',
      'с. Грамаде',
      'с. Гращица',
      'с. Грохотно',
      'с. Гудевица',
      'с. Гълъбово',
      'с. Гърнати',
      'с. Гьоврен',
      'с. Давидково',
      'с. Две тополи',
      'с. Дебеляново',
      'с. Диманово',
      'с. Димово',
      'с. Дирало',
      'с. Добрева череша',
      'с. Долен',
      'с. Долие',
      'с. Дрянка',
      'с. Дряновец',
      'с. Дунево',
      'с. Дуня',
      'с. Дъбова',
      'с. Еленка',
      'с. Еленска',
      'с. Елховец',
      'с. Ельово',
      'с. Ерма река',
      'с. Жребево',
      'с. Забърдо',
      'с. Загражден',
      'с. Заевите',
      'с. Змеица',
      'с. Змиево',
      'с. Зорница',
      'с. Иваново',
      'с. Изгрев',
      'с. Исьовци',
      'с. Касапско',
      'с. Катраница',
      'с. Кестен',
      'с. Киселчово',
      'с. Кожари',
      'с. Козарка',
      'с. Кокорово',
      'с. Кокорци',
      'с. Кориите',
      'с. Коритата',
      'с. Кочани',
      'с. Кошница',
      'с. Крайна (Мадан)',
      'с. Крайна (Неделино)',
      'с. Кремене',
      'с. Крушев дол',
      'с. Кръстатица',
      'с. Кукувица',
      'с. Кундево',
      'с. Купен',
      'с. Кутела',
      'с. Кушла',
      'с. Късак',
      'с. Левочево',
      'с. Леска',
      'с. Лещак',
      'с. Ливаде',
      'с. Лилеково',
      'с. Липец',
      'с. Ловци',
      'с. Лъка',
      'с. Любча',
      'с. Люлка',
      'с. Лясково',
      'с. Магарджица',
      'с. Малево',
      'с. Малка Арда',
      'с. Малко Крушево',
      'с. Миле',
      'с. Милково',
      'с. Митовска',
      'с. Михалково',
      'с. Могилица',
      'с. Момчиловци',
      'с. Мочуре',
      'с. Мугла',
      'с. Мъглища',
      'с. Мързян',
      'с. Надарци',
      'с. Оглед',
      'с. Оградна',
      'с. Орехово',
      'с. Орешец',
      'с. Оряховец',
      'с. Осиково',
      'с. Остри пазлак',
      'с. Острица',
      'с. Павелско',
      'с. Петково',
      'с. Петров дол',
      'с. Печинска',
      'с. Пещера',
      'с. Писаница',
      'с. Планинско',
      'с. Планинци',
      'с. Пловдивци',
      'с. Подвис',
      'с. Полковник Серафимово',
      'с. Поляна',
      'с. Попрелка',
      'с. Потока',
      'с. Пресока',
      'с. Проглед',
      'с. Равнил',
      'с. Равнината',
      'с. Равнища',
      'с. Равно нивище',
      'с. Река',
      'с. Речани',
      'с. Рибен дол',
      'с. Рибница',
      'с. Ровина',
      'с. Рустан',
      'с. Селище',
      'с. Селча',
      'с. Сивино',
      'с. Славейно',
      'с. Сливка',
      'с. Сливово',
      'с. Смилян',
      'с. Соколовци',
      'с. Солища',
      'с. Сопотот',
      'с. Средец',
      'с. Средногорци',
      'с. Средок',
      'с. Стайчин дол',
      'с. Старцево',
      'с. Стикъл',
      'с. Стойките',
      'с. Стоманево',
      'с. Стража',
      'с. Страшимир',
      'с. Студена',
      'с. Студенец',
      'с. Стърница',
      'с. Сърнино',
      'с. Тешел',
      'с. Тикале',
      'с. Траве',
      'с. Требище',
      'с. Триград',
      'с. Турян',
      'с. Тънка бара',
      'с. Тънкото',
      'с. Търън',
      'с. Уручевци',
      'с. Ухловица',
      'с. Фабрика',
      'с. Фатово',
      'с. Хасовица',
      'с. Хвойна',
      'с. Цацаровци',
      'с. Цирка',
      'с. Црънча',
      'с. Чавдар',
      'с. Чала',
      'с. Чамла',
      'с. Чепинци',
      'с. Чеплетен',
      'с. Черешките',
      'с. Черешово',
      'с. Черешовска река',
      'с. Чокманово',
      'с. Чурка',
      'с. Чуруково',
      'с. Чучур',
      'с. Шаренска',
      'с. Широка лъка',
      'с. Ягодина',
      'яз. Доспат',
    ];
    return gr;
  }
  getSofiagrad() {
    let gr = [
      '7-ми 11-ти километър',
      '',
      'Абдовица',
      '',
      'Банишора',
      'Белите брези',
      'Бенковски',
      'Борово',
      'Ботунец',
      'Ботунец 2',
      'Бояна',
      'Бъкстон',
      '',
      'Витоша',
      'Военна рампа',
      'Враждебна',
      'Връбница 1',
      'Връбница 2',
      '',
      'Гевгелийски',
      'Гео Милев',
      'Горна баня',
      'Горубляне',
      'Гоце Делчев',
      'Градина',
      '',
      'Дианабад',
      'Димитър Миленков',
      'Докторски паметник',
      'Драгалевци',
      'Дружба 1',
      'Дружба 2',
      'Дървеница',
      '',
      'Експериментален',
      '',
      'Западен парк',
      'Захарна фабрика',
      'Зона Б-18',
      'Зона Б-19',
      'Зона Б-5',
      'Зона Б-5-3',
      '',
      'Иван Вазов',
      'Изгрев',
      'Изток',
      'Илинден',
      'Илиянци',
      '',
      'Карпузица',
      'Княжево',
      'Красна поляна 1',
      'Красна поляна 2',
      'Красна поляна 3',
      'Красно село',
      'Кремиковци',
      'Кръстова вада',
      '',
      'Лагера',
      'Левски',
      'Левски В',
      'Левски Г',
      'Летище София',
      'Лозенец',
      'Люлин - център',
      'Люлин 1',
      'Люлин 10',
      'Люлин 2',
      'Люлин 3',
      'Люлин 4',
      'Люлин 5',
      'Люлин 6',
      'Люлин 7',
      'Люлин 8',
      'Люлин 9',
      '',
      'Малашевци',
      'Малинова долина',
      'Манастирски ливади',
      'Медицинска академия',
      'Младост 1',
      'Младост 1А',
      'Младост 2',
      'Младост 3',
      'Младост 4',
      'Модерно предградие',
      'Мусагеница',
      '',
      'Надежда 1',
      'Надежда 2',
      'Надежда 3',
      'Надежда 4',
      'НПЗ Изток',
      'НПЗ Искър',
      'НПЗ Средец',
      'НПЗ Хаджи Димитър',
      '',
      'Обеля',
      'Обеля 1',
      'Обеля 2',
      'Оборище',
      'Овча купел',
      'Овча купел 1',
      'Овча купел 2',
      'Орландовци',
      '',
      'Павлово',
      'ПЗ Илиянци',
      'ПЗ Хладилника',
      'Подуяне',
      'Полигона',
      '',
      'Разсадника',
      'Редута',
      'Република',
      'Република 2',
      '',
      'Света Троица',
      'Свобода',
      'Сердика',
      'Сеславци',
      'Симеоново',
      'Славия',
      'Слатина',
      'СПЗ Модерно предградие',
      'СПЗ Слатина',
      'Стрелбище',
      'Студентски град',
      'Сухата река',
      'Суходол',
      '',
      'Толстой',
      'Требич',
      'Триъгълника',
      '',
      'Факултета',
      'Филиповци',
      'Фондови жилища',
      '',
      'Хаджи Димитър',
      'Хиподрума',
      'Хладилника',
      'Христо Ботев',
      '',
      'Център',
      '',
      'Челопечене',
      '',
      'Яворов',
      '',
      'в.з.Американски колеж',
      'в.з.Беловодски път',
      'в.з.Бояна',
      'в.з.Бункера',
      'в.з.Врана - Герман',
      'в.з.Врана - Лозен',
      'в.з.Горна баня',
      'в.з.Килиите',
      'в.з.Киноцентъра',
      'в.з.Киноцентъра 3 част',
      'в.з.Люлин',
      'в.з.Малинова долина',
      'в.з.Малинова долина - Герена',
      'в.з.Симеоново - Драгалевци',
      'в.з.Черния кос',
      '',
      'гр. Банкя',
      'гр. Бухово',
      'гр. Нови Искър',
      '',
      'ж.гр.Зоопарк',
      'ж.гр.Южен парк',
      '',
      'м-т Барите',
      'м-т Батареята',
      'м-т Гърдова глава',
      'м-т Детски град',
      'м-т Камбаните',
      'м-т Киноцентъра',
      'м-т Мала кория',
      'м-т Подлозище',
      'м-т Щъркелово гнездо',
      'м-т Юбилейна гора',
      'м-т яз. Искър',
      'м-т Яладжа',
      '',
      'с. Балша',
      'с. Бистрица',
      'с. Бусманци',
      'с. Владая',
      'с. Войнеговци',
      'с. Волуяк',
      'с. Герман',
      'с. Горни Богров',
      'с. Доброславци',
      'с. Долни Богров',
      'с. Долни Пасарел',
      'с. Железница',
      'с. Желява',
      'с. Житен',
      'с. Иваняне',
      'с. Казичене',
      'с. Клисура',
      'с. Кокаляне',
      'с. Кривина',
      'с. Кубратово',
      'с. Кътина',
      'с. Лозен',
      'с. Локорско',
      'с. Мало Бучино',
      'с. Мировяне',
      'с. Мрамор',
      'с. Мърчаево',
      'с. Негован',
      'с. Панчарево',
      'с. Плана',
      'с. Подгумер',
      'с. Световрачене',
      'с. Чепинци',
      'с. Яна',
    ];
    return gr;
  }
  getSofiaoblast() {
    let gr = [
      'Гара Бов',
      'Гара Лакатник',
      'в.з.Ярема',
      'гр. Божурище',
      'гр. Ботевград',
      'гр. Годеч',
      'гр. Долна баня',
      'гр. Драгоман',
      'гр. Елин Пелин',
      'гр. Етрополе',
      'гр. Златица',
      'гр. Ихтиман',
      'гр. Копривщица',
      'гр. Костенец',
      'гр. Костинброд',
      'гр. Момин проход',
      'гр. Пирдоп',
      'гр. Правец',
      'гр. Самоков',
      'гр. Своге',
      'гр. Сливница',
      'к.к. Боровец',
      'м-т Мечката',
      'с. Алдомировци',
      'с. Алино',
      'с. Антон',
      'с. Априлово',
      'с. Байлово',
      'с. Бакьово',
      'с. Бальовци',
      'с. Банчовци',
      'с. Батулия',
      'с. Бахалин',
      'с. Безден',
      'с. Бели Искър',
      'с. Белица',
      'с. Белопопци',
      'с. Белчин',
      'с. Белчински бани',
      'с. Бенковски',
      'с. Беренде',
      'с. Беренде извор',
      'с. Бов',
      'с. Богданлия',
      'с. Богдановци',
      'с. Богьовци',
      'с. Боерица',
      'с. Боженица',
      'с. Бойковец',
      'с. Борика',
      'с. Бракьовци',
      'с. Братушково',
      'с. Брезе',
      'с. Брезовдол',
      'с. Брестака',
      'с. Брусен',
      'с. Бузяковци',
      'с. Буковец',
      'с. Букоровци',
      'с. Буново',
      'с. Бучин проход',
      'с. Бърдо',
      'с. Бърложница',
      'с. Бърля',
      'с. Вакарел',
      'с. Василовци',
      'с. Венковец',
      'с. Веринско',
      'с. Видраре',
      'с. Вишан',
      'с. Владиславци',
      'с. Владо Тричков',
      'с. Врачеш',
      'с. Връдловци',
      'с. Върбница',
      'с. Габер',
      'с. Габра',
      'с. Габровница',
      'с. Гайтанево',
      'с. Гара Елин Пелин',
      'с. Гинци',
      'с. Говедарци',
      'с. Голак',
      'с. Голема Раковица',
      'с. Големо Малово',
      'с. Голеш',
      'с. Голяновци',
      'с. Горна Василица',
      'с. Горна Малина',
      'с. Горни Окол',
      'с. Горно Камарци',
      'с. Горно село',
      'с. Горунака',
      'с. Градец',
      'с. Григорево',
      'с. Грълска падина',
      'с. Губеш',
      'с. Губислав',
      'с. Гургулят',
      'с. Гурково',
      'с. Гурмазово',
      'с. Гуцал',
      'с. Гълъбовци',
      'с. Делян',
      'с. Джамузовци',
      'с. Джурово',
      'с. Добравица',
      'с. Добърчин',
      'с. Доганово',
      'с. Долна Василица',
      'с. Долна Малина',
      'с. Долна Невля',
      'с. Долни Окол',
      'с. Долно Камарци',
      'с. Долно ново село',
      'с. Доспей',
      'с. Драговищица',
      'с. Драгоил',
      'с. Драготинци',
      'с. Драгушиново',
      'с. Дреатин',
      'с. Дреново',
      'с. Дружево',
      'с. Дръмша',
      'с. Душанци',
      'с. Еленов дол',
      'с. Елешница',
      'с. Елов дол',
      'с. Желен',
      'с. Живково',
      'с. Завидовци',
      'с. Заноге',
      'с. Заселе',
      'с. Зимевица',
      'с. Златуша',
      'с. Злокучене',
      'с. Извор',
      'с. Илинден',
      'с. Искрец',
      'с. Каленовци',
      'с. Калотина',
      'с. Калугерово',
      'с. Камбелевци',
      'с. Каменица',
      'с. Караполци',
      'с. Карлиево',
      'с. Клисура (Самоков)',
      'с. Ковачевци',
      'с. Комщица',
      'с. Костадинкино',
      'с. Костенец',
      'с. Краево',
      'с. Круша',
      'с. Крушовица',
      'с. Лакатник',
      'с. Левище',
      'с. Лесковдол',
      'с. Лесново',
      'с. Летница',
      'с. Липинци',
      'с. Липница',
      'с. Лисец',
      'с. Литаково',
      'с. Лопушня',
      'с. Лопян',
      'с. Луково',
      'с. Лъга',
      'с. Любница',
      'с. Маджаре',
      'с. Макоцево',
      'с. Мала Раковица',
      'с. Мала църква',
      'с. Малки Искър',
      'с. Мало Малово',
      'с. Манаселска река',
      'с. Манастирище',
      'с. Марица',
      'с. Мечковци',
      'с. Миланово',
      'с. Мирково',
      'с. Мирово',
      'с. Мургаш',
      'с. Мусачево',
      'с. Мухово',
      'с. Начево',
      'с. Негушево',
      'с. Неделище',
      'с. Нейкьовец',
      'с. Несла',
      'с. Новачене',
      'с. Нови хан',
      'с. Ново бърдо',
      'с. Ново село',
      'с. Огняново',
      'с. Огоя',
      'с. Опицвет',
      'с. Оплетня',
      'с. Оселна',
      'с. Осеновлаг',
      'с. Осиковица',
      'с. Осиковска Лакавица',
      'с. Осоица',
      'с. Очуша',
      'с. Пановци',
      'с. Пауново',
      'с. Петково',
      'с. Петрич',
      'с. Петърч',
      'с. Пищане',
      'с. Плъзище',
      'с. Повалиръж',
      'с. Подгорие',
      'с. Пожарево',
      'с. Полянци',
      'с. Понор',
      'с. Поповци',
      'с. Поповяне',
      'с. Потоп',
      'с. Правешка Лакавица',
      'с. Прекръсте',
      'с. Преспа',
      'с. Продановци',
      'с. Пролеша',
      'с. Пчелин',
      'с. Равна',
      'с. Равнище',
      'с. Равно поле',
      'с. Радотина',
      'с. Радуил',
      'с. Радуловци',
      'с. Разбоище',
      'с. Разлив',
      'с. Райово',
      'с. Ракита',
      'с. Рашково',
      'с. Раяновци',
      'с. Реброво',
      'с. Редина',
      'с. Рельово',
      'с. Рибарица',
      'с. Ропот',
      'с. Росоман',
      'с. Ръжана',
      'с. Саранци',
      'с. Свидня',
      'с. Своде',
      'с. Селянин',
      'с. Скравена',
      'с. Смолско',
      'с. Смолча',
      'с. Стамболово',
      'с. Станинци',
      'с. Столник',
      'с. Стъргел',
      'с. Табан',
      'с. Томпсън',
      'с. Трудовец',
      'с. Туден',
      'с. Хвърчил',
      'с. Хераково',
      'с. Храбърско',
      'с. Царичина',
      'с. Цацаровци',
      'с. Церецел',
      'с. Церово',
      'с. Цръклевци',
      'с. Църквище',
      'с. Чавдар',
      'с. Чеканец',
      'с. Чеканчево',
      'с. Челопеч',
      'с. Чепърлинци',
      'с. Черковище',
      'с. Черньово',
      'с. Чибаовци',
      'с. Чорул',
      'с. Чуковезер',
      'с. Чурек',
      'с. Шипочане',
      'с. Широки дол',
      'с. Шума',
      'с. Ябланица',
      'с. Ялботина',
      'с. Ямна',
      'с. Яребковица',
      'с. Ярлово',
    ];
    return gr;
  }
  getStarazagoragrad() {
    let gr = [
      'Аязмото',
      'Била',
      'Дъбрава',
      'Железник - изток',
      'Железник - център',
      'Загорка',
      'Зора',
      'Индустриална зона - запад',
      'Индустриална зона - изток',
      'Казански',
      'Казански - изток',
      'Кольо Ганчев',
      'Лозенец',
      'Македонски',
      'МБАЛ Иван Рилски',
      'Метро',
      'Никола Петков',
      'Операта',
      'Опълченски',
      'ОРБ',
      'Ремиза',
      'Самара 1',
      'Самара 2',
      'Самара 3',
      'Студентско градче',
      'Три чучура - север',
      'Три чучура - център',
      'Три чучура - юг',
      'Център',
      'м-т Беш Бунар',
      'м-т Голеш',
    ];
    return gr;
  }
  getStarazagoraoblast() {
    let gr = [
      'главен път Е85',
      'гр. Гурково',
      'гр. Гълъбово',
      'гр. Казанлък',
      'гр. Крън',
      'гр. Мъглиж',
      'гр. Николаево',
      'гр. Павел баня',
      'гр. Раднево',
      'гр. Чирпан',
      'гр. Шипка',
      'магистрала Тракия',
      'с. Александрово',
      'с. Априлово',
      'с. Арнаутито',
      'с. Асен',
      'с. Бащино',
      'с. Бели бряг',
      'с. Бенковски',
      'с. Богомилово',
      'с. Боздуганово',
      'с. Борилово',
      'с. Борово',
      'с. Борущица',
      'с. Братя Даскалови',
      'с. Братя Кунчеви',
      'с. Брестова',
      'с. Бузовград',
      'с. Бъдеще',
      'с. Българене',
      'с. Бънзарето',
      'с. Бял извор',
      'с. Бяло поле',
      'с. Васил Левски',
      'с. Великово',
      'с. Венец',
      'с. Верен',
      'с. Ветрен',
      'с. Виден',
      'с. Винарово',
      'с. Воденичарово',
      'с. Воловарово',
      'с. Габарево',
      'с. Гита',
      'с. Главан',
      'с. Гледачево',
      'с. Голям дол',
      'с. Голямо Дряново',
      'с. Горно Белево',
      'с. Горно Ботево',
      'с. Горно Изворово',
      'с. Горно ново село',
      'с. Горно Сахране',
      'с. Горно Черковище',
      'с. Гранит',
      'с. Даскал-Атанасово',
      'с. Дворище',
      'с. Димитриево',
      'с. Димовци',
      'с. Диня',
      'с. Долно изворово',
      'с. Долно ново село',
      'с. Долно Сахране',
      'с. Дунавци',
      'с. Дъбово',
      'с. Дълбоки',
      'с. Държава',
      'с. Държавен',
      'с. Едрево',
      'с. Еленино',
      'с. Елхово (Николаево)',
      'с. Елхово (Стара Загора)',
      'с. Енина',
      'с. Жерговец',
      'с. Жълтопоп',
      'с. Загоре',
      'с. Землен',
      'с. Зетьово',
      'с. Зимница',
      'с. Златирът',
      'с. Златна ливада',
      'с. Змейово',
      'с. Знаменосец',
      'с. Изворово',
      'с. Искрица',
      'с. Казанка',
      'с. Калитиново',
      'с. Калояновец',
      'с. Кирилово',
      'с. Княжевско',
      'с. Ковач',
      'с. Ковачево',
      'с. Козаревец',
      'с. Коларово',
      'с. Колена',
      'с. Колю Мариново',
      'с. Конаре',
      'с. Константиновец',
      'с. Копринка',
      'с. Кравино',
      'с. Кънчево',
      'с. Ловец',
      'с. Лозен',
      'с. Любеново',
      'с. Люляк',
      'с. Лява река',
      'с. Лясково',
      'с. Маджерито',
      'с. Малка Верея',
      'с. Малко Дряново',
      'с. Малко Кадиево',
      'с. Малко Тръново',
      'с. Малък дол',
      'с. Манолово',
      'с. Марково',
      'с. Маца',
      'с. Медникарово',
      'с. Медово',
      'с. Мирово',
      'с. Михайлово',
      'с. Могила',
      'с. Могилово',
      'с. Мусачево',
      'с. Мъдрец',
      'с. Найденово',
      'с. Нова махала',
      'с. Ново село',
      'с. Обручище',
      'с. Овощник',
      'с. Овчарци',
      'с. Опан',
      'с. Опълченец',
      'с. Оризово',
      'с. Оряховица',
      'с. Осетеново',
      'с. Осларка',
      'с. Остра могила',
      'с. Памукчии',
      'с. Паничерево',
      'с. Партизанин',
      'с. Петрово',
      'с. Плодовитово',
      'с. Плоска могила',
      'с. Подслон',
      'с. Полски Градец',
      'с. Помощник',
      'с. Православ',
      'с. Преславен',
      'с. Пряпорец',
      'с. Пчелиново',
      'с. Пшеничево',
      'с. Пъстрен',
      'с. Пъстрово',
      'с. Радунци',
      'с. Разделна',
      'с. Ракитница',
      'с. Рисиманово',
      'с. Розово',
      'с. Руманя',
      'с. Рупките',
      'с. Ръжена',
      'с. Самуилово',
      'с. Свобода',
      'с. Свободен',
      'с. Селце',
      'с. Скобелево',
      'с. Славянин',
      'с. Сладък кладенец',
      'с. Сливито',
      'с. Спасово',
      'с. Средец',
      'с. Средно градище',
      'с. Средногорово',
      'с. Старозагорски бани',
      'с. Столетово',
      'с. Стоян-Заимово',
      'с. Стрелец',
      'с. Сулица',
      'с. Съединение',
      'с. Сърневец',
      'с. Сърнево',
      'с. Тихомирово',
      'с. Тополяне',
      'с. Тракия',
      'с. Трояново',
      'с. Трънково',
      'с. Тулово',
      'с. Турия',
      'с. Тъжа',
      'с. Търничени',
      'с. Хаджидимитрово',
      'с. Хан Аспарухово',
      'с. Християново',
      'с. Хрищени',
      'с. Целина',
      'с. Ценово',
      'с. Черганово',
      'с. Черна гора',
      'с. Шаново',
      'с. Шейново',
      'с. Юлиево',
      'с. Яворовец',
      'с. Яворово',
      'с. Ягода',
      'с. Яздач',
      'с. Ясеново',
      'с. Ястребово',
      'яз. Жребчево',
      'яз. Копринка',
    ];
    return gr;
  }
  getTyrgovishtegrad() {
    let gr = [
      'Боровец',
      'Бряг',
      'Вароша',
      'Вилна зона',
      'Въбел',
      'Запад 1',
      'Запад 2',
      'Запад 3',
      'Изток 1',
      'Калково',
      'Малчо Малчев',
      'Промишлена зона',
      'Център',
    ];
    return gr;
  }
  getTyrgovishteoblast() {
    let gr = [
      'гр. Антоново',
      'гр. Омуртаг',
      'гр. Опака',
      'гр. Попово',
      'с. Алваново',
      'с. Александрово',
      'с. Априлово',
      'с. Баба Тонка',
      'с. Банковец',
      'с. Баячево',
      'с. Беломорци',
      'с. Берковски',
      'с. Бистра',
      'с. Богомолско',
      'с. Божурка',
      'с. Бракница',
      'с. Братово',
      'с. Буйново',
      'с. Букак',
      'с. Буховци',
      'с. Българаново',
      'с. Вардун',
      'с. Васил Левски',
      'с. Великденче',
      'с. Великовци',
      'с. Величка',
      'с. Вельово',
      'с. Веренци',
      'с. Веселец',
      'с. Висок',
      'с. Водица',
      'с. Врани кон',
      'с. Гагово',
      'с. Глашатай',
      'с. Глогинка',
      'с. Голямо градище',
      'с. Голямо Доляне',
      'с. Голямо ново',
      'с. Голямо Соколово',
      'с. Голямо църквище',
      'с. Горица',
      'с. Горна Златица',
      'с. Горна Кабда',
      'с. Горна Хубавка',
      'с. Горно Козарево',
      'с. Горно Новково',
      'с. Горско Абланово',
      'с. Горско село',
      'с. Градинка',
      'с. Греевци',
      'с. Гърчиново',
      'с. Давидово',
      'с. Девино',
      'с. Длъжка поляна',
      'с. Добротица',
      'с. Долец',
      'с. Долна Златица',
      'с. Долна Кабда',
      'с. Долна Хубавка',
      'с. Долно Козарево',
      'с. Долно Новково',
      'с. Драгановец',
      'с. Дралфа',
      'с. Дриново',
      'с. Дъбравица',
      'с. Дългач',
      'с. Еленово',
      'с. Железари',
      'с. Заветно',
      'с. Зараево',
      'с. Захари Стояново',
      'с. Звезда',
      'с. Звездица',
      'с. Здравец',
      'с. Зелена морава',
      'с. Змейно',
      'с. Иванча',
      'с. Изворово',
      'с. Илийно',
      'с. Калнище',
      'с. Камбурово',
      'с. Капище',
      'с. Кардам',
      'с. Кестеново',
      'с. Китино',
      'с. Ковачевец',
      'с. Козица',
      'с. Козма презвитер',
      'с. Конак',
      'с. Коноп',
      'с. Копрец',
      'с. Кошничари',
      'с. Крайполе',
      'с. Кралево',
      'с. Красноселци',
      'с. Крепча',
      'с. Крушолак',
      'с. Кръшно',
      'с. Къпинец',
      'с. Кьосевци',
      'с. Лиляк',
      'с. Ловец',
      'с. Ломци',
      'с. Любичево',
      'с. Люблен',
      'с. Макариополско',
      'с. Маково',
      'с. Малка Черковна',
      'с. Малоградец',
      'с. Манастирица',
      'с. Манушевци',
      'с. Марчино',
      'с. Медовина',
      'с. Мечово',
      'с. Миладиновци',
      'с. Милино',
      'с. Мировец',
      'с. Могилец',
      'с. Момино',
      'с. Моравица',
      'с. Моравка',
      'с. Надарево',
      'с. Обител',
      'с. Овчарово',
      'с. Орач',
      'с. Осен',
      'с. Осиково',
      'с. Острец',
      'с. Пайдушко',
      'с. Паламарца',
      'с. Панайот Хитово',
      'с. Паничино',
      'с. Певец',
      'с. Петрино',
      'с. Пиринец',
      'с. Плъстина',
      'с. Подгорица',
      'с. Помощица',
      'с. Поройно',
      'с. Посабина',
      'с. Преселец',
      'с. Пресиян',
      'с. Пресяк',
      'с. Присойна',
      'с. Пробуда',
      'с. Пролаз',
      'с. Птичево',
      'с. Пчелно',
      'с. Пъдарино',
      'с. Първан',
      'с. Равно село',
      'с. Разбойна',
      'с. Разделци',
      'с. Ралица',
      'с. Росина',
      'с. Росица',
      'с. Руец',
      'с. Рътлина',
      'с. Садина',
      'с. Светлен',
      'с. Свирчово',
      'с. Свободица',
      'с. Семерци',
      'с. Славяново',
      'с. Слънчовец',
      'с. Станец',
      'с. Стара речка',
      'с. Старчище',
      'с. Стеврек',
      'с. Стойново',
      'с. Стража',
      'с. Стройновци',
      'с. Съединение',
      'с. Таймище',
      'с. Твърдинци',
      'с. Тиховец',
      'с. Трескавец',
      'с. Тръстика',
      'с. Тъпчилещово',
      'с. Търновца',
      'с. Угледно',
      'с. Халваджийско',
      'с. Цар Асен',
      'с. Царевци',
      'с. Цветница',
      'с. Церовище',
      'с. Чеканци',
      'с. Черковна',
      'с. Черна вода',
      'с. Черни бряг',
      'с. Чернокапци',
      'с. Шишковица',
      'с. Язовец',
      'с. Яребично',
      'с. Ястребино',
    ];
    return gr;
  }
  getHaskovograd() {
    let gr = [
      'Бадема',
      'Болярово',
      'Воеводски',
      'Възраждане',
      'Дружба 1',
      'Каменни',
      'Куба',
      'Любен Каравелов',
      'Македонски',
      'Младежки хълм',
      'Овчарски',
      'Орфей',
      'Поройни',
      'Промишлена зона - Изток',
      'Промишлена зона - Север',
      'Промишлена зона - Юг',
      'Република',
      'Тракийски',
      'Училищни',
      'Хисаря',
      'Център',
      'Червена стена',
      'в.з. Хасково',
    ];
    return gr;
  }
  getHaskovooblast() {
    let gr = [
      'гр. Димитровград',
      'гр. Ивайловград',
      'гр. Любимец',
      'гр. Маджарово',
      'гр. Меричлери',
      'гр. Свиленград',
      'гр. Симеоновград',
      'гр. Тополовград',
      'гр. Харманли',
      'магистрала Марица',
      'с. Александрово',
      'с. Ангел войвода',
      'с. Балкан',
      'с. Бели дол',
      'с. Белица',
      'с. Белополци',
      'с. Белополяне',
      'с. Бисер',
      'с. Богомил',
      'с. Бодрово',
      'с. Болярски извор',
      'с. Бориславци',
      'с. Ботурче',
      'с. Боян Ботево',
      'с. Браница',
      'с. Брод',
      'с. Брусевци',
      'с. Брусино',
      'с. Брягово',
      'с. Бряст',
      'с. Брястово',
      'с. Бубино',
      'с. Българин',
      'с. Българска поляна',
      'с. Бял кладенец',
      'с. Бялградец',
      'с. Варник',
      'с. Васково',
      'с. Великан',
      'с. Ветрушка',
      'с. Винево',
      'с. Вис',
      'с. Владимирово',
      'с. Воден',
      'с. Воденци',
      'с. Войводенец',
      'с. Войводово',
      'с. Въгларово',
      'с. Вълче поле',
      'с. Върбица',
      'с. Върбово',
      'с. Габерово',
      'с. Гарваново',
      'с. Генералово',
      'с. Георги Добрево',
      'с. Гледка',
      'с. Глумово',
      'с. Гнездаре',
      'с. Големанци',
      'с. Голобрадово',
      'с. Голям извор',
      'с. Голяма долина',
      'с. Голямо Асеново',
      'с. Горни Главанак',
      'с. Горно Войводино',
      'с. Горно Луково',
      'с. Горно поле',
      'с. Горноселци',
      'с. Горски извор',
      'с. Горско',
      'с. Гугутка',
      'с. Гълъбец',
      'с. Дервишка могила',
      'с. Димитровче',
      'с. Динево',
      'с. Длъгнево',
      'с. Добрич',
      'с. Доброселец',
      'с. Долни Главанак',
      'с. Долно Белево',
      'с. Долно Ботево',
      'с. Долно Войводино',
      'с. Долно Големанци',
      'с. Долно Луково',
      'с. Долно поле',
      'с. Долно Съдиево',
      'с. Долно Черковище',
      'с. Долноселци',
      'с. Доситеево',
      'с. Драбишна',
      'с. Дрипчево',
      'с. Дряново',
      'с. Дъбовец',
      'с. Елена',
      'с. Ефрем',
      'с. Железари',
      'с. Железино',
      'с. Жълти бряг',
      'с. Здравец',
      'с. Зимовина',
      'с. Златополе',
      'с. Златоустово',
      'с. Зорница',
      'с. Иваново',
      'с. Изворово',
      'с. Йерусалимово',
      'с. Казак',
      'с. Калугерово',
      'с. Каменна река',
      'с. Камилски дол',
      'с. Капитан Андреево',
      'с. Капитан Петко войвода',
      'с. Караманци',
      'с. Карловско',
      'с. Каснаково',
      'с. Кладенец',
      'с. Клокотница',
      'с. Книжовник',
      'с. Княжево',
      'с. Кобилино',
      'с. Козлец',
      'с. Коларово',
      'с. Колец',
      'с. Кондово',
      'с. Конници',
      'с. Константиново',
      'с. Конуш',
      'с. Корен',
      'с. Костилково',
      'с. Костур',
      'с. Кралево',
      'с. Крепост',
      'с. Криво поле',
      'с. Крум',
      'с. Ламбух',
      'с. Левка',
      'с. Ленско',
      'с. Лешниково',
      'с. Лисово',
      'с. Лозен',
      'с. Любеново',
      'с. Лясковец',
      'с. Маджари',
      'с. Малево',
      'с. Малки Воден',
      'с. Малко Асеново',
      'с. Малко Брягово',
      'с. Малко градище',
      'с. Малко Попово',
      'с. Малък извор',
      'с. Манастир',
      'с. Мандра',
      'с. Мандрица',
      'с. Маслиново',
      'с. Маточина',
      'с. Меден бук',
      'с. Мезек',
      'с. Минерални бани',
      'с. Михалич',
      'с. Младиново',
      'с. Момино',
      'с. Момково',
      'с. Мрамор',
      'с. Мустрак',
      'с. Навъсен',
      'с. Надежден',
      'с. Николово',
      'с. Нова ливада',
      'с. Нова Надежда',
      'с. Овчарово',
      'с. Одринци',
      'с. Орешец',
      'с. Орешино',
      'с. Орешник',
      'с. Орлов дол',
      'с. Орлово',
      'с. Оряхово',
      'с. Остър камък',
      'с. Пашкул',
      'с. Пашово',
      'с. Планинец',
      'с. Планиново',
      'с. Плевун',
      'с. Подкрепа',
      'с. Покрован',
      'с. Поляново',
      'с. Поповец',
      'с. Попско',
      'с. Преславец',
      'с. Присадец',
      'с. Пчелари',
      'с. Пъстрогор',
      'с. Пъстроок',
      'с. Пътниково',
      'с. Пясъчево',
      'с. Рабово',
      'с. Равна гора',
      'с. Радиево',
      'с. Радовец',
      'с. Райкова могила',
      'с. Райново',
      'с. Рогозиново',
      'с. Родопи',
      'с. Розино',
      'с. Румелия',
      'с. Ръженово',
      'с. Сакарци',
      'с. Сборино',
      'с. Светлина (Димитровград)',
      'с. Светлина (Тополовград)',
      'с. Светослав',
      'с. Свирачи',
      'с. Свирково',
      'с. Селска поляна',
      'с. Сеноклас',
      'с. Сив кладенец',
      'с. Сива река',
      'с. Силен',
      'с. Синапово',
      'с. Сираково',
      'с. Скобелево',
      'с. Славеево',
      'с. Славяново',
      'с. Сладун',
      'с. Смирненци',
      'с. Соколенци',
      'с. Спахиево',
      'с. Срем',
      'с. Сталево',
      'с. Стамболийски',
      'с. Стамболово',
      'с. Стойково',
      'с. Странско',
      'с. Студена',
      'с. Сусам',
      'с. Сърница',
      'с. Татарево',
      'с. Текето',
      'с. Тополово',
      'с. Тракиец',
      'с. Троян',
      'с. Тънково',
      'с. Тянево',
      'с. Узунджово',
      'с. Устрем',
      'с. Филипово',
      'с. Хлябово',
      'с. Хухла',
      'с. Царева поляна',
      'с. Черепово',
      'с. Черна могила',
      'с. Черни рид',
      'с. Черничино',
      'с. Черногорово',
      'с. Чернодъб',
      'с. Чукарово',
      'с. Чучулига',
      'с. Широка поляна',
      'с. Шишманово',
      'с. Щит',
      'с. Ябълково',
      'яз. Ивайловград',
    ];
    return gr;
  }
  getShumengrad() {
    let gr = [
      '2-ри корпус',
      '5-ти полк',
      'Басейна',
      'Болницата',
      'Боян Българанов 1',
      'Боян Българанов 2',
      'Бялата пръст',
      'Военно училище',
      'Гривица',
      'Дивдядово',
      'Дивизионна болница',
      'Добруджански',
      'Еверест',
      'Куршун чешма',
      'Макак',
      'Математическа гимназия',
      'Мътница',
      'Пазара',
      'Пожарната',
      'Промишлена зона',
      'Томбул джамия',
      'Топхане',
      'Тракия',
      'Херсон',
      'Център',
      'в.з. Смесе',
      'м-т Под Манастира',
      'м-т Сакарка',
      'м-т Чашка',
    ];
    return gr;
  }
  getShumenoblast() {
    let gr = [
      'гр. Велики Преслав',
      'гр. Върбица',
      'гр. Каолиново',
      'гр. Каспичан',
      'гр. Нови пазар',
      'гр. Плиска',
      'гр. Смядово',
      'с. Александрово',
      'с. Байково',
      'с. Беджене',
      'с. Белокопитово',
      'с. Благово',
      'с. Близнаци',
      'с. Божурово',
      'с. Борци',
      'с. Боян',
      'с. Браничево',
      'с. Буйновица',
      'с. Бял бряг',
      'с. Бяла река',
      'с. Васил Друмев',
      'с. Векилски',
      'с. Велино',
      'с. Венец',
      'с. Веселиново',
      'с. Ветрище',
      'с. Вехтово',
      'с. Висока поляна',
      'с. Войвода',
      'с. Вълнари',
      'с. Върбак',
      'с. Върбяне',
      'с. Габрица',
      'с. Градище',
      'с. Гусла',
      'с. Денница',
      'с. Дибич',
      'с. Длъжко',
      'с. Добри Войниково',
      'с. Дойранци',
      'с. Долина',
      'с. Драгоево',
      'с. Дренци',
      'с. Друмево',
      'с. Единаковци',
      'с. Енево',
      'с. Желъд',
      'с. Живково',
      'с. Жилино',
      'с. Загориче',
      'с. Зайчино ореше',
      'с. Звегор',
      'с. Златар',
      'с. Златна нива',
      'с. Иваново',
      'с. Ивански',
      'с. Иглика',
      'с. Избул',
      'с. Изгрев',
      'с. Илия Блъсково',
      'с. Имренчево',
      'с. Калино',
      'с. Каменяк',
      'с. Капитан Петко',
      'с. Каравелово',
      'с. Каспичан',
      'с. Кладенец',
      'с. Климент',
      'с. Конево',
      'с. Коньовец',
      'с. Косово',
      'с. Костена река',
      'с. Кочово',
      'с. Крайгорци',
      'с. Красен дол',
      'с. Крива река',
      'с. Кълново',
      'с. Кьолмен',
      'с. Кюлевча',
      'с. Лиси връх',
      'с. Ловец',
      'с. Лозево',
      'с. Лятно',
      'с. Мадара',
      'с. Маломир',
      'с. Мараш',
      'с. Марково',
      'с. Менгишево',
      'с. Методиево',
      'с. Миланово',
      'с. Мировци',
      'с. Могила',
      'с. Мокреш',
      'с. Мостич',
      'с. Наум',
      'с. Никола Козлево',
      'с. Нова бяла река',
      'с. Ново Янково',
      'с. Новосел',
      'с. Овчарово',
      'с. Омарчево',
      'с. Осеновец',
      'с. Осмар',
      'с. Памукчии',
      'с. Панайот Волово',
      'с. Пет могили',
      'с. Писарево',
      'с. Правенци',
      'с. Преселка',
      'с. Пристое',
      'с. Радко Димитриево',
      'с. Развигорово',
      'с. Риш',
      'с. Ружица',
      'с. Салманово',
      'с. Сечище',
      'с. Сини вир',
      'с. Сливак',
      'с. Средковец',
      'с. Средня',
      'с. Стан',
      'с. Становец',
      'с. Станянци',
      'с. Стоян Михайловски',
      'с. Страхилица',
      'с. Струино',
      'с. Студеница',
      'с. Суха река',
      'с. Сушина',
      'с. Тервел',
      'с. Тимарево',
      'с. Тодор Икономово',
      'с. Трем',
      'с. Троица',
      'с. Тръница',
      'с. Тушовица',
      'с. Тъкач',
      'с. Хан Крум',
      'с. Хитрино',
      'с. Хърсово',
      'с. Цани Гинчево',
      'с. Царев брод',
      'с. Църквица',
      'с. Черенча',
      'с. Черна',
      'с. Черни връх',
      'с. Черноглавци',
      'с. Чернооково',
      'с. Янково',
      'с. Ясенково',
      'яз. Тича',
    ];
    return gr;
  }
  getQmbolgrad() {
    let gr = [
      'Аврен',
      'Боровец',
      'Васил Левски',
      'Военен полигон Боровец',
      'Военна база',
      'Възраждане',
      'Генерал Заимов',
      'Георги Бенковски',
      'Граф Игнатиев',
      'Д-р Дончев',
      'Диана',
      'Димитър Димов',
      'Златен рог',
      'Зорница',
      'Изток',
      'Каргон',
      'Клокотница',
      'Мототехника',
      'ОУ П. Р. Славейков',
      'Промишлена зона',
      'Райна Княгиня',
      'Ромска махала',
      'Хале',
      'Христо Ботев',
      'Център',
      'Яворов',
    ];
    return gr;
  }
  getQmboloblast() {
    let gr = [
      'гр. Болярово',
      'гр. Елхово',
      'гр. Стралджа',
      'магистрала Тракия',
      'с. Александрово',
      'с. Асеново',
      'с. Атолово',
      'с. Безмер',
      'с. Богорово',
      'с. Болярско',
      'с. Борисово',
      'с. Ботево',
      'с. Бояджик',
      'с. Бояново',
      'с. Веселиново',
      'с. Видинци',
      'с. Воден',
      'с. Воденичане',
      'с. Войника',
      'с. Вълча поляна',
      'с. Вълчи извор',
      'с. Генерал Инзово',
      'с. Генерал Тошево',
      'с. Голям Дервент',
      'с. Голям манастир',
      'с. Голямо Крушево',
      'с. Горска поляна',
      'с. Гранитово',
      'с. Гълъбинци',
      'с. Денница',
      'с. Джинот',
      'с. Добрич',
      'с. Дражево',
      'с. Драма',
      'с. Дряново',
      'с. Дъбово',
      'с. Жребино',
      'с. Завой',
      'с. Зимница',
      'с. Златари',
      'с. Златиница',
      'с. Иглика',
      'с. Изгрев',
      'с. Иречеково',
      'с. Кабиле',
      'с. Калчево',
      'с. Камен връх',
      'с. Каменец',
      'с. Каравелово',
      'с. Кирилово',
      'с. Козарево',
      'с. Коневец',
      'с. Крайново',
      'с. Крумово',
      'с. Кукорево',
      'с. Лалково',
      'с. Лесово',
      'с. Леярово',
      'с. Лозенец',
      'с. Люлин',
      'с. Маленово',
      'с. Малко Кирилово',
      'с. Малко Шарково',
      'с. Маломир',
      'с. Маломирово',
      'с. Малък манастир',
      'с. Мамарчево',
      'с. Меден кладенец',
      'с. Межда',
      'с. Мелница',
      'с. Миладиновци',
      'с. Могила',
      'с. Недялско',
      'с. Овчи кладенец',
      'с. Окоп',
      'с. Оман',
      'с. Палаузово',
      'с. Победа',
      'с. Поляна',
      'с. Попово',
      'с. Правдино',
      'с. Пчела',
      'с. Първенец',
      'с. Раздел',
      'с. Робово',
      'с. Роза',
      'с. Ружица',
      'с. Савино',
      'с. Саранско',
      'с. Симеоново',
      'с. Ситово',
      'с. Скалица',
      'с. Славейково',
      'с. Сламино',
      'с. Стара река',
      'с. Стефан Караджово',
      'с. Странджа',
      'с. Стройно',
      'с. Тамарино',
      'с. Тенево',
      'с. Трънково',
      'с. Търнава',
      'с. Хаджидимитрово',
      'с. Ханово',
      'с. Чарган',
      'с. Чарда',
      'с. Челник',
      'с. Чернозем',
      'с. Шарково',
    ];
    return gr;
  }
}
