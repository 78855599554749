import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ChoseContactSearchComponent } from 'src/app/dialogs/chose-contact-search/chose-contact-search.component';
import { RaionmodalComponent } from 'src/app/dialogs/raionmodal/raionmodal.component';
import { TipImotmodalComponent } from 'src/app/dialogs/tip-imotmodal/tip-imotmodal.component';
import { IsMobileService } from 'src/app/services/is-mobile.service';
import { RequestsService } from 'src/app/services/requests.service';
import { SearchcatService } from 'src/app/services/searchcat.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-zapazeniobqvi',
  templateUrl: './zapazeniobqvi.component.html',
  styleUrls: ['./zapazeniobqvi.component.css'],
})
export class ZapazeniobqviComponent implements OnInit {
  constructor(
    private req: RequestsService,
    private cat: SearchcatService,
    private dialog: MatDialog,
    private mobile: IsMobileService
  ) {}
  colapsefiltermobile = true;
  formobile = false;
  allactive = true;
  chasniactive = false;
  pleasewait = true;
  povtoreniq = 0;
  obqviall: any[] = [];
  obqvisort: any[] = [];
  obqvichasni: any[] = [];
  page!: number;
  scrollposition: any;
  typeimotcat!: any;
  gradoblastcat!: any;
  raioncat!: any;
  privateFilter: any;
  userimotsid: any[] = [];
  chosenRaioni: any;
  chosenTipImot: any;
  tyrsachkadanni = new FormGroup({
    typeimot: new FormControl(''),
    typeimot0: new FormControl(''),
    typeimot1: new FormControl(''),
    typeimot2: new FormControl(''),
    typeimot3: new FormControl(''),
    typeimot4: new FormControl(''),
    oblastgrad: new FormControl(''),
    raion: new FormControl(''),
    raion0: new FormControl(''),
    raion1: new FormControl(''),
    raion2: new FormControl(''),
    raion3: new FormControl(''),
    raion4: new FormControl(''),
    raion5: new FormControl(''),
    raion6: new FormControl(''),
    tipobqva: new FormControl(''),
    telefon: new FormControl(''),
    sortby: new FormControl(''),
    kv_ot: new FormControl(''),
    kv_do: new FormControl(''),
  });
  @HostListener('window:scroll', ['$event']) onScrollEvent($event: any) {
    //  sessionStorage.scrollPos = window.scrollY
    this.scrollposition = window.scrollY;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    event.target.innerWidth;

    if (event.target.innerWidth < 990) {
      this.formobile = true;
    } else {
      this.formobile = false;
    }
  }
  @ViewChild('Forscroll') heightofdiv!: ElementRef;

  ngOnInit(): void {
    if (this.mobile.isMobile()) {
      this.formobile = true;
    } else {
      this.formobile = false;
    }

    this.typeimotcat = this.cat.gettypeimot();
    this.gradoblastcat = this.cat.getgradoblast();

    if (sessionStorage.getItem('ZaptipObqva') == null) sessionStorage.setItem('ZaptipObqva', '');
    if (sessionStorage.getItem('Zaptypeimot') == null) sessionStorage.setItem('Zaptypeimot', '');

    if (sessionStorage.getItem('Zaptypeimot0') == null) sessionStorage.setItem('Zaptypeimot0', '');
    if (sessionStorage.getItem('Zaptypeimot1') == null) sessionStorage.setItem('Zaptypeimot1', '');
    if (sessionStorage.getItem('Zaptypeimot2') == null) sessionStorage.setItem('Zaptypeimot2', '');
    if (sessionStorage.getItem('Zaptypeimot3') == null) sessionStorage.setItem('Zaptypeimot3', '');
    if (sessionStorage.getItem('Zaptypeimot4') == null) sessionStorage.setItem('Zaptypeimot4', '');

    if (sessionStorage.getItem('Zapoblastgrad') == null) sessionStorage.setItem('Zapoblastgrad', '');
    if (sessionStorage.getItem('Zapraion') == null) sessionStorage.setItem('Zapraion', '');

    if (sessionStorage.getItem('Zapraion0') == null) sessionStorage.setItem('Zapraion0', '');
    if (sessionStorage.getItem('Zapraion1') == null) sessionStorage.setItem('Zapraion1', '');
    if (sessionStorage.getItem('Zapraion2') == null) sessionStorage.setItem('Zapraion2', '');
    if (sessionStorage.getItem('Zapraion3') == null) sessionStorage.setItem('Zapraion3', '');
    if (sessionStorage.getItem('Zapraion4') == null) sessionStorage.setItem('Zapraion4', '');
    if (sessionStorage.getItem('Zapraion5') == null) sessionStorage.setItem('Zapraion5', '');
    if (sessionStorage.getItem('Zapraion6') == null) sessionStorage.setItem('Zapraion6', '');

    if (sessionStorage.getItem('Zaptelefon') == null) sessionStorage.setItem('Zaptelefon', '');
    if (sessionStorage.getItem('Zapsortby') == null) sessionStorage.setItem('Zapsortby', '0');
    if (sessionStorage.getItem('Zapkv_ot') == null) sessionStorage.setItem('Zapkv_ot', '');
    if (sessionStorage.getItem('Zapkv_do') == null) sessionStorage.setItem('Zapkv_do', '');

    this.tyrsachkadanni.patchValue({
      typeimot: sessionStorage.getItem('Zaptypeimot'),
      typeimot0: sessionStorage.getItem('Zaptypeimot0'),
      typeimot1: sessionStorage.getItem('Zaptypeimot1'),
      typeimot2: sessionStorage.getItem('Zaptypeimot2'),
      typeimot3: sessionStorage.getItem('Zaptypeimot3'),
      typeimot4: sessionStorage.getItem('Zaptypeimot4'),
      oblastgrad: sessionStorage.getItem('Zapoblastgrad'),
      raion: sessionStorage.getItem('Zapraion'),
      raion0: sessionStorage.getItem('Zapraion0'),
      raion1: sessionStorage.getItem('Zapraion1'),
      raion2: sessionStorage.getItem('Zapraion2'),
      raion3: sessionStorage.getItem('Zapraion3'),
      raion4: sessionStorage.getItem('Zapraion4'),
      raion5: sessionStorage.getItem('Zapraion5'),
      raion6: sessionStorage.getItem('Zapraion6'),
      tipobqva: sessionStorage.getItem('ZaptipObqva'),
      telefon: sessionStorage.getItem('Zaptelefon'),
      sortby: sessionStorage.getItem('Zapsortby'),
      kv_ot: sessionStorage.getItem('Zapkv_ot'),
      kv_do: sessionStorage.getItem('Zapkv_do'),
    });

    this.getraions(this.tyrsachkadanni.get('oblastgrad')?.value);

    let pl = {
      userid: localStorage.getItem('userid'),
      oblastgrad: this.tyrsachkadanni.get('oblastgrad')?.value,
      raion: sessionStorage.getItem('Zapraion'),
      raion0: sessionStorage.getItem('Zapraion0'),
      raion1: sessionStorage.getItem('Zapraion1'),
      raion2: sessionStorage.getItem('Zapraion2'),
      raion3: sessionStorage.getItem('Zapraion3'),
      raion4: sessionStorage.getItem('Zapraion4'),
      raion5: sessionStorage.getItem('Zapraion5'),
      raion6: sessionStorage.getItem('Zapraion6'),
      tipObqva: this.tyrsachkadanni.get('tipobqva')?.value,
      typeimot: sessionStorage.getItem('Zaptypeimot'),
      typeimot0: sessionStorage.getItem('Zaptypeimot0'),
      typeimot1: sessionStorage.getItem('Zaptypeimot1'),
      typeimot2: sessionStorage.getItem('Zaptypeimot2'),
      typeimot3: sessionStorage.getItem('Zaptypeimot3'),
      typeimot4: sessionStorage.getItem('Zaptypeimot4'),
      telefon: this.tyrsachkadanni.get('telefon')?.value,
      sortby: this.tyrsachkadanni.get('sortby')?.value,
      kv_ot: this.tyrsachkadanni.get('kv_ot')?.value,
      kv_do: this.tyrsachkadanni.get('kv_do')?.value,
    };

    if (sessionStorage.getItem('ZapchosenTipImot') != null) {
      let m = sessionStorage.getItem('ZapchosenTipImot');
      let t = m?.split(',');
      this.chosenTipImot = t;
    }
    if (sessionStorage.getItem('ZapchosenRaioni') != null) {
      let m = sessionStorage.getItem('ZapchosenRaioni');
      let t = m?.split(',');
      this.chosenRaioni = t;
    }

    this.page = environment.lastusedpage;

    this.req.getfilteredimotsfav(pl).subscribe(
      (result: any) => {
        this.obqvisort = result;
        //console.log(result);

        this.pleasewait = false;
        // if (!this.formobile) {
        setTimeout(() => {
          window.scrollTo(0, environment.scrollpos);
        }, 100);
        // }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  savescrollpos() {
    environment.scrollpos = this.scrollposition;
  }

  openContactsfilter() {
    let t = 'test12334';
    let dialogOtg = this.dialog.open(ChoseContactSearchComponent, {
      data: { test: t },
    });
    dialogOtg.afterClosed().subscribe((result) => {
      this.clearfilter();
      if (result == undefined) return;
      if (result.ok != '1') return;
      let data = result.data;
      if (data.oblastGrad === null) {
        data.oblastGrad = '';
      }
      if (data.raion === null) {
        data.raion = '';
      }
      if (data.tipImot === null) {
        data.tipImot = '';
      }
      if (data.kvadraturaOt === null) {
        data.kvadraturaOt = '';
      }
      if (data.kvadraturaDo === null) {
        data.kvadraturaDo = '';
      }

      //console.log(data.raion);
      if (data.raion == null || data.raion == undefined) data.raion = '';
      let arrRaion = data.raion.split(',');
      // console.log(arrRaion.length);
      if (arrRaion.length > 1) {
        sessionStorage.setItem('Zapraion', arrRaion[0]);
        for (let i = 1; i < arrRaion.length; i++) {
          sessionStorage.setItem(`Zapraion${i - 1}`, arrRaion[i]);
        }
        this.chosenRaioni = arrRaion;
      } else {
        sessionStorage.setItem('Zapraion', data.raion);
        this.chosenRaioni = [arrRaion];
      }
      sessionStorage.setItem('ZapchosenRaioni', data.raion);

      if (data.tipImot == null || data.tipImot == undefined) data.tipImot = '';
      let arrTip = data.tipImot.split(',');
      // console.log(arrTip.length);
      if (arrTip.length > 1) {
        sessionStorage.setItem('Zaptypeimot', arrTip[0]);
        for (let j = 1; j < arrTip.length; j++) {
          sessionStorage.setItem(`Zaptypeimot${j - 1}`, arrTip[j]);
        }
        this.chosenTipImot = arrTip;
      } else {
        sessionStorage.setItem('Zaptypeimot', data.tipImot);
        this.chosenTipImot = [arrTip];
      }
      sessionStorage.setItem('ZapchosenTipImot', data.tipImot);
      sessionStorage.setItem('Zapoblastgrad', data.oblastGrad);
      sessionStorage.setItem('Zapkv_ot', data.kvadraturaOt);
      sessionStorage.setItem('Zapkv_do', data.kvadraturaDo);
      this.getraions(data.oblastGrad);
    });
  }

  openTipImotModal() {
    let dialogOtg = this.dialog.open(TipImotmodalComponent, { data: { type: this.typeimotcat, from: 'zap' } });
    dialogOtg.afterClosed().subscribe((result) => {
      //console.log(result);

      if (result != 'false') {
        this.chosenTipImot = result;
        sessionStorage.setItem('ZapchosenTipImot', result);
        this.filter(0);
      }
    });
  }

  openRaionmodal() {
    if (this.tyrsachkadanni.get('oblastgrad')?.value == '') {
      window.alert('Не сте избрали област или град');
    } else {
      let dialogOtg = this.dialog.open(RaionmodalComponent, { data: { type: this.raioncat, from: 'zap' } });
      dialogOtg.afterClosed().subscribe((result) => {
        // console.log(result);

        if (result != 'false') {
          this.chosenRaioni = result;
          sessionStorage.setItem('ZapchosenRaioni', result);
          this.filter(0);
        }
      });
    }
  }

  savefav(imotid: any) {
    let userid = localStorage.getItem('userid');

    this.req.adduserfav(userid, imotid).subscribe(
      (res: any) => {
        //console.log(res);
        this.obqvisort.forEach((ele) => {
          if (ele.id == imotid) {
            ele.favour = true;
          }
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }
  deletefav(imotid: any) {
    let userid = localStorage.getItem('userid');

    this.req.removeuserfav(userid, imotid).subscribe(
      (res: any) => {
        //console.log(res);
        this.obqvisort.forEach((ele) => {
          if (ele.id == imotid) {
            ele.favour = false;
          }
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  privatefilter() {
    this.req.getuserfilter(localStorage.getItem('userid')).subscribe(
      (res) => {
        this.privateFilter = res;

        sessionStorage.setItem('ZaptipObqva', this.privateFilter.filterTipObqva);
        sessionStorage.setItem('Zapoblastgrad', this.privateFilter.filterGrad);

        sessionStorage.setItem('Zapraion', this.privateFilter.filterRaion);
        sessionStorage.setItem('Zaptypeimot', this.privateFilter.filterTipImot);
        this.getraions(this.privateFilter.filterGrad);
        sessionStorage.setItem('ZapchosenRaioni', this.privateFilter.filterRaion);
        sessionStorage.setItem('ZapchosenTipImot', this.privateFilter.filterTipImot);
        this.chosenRaioni = [this.privateFilter.filterRaion];
        this.chosenTipImot = [this.privateFilter.filterTipImot];

        // this.tyrsachkadanni.patchValue({
        //   typeimot: this.privateFilter.filterTipImot,
        //   typeimot0: '',
        //   typeimot1: '',
        //   typeimot2: '',
        //   typeimot3: '',
        //   typeimot4: '',
        //   oblastgrad: this.privateFilter.filterGrad,
        //   raion: this.privateFilter.filterRaion,
        //   raion0: '',
        //   raion1: '',
        //   raion2: '',
        //   raion3: '',
        //   raion4: '',
        //   raion5: '',
        //   raion6: '',
        //   tipobqva: this.privateFilter.filterTipObqva,
        //   telefon: '',
        //   sortby: sessionStorage.getItem('Zapsortby'),
        //   kv_ot: '',
        //   kv_do: '',
        // });
        // this.getraions(this.tyrsachkadanni.get('oblastgrad')?.value);
        // this.filter(0);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  newSearch() {
    this.clearfilter();
    this.filter(0);
  }

  clearfilter() {
    this.tyrsachkadanni.patchValue({
      typeimot: '',
      typeimot0: '',
      typeimot1: '',
      typeimot2: '',
      typeimot3: '',
      typeimot4: '',
      oblastgrad: '',
      raion: '',
      raion0: '',
      raion1: '',
      raion2: '',
      raion3: '',
      raion4: '',
      raion5: '',
      raion6: '',
      tipobqva: '',
      telefon: '',
      sortby: '0',
      kv_ot: '',
      kv_do: '',
    });
    sessionStorage.setItem('Zapoblastgrad', '');
    sessionStorage.setItem('Zapraion', '');
    sessionStorage.setItem('Zapraion0', '');
    sessionStorage.setItem('Zapraion1', '');
    sessionStorage.setItem('Zapraion2', '');
    sessionStorage.setItem('Zapraion3', '');
    sessionStorage.setItem('Zapraion4', '');
    sessionStorage.setItem('Zapraion5', '');
    sessionStorage.setItem('Zapraion6', '');
    sessionStorage.setItem('Zaptypeimot', '');
    sessionStorage.setItem('Zaptypeimot0', '');
    sessionStorage.setItem('Zaptypeimot1', '');
    sessionStorage.setItem('Zaptypeimot2', '');
    sessionStorage.setItem('Zaptypeimot3', '');
    sessionStorage.setItem('Zaptypeimot4', '');
    sessionStorage.setItem('Zaptelefon', '');
    sessionStorage.setItem('ZaptipObqva', '');
    sessionStorage.setItem('Zapsortby', '0');
    this.chosenTipImot = [];
    this.chosenRaioni = [];
    sessionStorage.setItem('ZapchosenTipImot', '');
    sessionStorage.setItem('ZapchosenRaioni', '');
    sessionStorage.setItem('Zapkv_ot', '');
    sessionStorage.setItem('Zapkv_do', '');

    // this.filter(0);
  }
  changeFilter() {
    if (this.tyrsachkadanni.get('oblastgrad')?.value != '') {
      let val = this.tyrsachkadanni.get('oblastgrad')?.value;
      val = val.toLowerCase();
      let t = sessionStorage.getItem('Zapoblastgrad');
      let k = t?.toLocaleLowerCase();

      if (k != val) {
        this.tyrsachkadanni.patchValue({
          raion: '',
        });
        this.chosenRaioni = [];
        this.chosenTipImot = [];
        console.log('not going here');

        sessionStorage.setItem('ZapchosenRaioni', '');
        sessionStorage.setItem('ZapchosenTipImot', '');
        sessionStorage.setItem('Zapraion', '');
        sessionStorage.setItem('Zapraion0', '');
        sessionStorage.setItem('Zapraion1', '');
        sessionStorage.setItem('Zapraion2', '');
        sessionStorage.setItem('Zapraion3', '');
        sessionStorage.setItem('Zapraion4', '');
        sessionStorage.setItem('Zapraion5', '');
        sessionStorage.setItem('Zapraion6', '');
        sessionStorage.setItem('Zaptypeimot', '');
        sessionStorage.setItem('Zaptypeimot0', '');
        sessionStorage.setItem('Zaptypeimot1', '');
        sessionStorage.setItem('Zaptypeimot2', '');
        sessionStorage.setItem('Zaptypeimot3', '');
        sessionStorage.setItem('Zaptypeimot4', '');
        sessionStorage.setItem('Zaptelefon', '');
        sessionStorage.setItem('Zapkv_ot', '');
        sessionStorage.setItem('Zapkv_do', '');
        sessionStorage.setItem('Zapsortby', '0');
        this.tyrsachkadanni.patchValue({
          kv_ot: '',
          kv_do: '',
          telefon: '',
        });
      }
      sessionStorage.setItem('Zapoblastgrad', this.tyrsachkadanni.get('oblastgrad')?.value);
    } else {
      sessionStorage.setItem('Zapoblastgrad', '');
    }

    if (this.tyrsachkadanni.get('tipobqva')?.value != '') {
      sessionStorage.setItem('ZaptipObqva', this.tyrsachkadanni.get('tipobqva')?.value);
    } else {
      sessionStorage.setItem('ZaptipObqva', '');
    }

    if (this.tyrsachkadanni.get('telefon')?.value != '') {
      sessionStorage.setItem('Zaptelefon', this.tyrsachkadanni.get('telefon')?.value);
    } else {
      sessionStorage.setItem('Zaptelefon', '');
    }

    if (this.tyrsachkadanni.get('sortby')?.value != '') {
      sessionStorage.setItem('Zapsortby', this.tyrsachkadanni.get('sortby')?.value);
    } else {
      sessionStorage.setItem('Zapsortby', '');
    }

    if (this.tyrsachkadanni.get('kv_ot')?.value != '') {
      sessionStorage.setItem('Zapkv_ot', this.tyrsachkadanni.get('kv_ot')?.value);
    }
    if (this.tyrsachkadanni.get('kv_ot')?.value == null) {
      sessionStorage.setItem('Zapkv_ot', '');
    }

    if (this.tyrsachkadanni.get('kv_do')?.value != '') {
      sessionStorage.setItem('Zapkv_do', this.tyrsachkadanni.get('kv_do')?.value);
    }
    if (this.tyrsachkadanni.get('kv_do')?.value == null) {
      sessionStorage.setItem('Zapkv_do', '');
    }
    this.getraions(sessionStorage.getItem('Zapoblastgrad'));
  }
  //do tuk si gi pregledal i napravil samo da se dobavi openContactModal
  filter(getraion: Number) {
    let pl = {
      userid: localStorage.getItem('userid'),
      //oblastgrad: this.tyrsachkadanni.get('oblastgrad')?.value,
      oblastgrad: sessionStorage.getItem('Zapoblastgrad'),
      raion: sessionStorage.getItem('Zapraion'),
      raion0: sessionStorage.getItem('Zapraion0'),
      raion1: sessionStorage.getItem('Zapraion1'),
      raion2: sessionStorage.getItem('Zapraion2'),
      raion3: sessionStorage.getItem('Zapraion3'),
      raion4: sessionStorage.getItem('Zapraion4'),
      raion5: sessionStorage.getItem('Zapraion5'),
      raion6: sessionStorage.getItem('Zapraion6'),
      //tipObqva: this.tyrsachkadanni.get('tipobqva')?.value,
      tipObqva: sessionStorage.getItem('ZaptipObqva'),
      typeimot: sessionStorage.getItem('Zaptypeimot'),
      typeimot0: sessionStorage.getItem('Zaptypeimot0'),
      typeimot1: sessionStorage.getItem('Zaptypeimot1'),
      typeimot2: sessionStorage.getItem('Zaptypeimot2'),
      typeimot3: sessionStorage.getItem('Zaptypeimot3'),
      typeimot4: sessionStorage.getItem('Zaptypeimot4'),
      //telefon: this.tyrsachkadanni.get('telefon')?.value,
      telefon: sessionStorage.getItem('Zaptelefon'),
      //sortby: this.tyrsachkadanni.get('sortby')?.value,
      sortby: sessionStorage.getItem('Zapsortby'),
      // kv_ot: this.tyrsachkadanni.get('kv_ot')?.value,
      kv_ot: sessionStorage.getItem('Zapkv_ot'),
      //kv_do: this.tyrsachkadanni.get('kv_do')?.value,
      kv_do: sessionStorage.getItem('Zapkv_do'),
    };
    this.tyrsachkadanni.patchValue({
      typeimot: sessionStorage.getItem('Zaptypeimot'),
      typeimot0: sessionStorage.getItem('Zaptypeimot0'),
      typeimot1: sessionStorage.getItem('Zaptypeimot1'),
      typeimot2: sessionStorage.getItem('Zaptypeimot2'),
      typeimot3: sessionStorage.getItem('Zaptypeimot3'),
      typeimot4: sessionStorage.getItem('Zaptypeimot4'),
      oblastgrad: sessionStorage.getItem('Zapoblastgrad'),
      raion: sessionStorage.getItem('Zapraion'),
      raion0: sessionStorage.getItem('Zapraion0'),
      raion1: sessionStorage.getItem('Zapraion1'),
      raion2: sessionStorage.getItem('Zapraion2'),
      raion3: sessionStorage.getItem('Zapraion3'),
      raion4: sessionStorage.getItem('Zapraion4'),
      raion5: sessionStorage.getItem('Zapraion5'),
      raion6: sessionStorage.getItem('Zapraion6'),
      tipobqva: sessionStorage.getItem('ZaptipObqva'),
      telefon: sessionStorage.getItem('Zaptelefon'),
      sortby: sessionStorage.getItem('Zapsortby'),
      kv_ot: sessionStorage.getItem('Zapkv_ot'),
      kv_do: sessionStorage.getItem('Zapkv_do'),
    });

    this.req.getfilteredimotsfav(pl).subscribe(
      (result: any) => {
        this.obqvisort = result;
        this.colapsefiltermobile = true;
        // setTimeout(() => {
        //   this.heightofdiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        // }, 100);
        if (getraion == 0) {
          this.page = 1;
          environment.lastusedpage = 1;
        }

        this.pleasewait = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getraions(val: any) {
    val = val.toLowerCase();

    switch (val) {
      case 'област пловдив':
        {
          this.raioncat = this.cat.getPlovdivoblast();
        }
        break;
      case 'град пловдив':
        {
          this.raioncat = this.cat.getPlovdivgrad();
        }
        break;
      case 'град благоевград':
        {
          this.raioncat = this.cat.getBlagoevgradgrad();
        }
        break;
      case 'област благоевград':
        {
          this.raioncat = this.cat.getBlagoevgradoblast();
        }
        break;
      case 'град бургас':
        {
          this.raioncat = this.cat.getBurgasgrad();
        }
        break;
      case 'област бургас':
        {
          this.raioncat = this.cat.getBurgasoblast();
        }
        break;
      case 'град варна':
        {
          this.raioncat = this.cat.getVarnagrad();
        }
        break;
      case 'област варна':
        {
          this.raioncat = this.cat.getVarnaoblast();
        }
        break;
      case 'град велико търново':
        {
          this.raioncat = this.cat.getVelikoTyrnovograd();
        }
        break;
      case 'област велико търново':
        {
          this.raioncat = this.cat.getVelikotyrnovooblast();
        }
        break;
      case 'град видин':
        {
          this.raioncat = this.cat.getVidingrad();
        }
        break;
      case 'област видин':
        {
          this.raioncat = this.cat.getVidinoblast();
        }
        break;
      case 'град враца':
        {
          this.raioncat = this.cat.getVracagrad();
        }
        break;
      case 'област враца':
        {
          this.raioncat = this.cat.getVracaoblast();
        }
        break;
      case 'град габрово':
        {
          this.raioncat = this.cat.getGabrovograd();
        }
        break;
      case 'област габрово':
        {
          this.raioncat = this.cat.getGabrovooblast();
        }
        break;
      case 'град добрич':
        {
          this.raioncat = this.cat.getDobrichgrad();
        }
        break;
      case 'област добрич':
        {
          this.raioncat = this.cat.getDobrichoblast();
        }
        break;
      case 'град кърджали':
        {
          this.raioncat = this.cat.getKurdjaligrad();
        }
        break;
      case 'област кърджали':
        {
          this.raioncat = this.cat.getKurdjalioblast();
        }
        break;
      case 'град кюстендил':
        {
          this.raioncat = this.cat.getKustendilgrad();
        }
        break;
      case 'област кюстендил':
        {
          this.raioncat = this.cat.getKustendiloblast();
        }
        break;
      case 'град ловеч':
        {
          this.raioncat = this.cat.getLovechgrad();
        }
        break;
      case 'област ловеч':
        {
          this.raioncat = this.cat.getLovechoblast();
        }
        break;
      case 'град монтана':
        {
          this.raioncat = this.cat.getMontanagrad();
        }
        break;
      case 'област монтана':
        {
          this.raioncat = this.cat.getMontanaoblast();
        }
        break;
      case 'град пазарджик':
        {
          this.raioncat = this.cat.getPazardjikgrad();
        }
        break;
      case 'област пазарджик':
        {
          this.raioncat = this.cat.getPazardjikoblast();
        }
        break;
      case 'град перник':
        {
          this.raioncat = this.cat.getPernikgrad();
        }
        break;
      case 'област перник':
        {
          this.raioncat = this.cat.getPernikoblast();
        }
        break;
      case 'град плевен':
        {
          this.raioncat = this.cat.getPlevengrad();
        }
        break;
      case 'област плевен':
        {
          this.raioncat = this.cat.getPlevenoblast();
        }
        break;
      case 'град разград':
        {
          this.raioncat = this.cat.getRazgradgrad();
        }
        break;
      case 'област разград':
        {
          this.raioncat = this.cat.getRazgradoblast();
        }
        break;
      case 'град русе':
        {
          this.raioncat = this.cat.getRusegrad();
        }
        break;
      case 'област русе':
        {
          this.raioncat = this.cat.getRuseoblast();
        }
        break;
      case 'град силистра':
        {
          this.raioncat = this.cat.getSilistragrad();
        }
        break;
      case 'област силистра':
        {
          this.raioncat = this.cat.getSilistraoblast();
        }
        break;
      case 'град сливен':
        {
          this.raioncat = this.cat.getSlivengrad();
        }
        break;
      case 'област сливен':
        {
          this.raioncat = this.cat.getSlivenoblast();
        }
        break;
      case 'град смолян':
        {
          this.raioncat = this.cat.getSmolqngrad();
        }
        break;
      case 'област смолян':
        {
          this.raioncat = this.cat.getSmolqnoblast();
        }
        break;
      case 'град софия':
        {
          this.raioncat = this.cat.getSofiagrad();
        }
        break;
      case 'област софия':
        {
          this.raioncat = this.cat.getSofiaoblast();
        }
        break;
      case 'град стара загора':
        {
          this.raioncat = this.cat.getStarazagoragrad();
        }
        break;
      case 'област стара загора':
        {
          this.raioncat = this.cat.getStarazagoraoblast();
        }
        break;
      case 'град търговище':
        {
          this.raioncat = this.cat.getTyrgovishtegrad();
        }
        break;
      case 'област търговище':
        {
          this.raioncat = this.cat.getTyrgovishteoblast();
        }
        break;
      case 'град хасково':
        {
          this.raioncat = this.cat.getHaskovograd();
        }
        break;
      case 'област хасково':
        {
          this.raioncat = this.cat.getHaskovooblast();
        }
        break;
      case 'град шумен':
        {
          this.raioncat = this.cat.getShumengrad();
        }
        break;
      case 'област шумен':
        {
          this.raioncat = this.cat.getShumenoblast();
        }
        break;
      case 'град ямбол':
        {
          this.raioncat = this.cat.getQmbolgrad();
        }
        break;
      case 'област ямбол':
        {
          this.raioncat = this.cat.getQmboloblast();
        }
        break;
    }

    this.filter(1);
  }

  searchinobqvi(val: any, where: any) {
    val = val.toLowerCase();
    if (val == null || val == '' || val == undefined) {
      this.obqvisort = this.obqviall.sort((a, b) => {
        var datePartsa = a.data.split('.');
        var datePartsb = b.data.split('.');
        var clocka = a.time.split(':');
        var clockb = b.time.split(':');
        var dateObjecta = new Date(+datePartsa[2], datePartsa[1] - 1, +datePartsa[0], +clocka[0], +clocka[1]);
        var dateObjectb = new Date(+datePartsb[2], datePartsb[1] - 1, +datePartsb[0], +clockb[0], +clockb[1]);

        return <any>new Date(dateObjectb) - <any>new Date(dateObjecta);
      });
      this.tyrsachkadanni.patchValue({
        typeimot: '',
        oblastgrad: '',
        raion: '',
        tipobqva: '',
      });
    } else {
      if (where == 'tipObqva') {
        this.obqvisort = this.obqvisort.filter(function (obqva) {
          return obqva.tipObqva.toLowerCase().includes(val);
        });
      }
      if (where == 'title') {
        this.obqvisort = this.obqvisort.filter(function (obqva) {
          return obqva.title.toLowerCase().includes(val);
        });
      }
      if (where == 'raion') {
        this.obqvisort = this.obqvisort.filter(function (obqva) {
          return obqva.raion.toLowerCase().includes(val);
        });
      }
      if (where == 'town') {
        if (val == 'област пловдив') {
          this.raioncat = this.cat.getPlovdivoblast();
        }
        if (val == 'град пловдив') {
          this.raioncat = this.cat.getPlovdivgrad();
        }

        this.obqvisort = this.obqviall.filter(function (obqva) {
          return obqva.town.toLowerCase().includes(val);
        });
      }
      if (where == 'typeimot') {
        this.obqvisort = this.obqviall.filter(function (typeimot) {
          return typeimot.title.toLowerCase().includes(val);
        });
      }
    }
  }

  onPageChange(page: number) {
    this.page = page;
    environment.lastusedpage = page;

    window.scrollTo(0, 0);
  }
}
