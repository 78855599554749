<div class="container-lg" style="text-align: -webkit-center;">
    <app-menu> </app-menu>

    <div class="col-6" >
        <div class="row centercont">
            
            <h2>Вход</h2>
        
        </div>
    <form [formGroup]="loginform">
        <div class="row centercont">
            
                
                    <label for="name" ><h3>Име или имейл:</h3></label>
                    <input id="name" name="name"  type="text" class="form-control" formControlName="name">


                
            
        </div>
        <div class="row centercont">
            
            
                <label for="pass" ><h3>Парола:</h3></label>
                <input id="pass" name="pass"  type="password"  class="form-control" formControlName="pass"> 


            
        
        </div>
        <div class="row centercont" *ngIf="err">
            {{error}}
        </div>
        <div class="row centercont m-1">
            
                <button class="btn btn-primary"  (click)="login()" >Вписване</button>
            
        </div>
        
    </form>
    </div>
    <div class="bg-dark" style="color: white;">
         
    </div>
</div>

