<h1 mat-dialog-title>Добави контакт</h1>
<div mat-dialog-content>
  <div class="container-fluid">
    <div class="row mt-1">
      <div class="col m-1">
        <form [formGroup]="addformcontact">
          <div class="row">
            <label>Име:</label>
            <input class="form-control" type="text" formControlName="name" />
          </div>
          <div class="row">
            <label>Телефон:</label>
            <input class="form-control" type="text" formControlName="tel" />
          </div>
          <div class="row">
            <label>Имейл:</label>
            <input class="form-control" type="text" formControlName="email" />
          </div>
          <div class="row">
            <label>Град/Област:</label>
            <select class="form-control" (change)="valueChanged()" formControlName="grad">
              <option selected value="">Всички</option>
              <option *ngFor="let gr of towns" value="{{ gr }}">{{ gr }}</option>
            </select>
          </div>
          <div class="row">
            <button class="btn btn-primary m-1" (click)="openRaionModal()">Райони</button>
          </div>
          <div class="row">
            {{ addformcontact.get('raion')?.value }}
          </div>
          <div class="row">
            <button class="btn btn-primary m-1" (click)="openTipModal()">Тип имоти</button>
          </div>
          <div class="row">
            {{ addformcontact.get('tip')?.value }}
          </div>
          <div class="row">
            <label>Квадратура от:</label>
          </div>
          <div class="row">
            <input class="form-control" type="number" formControlName="kvOt" />
          </div>
          <div class="row">
            <label>Квадратура до:</label>
          </div>
          <div class="row">
            <input class="form-control" type="number" formControlName="kvDo" />
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Затвори</button>

  <button mat-button (click)="savecontact()" cdkFocusInitial>Запази</button>
</div>
