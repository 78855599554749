<div class="container-lg">
   <app-menu></app-menu>
    <div class="row centercontent m-2">
        <p style="font-size: larger;">Универсална търсачка на имоти във всички по-известни Български сайтове за имоти!!!</p>
        <p style="font-size: larger;">Нашата търсачка позволява да търсите имоти в България като има бoгат набор от филтри и мощна база данни,
             за лесно и удобно търсене!</p>
             <p style="font-size: larger; font-weight: bold;">Оставете технологията да свърши Вашата работа!!!</p>
    </div>
    <div class="row centercontent m-2">
        <p style="font-size: larger;">За да използвате търсачката се изисква акаунт и месечен абонамент. Цената за месец ползване на търсачката е 10 лв.</p>
    </div>
    <div class="row centercontent m-2">
        <p style="font-size: larger;"> Тарифи и отстъпки </p>
    </div>

    
    
</div>
