import { ContactsTipRaionModalComponent } from './../contacts-tip-raion-modal/contacts-tip-raion-modal.component';
import { SearchcatService } from './../../services/searchcat.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IsMobileService } from 'src/app/services/is-mobile.service';
import { RequestsService } from 'src/app/services/requests.service';
import { ChoseContactSearchComponent } from '../chose-contact-search/chose-contact-search.component';

@Component({
  selector: 'app-contact-info-show',
  templateUrl: './contact-info-show.component.html',
  styleUrls: ['./contact-info-show.component.css'],
})
export class ContactInfoShowComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private req: RequestsService,
    private dialogRef: MatDialogRef<ContactInfoShowComponent>,
    private mobile: IsMobileService,
    private getCat: SearchcatService,
    private dialog: MatDialog
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    event.target.innerWidth;

    if (event.target.innerWidth < 990) {
      this.formobile = true;
      this.dialogRef.updateSize('100%');
    } else {
      this.dialogRef.updateSize('40%');
      this.formobile = false;
    }
  }
  editContact = new FormGroup({
    name: new FormControl(''),
    telefon: new FormControl(''),
    mail: new FormControl(''),
    grad: new FormControl(''),
    raion: new FormControl(''),
    tip: new FormControl(''),
    kvOt: new FormControl(''),
    kvDo: new FormControl(''),
  });
  edit = false;
  someValueIsChanged = false;
  formobile: any;
  contactInfo: any;
  gradoblastcat: any;
  tipcat: any;
  raioncat: any;
  idToedit: any;
  raions: any;
  tipImots: any;
  ngOnInit(): void {
    if (this.mobile.isMobile()) {
      this.dialogRef.updateSize('100%');
      this.formobile = true;
    } else {
      this.dialogRef.updateSize('40%');
      this.formobile = false;
    }
    this.contactInfo = this.data.test;
    this.idToedit = this.contactInfo.id;
    this.gradoblastcat = this.getCat.getgradoblast();
    this.tipcat = this.getCat.gettypeimot();
    this.raioncat = this.getCat.getRaionsFromGrad(this.contactInfo.oblastGrad);
    this.raions = this.contactInfo.raion;
    this.tipImots = this.contactInfo.tipImot;
  }
  openRaionModal() {
    if (this.editContact.get('grad')?.value == '') {
      window.alert('Не сте избрали област или град');
    } else {
      let dialogOtg = this.dialog.open(ContactsTipRaionModalComponent, {
        data: { test: this.raioncat, cheked: this.contactInfo.raion, type: 0 },
      });
      dialogOtg.afterClosed().subscribe(
        (result) => {
          //console.log(result);

          this.raions = result;
          this.someValueIsChanged = true;
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  openTipModal() {
    let dialogOtg = this.dialog.open(ContactsTipRaionModalComponent, {
      data: { test: this.tipcat, cheked: this.contactInfo.tipImot, type: 1 },
    });
    dialogOtg.afterClosed().subscribe(
      (result) => {
        //console.log(result);

        this.tipImots = result;
        this.someValueIsChanged = true;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  gradChange() {
    this.raioncat = this.getCat.getRaionsFromGrad(this.editContact.get('grad')?.value);
    this.someValueIsChanged = true;
  }
  editFunc() {
    this.edit = true;

    this.editContact.setValue({
      name: this.contactInfo.contactName,
      telefon: this.contactInfo.contactPhone,
      mail: this.contactInfo.contactEmail,
      grad: this.contactInfo.oblastGrad,
      raion: this.contactInfo.raion,
      tip: this.contactInfo.tipImot,
      kvOt: this.contactInfo.kvadraturaOt,
      kvDo: this.contactInfo.kvadraturaDo,
    });
  }
  saveEdited() {
    // console.log(this.someValueIsChanged);
    if (this.someValueIsChanged) {
      if (this.raions == null || this.raions == undefined) this.raions = '';
      if (this.tipImots == null || this.tipImots == undefined) this.tipImots = '';
      let send = {
        contactid: this.idToedit,
        contactName: this.editContact.get('name')?.value,
        contactPhone: this.editContact.get('telefon')?.value,
        contactEmail: this.editContact.get('mail')?.value,
        contactGrad: this.editContact.get('grad')?.value,
        contactRaion: this.raions,
        contactTipImot: this.tipImots,
        contactKvOt: this.editContact.get('kvOt')?.value,
        contactKvDo: this.editContact.get('kvDo')?.value,
      };

      this.req.edituserimotcontact(send).subscribe(
        (resul) => {
          this.contactInfo.contactEmail = this.editContact.get('mail')?.value;
          this.contactInfo.contactName = this.editContact.get('name')?.value;
          this.contactInfo.contactPhone = this.editContact.get('telefon')?.value;
          this.contactInfo.oblastGrad = this.editContact.get('grad')?.value;
          this.contactInfo.raion = this.raions;
          this.contactInfo.tipImot = this.tipImots;
          this.contactInfo.kvadraturaOt = this.editContact.get('kvOt')?.value;
          this.contactInfo.kvadraturaDo = this.editContact.get('kvDo')?.value;
          this.edit = false;
        },
        (err) => {
          console.log(err);
        }
      );
    }
    this.edit = false;
  }
  valueChanged() {
    this.someValueIsChanged = true;
  }
}
