<h1 mat-dialog-title>Информация за контакта</h1>
<div mat-dialog-content *ngIf="!edit">
  <div class="container-fluid" *ngIf="!formobile">
    <div class="row">
      <div class="col-6" style="cursor: pointer">Име:</div>
      <div class="col">{{ contactInfo.contactName }}</div>
    </div>
    <div class="row">
      <div class="col-6" style="cursor: pointer">Телефон:</div>
      <div class="col">
        <a href="tel:{{ contactInfo.contactPhone }}">{{ contactInfo.contactPhone }}</a>
      </div>
    </div>
    <div class="row">
      <div class="col-6" style="cursor: pointer">Имейл:</div>
      <div class="col">
        {{ contactInfo.contactEmail }}
      </div>
    </div>
    <div class="row">
      <div class="col-6" style="cursor: pointer">Филтър за град:</div>
      <div class="col">
        {{ contactInfo.oblastGrad }}
      </div>
    </div>
    <div class="row">
      <div class="col-6" style="cursor: pointer">Филтър за район:</div>
      <div class="col">
        {{ contactInfo.raion }}
      </div>
    </div>
    <div class="row">
      <div class="col-6" style="cursor: pointer">Филтър за тип имот:</div>
      <div class="col">
        {{ contactInfo.tipImot }}
      </div>
    </div>
    <div class="row">
      <div class="col-6" style="cursor: pointer">Филтър за квадратура от:</div>
      <div class="col">{{ contactInfo.kvadraturaOt }} кв.м</div>
    </div>
    <div class="row">
      <div class="col-6" style="cursor: pointer">Филтър за квадратура до:</div>
      <div class="col">{{ contactInfo.kvadraturaDo }} кв.м</div>
    </div>
    <div class="row">
      <div class="col m-2 p-2"><button mat-button (click)="editFunc()">Редактирай</button></div>
    </div>
  </div>
  <div class="container-fluid" *ngIf="formobile">
    <div class="row blever mt-2">
      <div class="col-12" style="cursor: pointer">Име:</div>
      <div class="col">{{ contactInfo.contactName }}</div>
    </div>
    <div class="row blever mt-1">
      <div class="col-12" style="cursor: pointer">Телефон:</div>
      <div class="col">
        <a href="tel:{{ contactInfo.contactPhone }}">{{ contactInfo.contactPhone }}</a>
      </div>
    </div>
    <div class="row blever mt-1">
      <div class="col-12" style="cursor: pointer">Имейл:</div>
      <div class="col">
        {{ contactInfo.contactEmail }}
      </div>
    </div>
    <div class="row blever mt-1">
      <div class="col-12" style="cursor: pointer">Филтър за град:</div>
      <div class="col">
        {{ contactInfo.oblastGrad }}
      </div>
    </div>
    <div class="row blever mt-1">
      <div class="col-12" style="cursor: pointer">Филтър за район:</div>
      <div class="col">
        {{ contactInfo.raion }}
      </div>
    </div>
    <div class="row blever mt-1">
      <div class="col-12" style="cursor: pointer">Филтър за тип имот:</div>
      <div class="col">
        {{ contactInfo.tipImot }}
      </div>
    </div>
    <div class="row blever mt-1">
      <div class="col-12" style="cursor: pointer">Филтър за квадратура от:</div>
      <div class="col">{{ contactInfo.kvadraturaOt }} кв.м</div>
    </div>
    <div class="row blever mt-1">
      <div class="col-12" style="cursor: pointer">Филтър за квадратура до:</div>
      <div class="col">{{ contactInfo.kvadraturaDo }} кв.м</div>
    </div>
    <div class="row">
      <div class="col m-2 p-2"><button mat-button (click)="editFunc()">Редактирай</button></div>
    </div>
  </div>
</div>
<!-- От тук започва edit прозореца -->
<div mat-dialog-content *ngIf="edit">
  <div class="container-fluid" *ngIf="!formobile">
    <form [formGroup]="editContact">
      <div class="row">
        <div class="col-6" style="cursor: pointer">Име:</div>
        <div class="col">
          <input class="form-control" (change)="valueChanged()" type="text" formControlName="name" />
        </div>
      </div>
      <div class="row">
        <div class="col-6" style="cursor: pointer">Телефон:</div>
        <div class="col">
          <input class="form-control" (change)="valueChanged()" type="text" formControlName="telefon" />
        </div>
      </div>
      <div class="row">
        <div class="col-6" style="cursor: pointer">Имейл:</div>
        <div class="col">
          <input class="form-control" (change)="valueChanged()" type="text" formControlName="mail" />
        </div>
      </div>
      <div class="row">
        <div class="col-6" style="cursor: pointer">Филтър за град:</div>
        <div class="col">
          <select class="form-control" (change)="gradChange()" formControlName="grad">
            <option selected value="">Всички</option>
            <option *ngFor="let gr of gradoblastcat" value="{{ gr }}">{{ gr }}</option>
          </select>
          <!-- <input class="form-control" type="text" formControlName="grad" /> -->
        </div>
      </div>
      <div class="row">
        <div class="col-6" style="cursor: pointer">Филтър за район:</div>
        <div class="col">
          <button class="btn btn-primary m-1" (click)="openRaionModal()">Райони</button>
          <!-- <select class="form-control" (change)="valueChanged()" formControlName="raion">
            <option selected value="">Всички</option>
            <option *ngFor="let gr of raioncat" value="{{ gr }}">{{ gr }}</option>
          </select> -->
          <!-- <input class="form-control" type="text" formControlName="raion" /> -->
        </div>
      </div>
      <div class="row">
        <div class="col-6" style="cursor: pointer">Филтър за тип имот:</div>
        <div class="col">
          <button class="btn btn-primary m-1" (click)="openTipModal()">Тип имоти</button>
          <!-- <select class="form-control" (change)="valueChanged()" formControlName="tip">
            <option selected value="">Всички</option>
            <option *ngFor="let gr of tipcat" value="{{ gr }}">{{ gr }}</option>
          </select> -->
          <!-- <input class="form-control" type="text" formControlName="tip" /> -->
        </div>
      </div>
      <div class="row">
        <div class="col-6" style="cursor: pointer">Филтър за квадратура от:</div>
        <div class="col">
          <input class="form-control" (change)="valueChanged()" type="number" formControlName="kvOt" />
        </div>
      </div>
      <div class="row">
        <div class="col-6" style="cursor: pointer">Филтър за квадратура до:</div>
        <div class="col">
          <input class="form-control" (change)="valueChanged()" type="number" formControlName="kvDo" />
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col m-2 p-2"><button class="btn btn-primary m-1" (click)="saveEdited()">Запази</button></div>
    </div>
  </div>

  <div class="container-fluid" *ngIf="formobile">
    <form [formGroup]="editContact">
      <div class="row">
        <div class="col-6" style="cursor: pointer">Име:</div>
        <div class="col">
          <input class="form-control" (change)="valueChanged()" type="text" formControlName="name" />
        </div>
      </div>
      <div class="row">
        <div class="col-6" style="cursor: pointer">Телефон:</div>
        <div class="col">
          <input class="form-control" (change)="valueChanged()" type="text" formControlName="telefon" />
        </div>
      </div>
      <div class="row">
        <div class="col-6" style="cursor: pointer">Имейл:</div>
        <div class="col">
          <input class="form-control" (change)="valueChanged()" type="text" formControlName="mail" />
        </div>
      </div>
      <div class="row">
        <div class="col-6" style="cursor: pointer">Филтър за град:</div>
        <div class="col">
          <select class="form-control" (change)="gradChange()" formControlName="grad">
            <option selected value="">Всички</option>
            <option *ngFor="let gr of gradoblastcat" value="{{ gr }}">{{ gr }}</option>
          </select>
          <!-- <input class="form-control" type="text" formControlName="grad" /> -->
        </div>
      </div>
      <div class="row">
        <div class="col-6" style="cursor: pointer">Филтър за район:</div>
        <div class="col">
          <button class="btn btn-primary m-1" (click)="openRaionModal()">Райони</button>
          <!-- <select class="form-control" (change)="valueChanged()" formControlName="raion">
            <option selected value="">Всички</option>
            <option *ngFor="let gr of raioncat" value="{{ gr }}">{{ gr }}</option>
          </select> -->
          <!-- <input class="form-control" type="text" formControlName="raion" /> -->
        </div>
      </div>
      <div class="row">
        <div class="col-6" style="cursor: pointer">Филтър за тип имот:</div>
        <div class="col">
          <button class="btn btn-primary m-1" (click)="openTipModal()">Тип имоти</button>
          <!-- <select class="form-control" (change)="valueChanged()" formControlName="tip">
            <option selected value="">Всички</option>
            <option *ngFor="let gr of tipcat" value="{{ gr }}">{{ gr }}</option>
          </select> -->
          <!-- <input class="form-control" type="text" formControlName="tip" /> -->
        </div>
      </div>
      <div class="row">
        <div class="col-6" style="cursor: pointer">Филтър за квадратура от:</div>
        <div class="col">
          <input class="form-control" (change)="valueChanged()" type="number" formControlName="kvOt" />
        </div>
      </div>
      <div class="row">
        <div class="col-6" style="cursor: pointer">Филтър за квадратура до:</div>
        <div class="col">
          <input class="form-control" (change)="valueChanged()" type="number" formControlName="kvDo" />
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col m-2 p-2"><button class="btn btn-primary m-1" (click)="saveEdited()">Запази</button></div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Затвори</button>

  <button mat-button mat-dialog-close cdkFocusInitial>ОК</button>
</div>
