import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IsMobileService {

  ismobile = false;
  constructor() { 
    
  }
  isMobile(){
    if(window.innerWidth<990){
      return true;
    }
    return false;
  }

}
