import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IsMobileService } from 'src/app/services/is-mobile.service';
import { RequestsService } from 'src/app/services/requests.service';

@Component({
  selector: 'app-chose-contact-search',
  templateUrl: './chose-contact-search.component.html',
  styleUrls: ['./chose-contact-search.component.css'],
})
export class ChoseContactSearchComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private req: RequestsService,
    private dialogRef: MatDialogRef<ChoseContactSearchComponent>,
    private mobile: IsMobileService
  ) {}

  contact: any[] = [];
  formobile: any;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    event.target.innerWidth;

    if (event.target.innerWidth < 990) {
      this.formobile = true;
      this.dialogRef.updateSize('100%');
    } else {
      this.dialogRef.updateSize('40%');
      this.formobile = false;
    }
  }

  ngOnInit(): void {
    if (this.mobile.isMobile()) {
      this.dialogRef.updateSize('100%');
      this.formobile = true;
    } else {
      this.dialogRef.updateSize('40%');
      this.formobile = false;
    }
    let userid = localStorage.getItem('userid');

    this.req.getusercontacts(userid).subscribe(
      (result: any) => {
        //console.log(result);
        this.contact = result;
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  izbrankontakt(val: any) {
    let ret = this.contact.find((id) => {
      return id.id === val;
    });

    let obj = {
      ok: '1',
      data: ret,
    };

    this.dialogRef.close(obj);
  }
}
