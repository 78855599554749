import { AddContactComponent } from './../../dialogs/add-contact/add-contact.component';
import { ContactInfoShowComponent } from './../../dialogs/contact-info-show/contact-info-show.component';
import { RequestsService } from 'src/app/services/requests.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-my-contacts',
  templateUrl: './my-contacts.component.html',
  styleUrls: ['./my-contacts.component.css'],
})
export class MyContactsComponent implements OnInit {
  constructor(private req: RequestsService, private dialog: MatDialog) {}
  allcontacts: any[] = [];
  sortcontacts: any[] = [];
  ngOnInit(): void {
    this.req.getusercontacts(localStorage.getItem('userid')).subscribe(
      (res: any) => {
        this.allcontacts = res;
        this.sortcontacts = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  addContact() {
    let dialogOtg = this.dialog.open(AddContactComponent, {
      data: { test: 'nako' },
    });
    dialogOtg.afterClosed().subscribe(
      (result) => {
        //console.log(result);
        this.ngOnInit();
      },
      (err) => {
        console.log(err);
      }
    );
  }
  deleteContact(id: any) {
    if (confirm('Искате ли да изтриете контакта')) {
      this.req.deleteuserimotcontact(id).subscribe(
        (res) => {
          this.sortcontacts = this.sortcontacts.filter((el) => {
            return el.id != id;
          });
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  showContactInfo(index: any) {
    let contactInfoForSend = this.allcontacts.filter((el) => el.id == index);

    let dialogOtg = this.dialog.open(ContactInfoShowComponent, {
      data: { test: contactInfoForSend[0] },
    });
    dialogOtg.afterClosed().subscribe(
      (result) => {
        console.log(result);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  searchkont(where: any, ser: String) {
    ser = ser.toLowerCase().replace(/\s/g, '');

    if (where == 'name') {
      this.sortcontacts = this.allcontacts.filter(function (ele) {
        return ele.contactName.toLowerCase().replace(/\s/g, '').includes(ser);
      });
      return;
    }
    if (where == 'telefon') {
      this.sortcontacts = this.allcontacts.filter(function (ele) {
        return ele.contactPhone.toLowerCase().replace(/\s/g, '').includes(ser);
      });
      return;
    }
    if (where == 'email') {
      this.sortcontacts = this.allcontacts.filter(function (ele) {
        return ele.contactEmail.toLowerCase().replace(/\s/g, '').includes(ser);
      });
      return;
    }
  }
}
