import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ShowobqviComponent } from './components/showobqvi/showobqvi.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuComponent } from './components/menu/menu.component';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { UserpageComponent } from './components/userpage/userpage.component';
import { LoginComponent } from './components/login/login.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ShowDbobqviComponent } from './components/show-dbobqvi/show-dbobqvi.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ImotviewComponent } from './components/imotview/imotview.component';
import { GalleryModule } from 'ngx-doe-gallery';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IsUserDirective } from './directives/is-user.directive';
import { IsAdminDirective } from './directives/is-admin.directive';
import { AdminpageComponent } from './components/adminpage/adminpage.component';
import { ZapazeniobqviComponent } from './components/zapazeniobqvi/zapazeniobqvi.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import { ObshtiusloviqComponent } from './components/obshtiusloviq/obshtiusloviq.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { ContactDialogComponent } from './dialogs/contact-dialog/contact-dialog.component';
import { AddImotContactComponent } from './dialogs/add-imot-contact/add-imot-contact.component';
import { LogininterseptorInterceptor } from './interseptor/logininterseptor.interceptor';
import { AuthGuardGuard } from './Authguard/auth-guard.guard';
import { MyContactsComponent } from './components/my-contacts/my-contacts.component';
import { RaionmodalComponent } from './dialogs/raionmodal/raionmodal.component';
import { TipImotmodalComponent } from './dialogs/tip-imotmodal/tip-imotmodal.component';
import { AddContactToimotComponent } from './dialogs/add-contact-toimot/add-contact-toimot.component';
import { ChoseContactSearchComponent } from './dialogs/chose-contact-search/chose-contact-search.component';
import { DialogNewOrExistComponent } from './dialogs/dialog-new-or-exist/dialog-new-or-exist.component';
import { ContactInfoShowComponent } from './dialogs/contact-info-show/contact-info-show.component';
import { ContactsTipRaionModalComponent } from './dialogs/contacts-tip-raion-modal/contacts-tip-raion-modal.component';
import { AddContactComponent } from './dialogs/add-contact/add-contact.component';
import { OlxredirectComponent } from './components/olxredirect/olxredirect.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ShowobqviComponent,
    MenuComponent,
    ScrollToTopComponent,
    RegistrationComponent,
    UserpageComponent,
    LoginComponent,
    ShowDbobqviComponent,
    ImotviewComponent,
    IsUserDirective,
    IsAdminDirective,
    AdminpageComponent,
    ZapazeniobqviComponent,
    ObshtiusloviqComponent,
    ContactDialogComponent,
    AddImotContactComponent,
    MyContactsComponent,
    RaionmodalComponent,
    TipImotmodalComponent,
    AddContactToimotComponent,
    ChoseContactSearchComponent,
    DialogNewOrExistComponent,
    ContactInfoShowComponent,
    ContactsTipRaionModalComponent,
    AddContactComponent,
    OlxredirectComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    GalleryModule, //https://openbase.com/js/@ngx-imagery/gallery ili https://daelmaak.github.io/ngx-doe-gallery/ tova iziskva i BrowserAnimationsModule s tova  npm install @angular/animations
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule ,
    MatFormFieldModule,
    MatInputModule,
    NgxMaterialTimepickerModule,
    MatCardModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule
  ],
  providers: [
    AuthGuardGuard,
    {
      provide:HTTP_INTERCEPTORS,
      useClass:LogininterseptorInterceptor,
      multi:true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
