import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class RequestsService {
  constructor(private http: HttpClient, private router: Router) {}
  url = environment.url;

  checkImotForReal(id: any) {
    let pl = {
      imotid: id,
    };
    return this.http.post(`${this.url}/search/checkSpesificImot`, pl);
  }

  setTokenOlx(token: any) {
    let pl = {
      token: token,
    };
    return this.http.post(`${this.url}/tokens/saveTokenOlx`, pl);
  }
  getOlxTokenExpirein() {
    let pl = {
      token: 'isexpire',
    };
    return this.http.post(`${this.url}/tokens/expireToken`, pl);
  }

  getfilteredimotsfav(pl: any) {
    return this.http.post(`${this.url}/search/getimotidbsearchfavouriteNew`, pl);
  }
  // getfilteredimotsfav(pl: any) {
  //   return this.http.post(`${this.url}/search/getimotidbsearchfavourite`, pl);
  // }

  getfilteredimots(pl: any) {
    return this.http.post(`${this.url}/search/getimotidbsearchNew`, pl);
  }
  //old
  // getfilteredimots(pl:any){

  //   return this.http.post(`${this.url}/search/getimotidbsearch`,pl);
  // }

  getuserfav(userid: any) {
    let pl = {
      userid: userid,
    };
    return this.http.post(`${this.url}/userdata/getimotinfav`, pl);
  }
  deleteuserimotcontact(contactid: any) {
    let pl = {
      contactid: contactid,
    };

    return this.http.post(`${this.url}/userdata/deleteuserimotcontacts`, pl);
  }
  edituserimotcontact(pl: any) {
    return this.http.post(`${this.url}/userdata/edituserimotcontacts`, pl);
  }
  adduserimotcontact(pl: any) {
    return this.http.post(`${this.url}/userdata/adduserimotcontacts`, pl);
  }

  getusercontacts(userid: any) {
    let pl = {
      userid: userid,
    };
    return this.http.post(`${this.url}/userdata/getusercontacts`, pl);
  }
  getuserimotcontact(userid: any, imotid: any) {
    let pl = {
      userid: userid,
      imotid: imotid,
    };
    return this.http.post(`${this.url}/userdata/getuserimotcontacts`, pl);
  }
  removeuserfav(userid: any, imotid: any) {
    let pl = {
      userid: userid,
      imotid: imotid,
    };
    return this.http.post(`${this.url}/userdata/removeimotinfav`, pl);
  }
  setuserfavbelejka(userid: any, imotid: any, belejkatext: any, belejkachas: any, belejkadata: any) {
    let pl = {
      userid: userid,
      imotid: imotid,
      belejkaText: belejkatext,
      belejkaChas: belejkachas,
      belejkaData: belejkadata,
    };
    return this.http.post(`${this.url}/userdata/setimotinfavbelejka`, pl);
  }

  adduserfav(userid: any, imotid: any) {
    let pl = {
      userid: userid,
      imotid: imotid,
    };
    return this.http.post(`${this.url}/userdata/putimotinfav`, pl);
  }
  getuserfilter(id: any) {
    let pl = {
      id: id,
    };
    return this.http.post(`${this.url}/userdata/getuserfilter`, pl);
  }
  setuserfilter(pl: any) {
    return this.http.post(`${this.url}/userdata/setuserfilter`, pl);
  }

  getuserdate(id: any) {
    let pl = {
      id: id,
    };
    return this.http.post(`${this.url}/userdata/getuserdata`, pl);
  }

  getfromdb() {
    let pl = {
      page: 'page',
    };
    return this.http.post(`${this.url}/search/getimotidb`, pl);
  }
  getfromdbbyid(id: any) {
    let pl = {
      id: id,
    };
    return this.http.post(`${this.url}/search/getimotbyid`, pl);
  }

  getapifromimot(page: Number) {
    let pl = {
      page: page,
    };
    return this.http.post(`${this.url}/search/apisearchimotbg`, pl);
  }

  getfromalo(page: String) {
    let pl = {
      page: page,
    };
    return this.http.post(`${this.url}/search/searchtodayinalo`, pl);
  }
  getfromimotbg(page: String) {
    let pl = {
      page: page,
    };
    return this.http.post(`${this.url}/search/searchnewimotbg`, pl);
  }
  login(name: string, pass: string) {
    let payload = {
      name: name,
      password: pass,
    };

    return new Promise((resolve, reject) => {
      this.http.post(`${this.url}/auth/login`, payload).subscribe(
        (res) => {
          return resolve(res);
        },
        (err) => {
          return reject(err);
        }
      );
    })
      .then((result: any) => {
        // console.log(result);

        let user = new User(result);
        localStorage.setItem('token', user.gettoken());
        localStorage.setItem('userid', user.getuserid());
        localStorage.setItem('userrole', user.getrole());
        localStorage.setItem('name', user.getname());

        let userfilter = user.getfilters();

        localStorage.setItem('userfilterTipObqva', userfilter.filterTipObqva);
        localStorage.setItem('userfilterGrad', userfilter.filterGrad);
        localStorage.setItem('userfilterRaion', userfilter.filterRaion);
        localStorage.setItem('userfilterTipImot', userfilter.filterTipImot);

        if (!!user.getuserid()) {
          this.router.navigate(['/obqvidb']);
        }

        // if(user.getrole()==="admin"){
        //   //environment.admin = true;
        //   this.router.navigate(['/admin']);

        // }
        // if(user.getrole()=== "user"){
        //  // environment.user = true;
        //   this.router.navigate(['/user']);
        // }
        // if(user.getrole()=== "usermagazine"){
        //  // environment.user = true;
        //   this.router.navigate(['/user']);
        // }
        // if(user.getrole()=== "userdilar"){
        // //  environment.user = true;
        //   this.router.navigate(['/user']);
        // }
        // if(user.getrole()=== "father"){
        //  // environment.father = true;
        //   this.router.navigate(['/user']);
        // }
      })
      .catch((err) => {
        return err;
      });
  }
}
