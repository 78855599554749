<div class="container-fluid pb-4">
  <app-menu></app-menu>
  <div class="container-lg blever backdeign mt-3">
    <div class="container-fluid whiteback p-2">
      <div class="row">
        <div class="col-sm-9">
          <div class="col">
            <div class="col-12 boldtext">
              {{ imot?.title }}
            </div>
            <div class="col">{{ imot?.town }},{{ imot?.raion }}</div>
          </div>
          <div class="col" style="max-width: 800px">
            <doe-gallery
              [items]="images"
              [counter]="false"
              [arrows]="true"
              [loop]="true"
              itemWidth="100%"
              objectFit="contain"
              style="width: 100%; max-height: 600px"
            ></doe-gallery>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="row">
            <div class="col" style="padding-top: 10px">
              <div class="row" style="font-size: large; font-weight: bold; justify-content: center">
                {{ imot?.price }} {{ imot?.currency }}
              </div>
              <div class="row" style="justify-content: center">({{ cenanakvadrat }} {{ imot?.currency }}/кв.м)</div>
            </div>
          </div>
          <div class="row" style="place-content: center">
            <button class="btn btn-primary m-1" *ngIf="!itsfavouriteimot" (click)="putimotfav()">Запази обява</button>
            <button class="btn btn-primary" *ngIf="itsfavouriteimot" (click)="removefromfav()">
              Премахни от запазени
            </button>
            <button class="btn btn-primary m-1" (click)="checkImot()">Провери обявата</button>
          </div>

          <!-- <div class="row" >
                        
                        <div class="col boldtext">
                            {{imot?.town}},{{imot?.raion}}
                        </div>
                    </div> -->
          <div class="row">
            <div class="col">Тип:</div>
            <div class="col" style="font-weight: bold">{{ imot?.title }}</div>
          </div>
          <div class="row">
            <div class="col">Квадратура:</div>
            <div class="col" style="font-weight: bold">{{ imot?.quadrature }} {{ imot?.metric }}</div>
          </div>
          <div class="row" *ngIf="imot?.typeBuild !== '-'">
            <div class="col">Строителство:</div>
            <div class="col" style="font-weight: bold">{{ imot?.typeBuild }}</div>
          </div>
          <div
            class="row"
            *ngIf="imot?.floorRegulation !== '-' && imot?.floorRegulation !== 'ДА' && imot?.floorRegulation !== 'НЕ'"
          >
            <div class="col">Етаж:</div>
            <div class="col" style="font-weight: bold">{{ imot?.floorRegulation }}</div>
          </div>
          <div class="row" *ngIf="imot?.floorRegulation !== '-' && imot?.floorRegulation === 'ДА'">
            <div class="col">Регулация:</div>
            <div class="col" style="font-weight: bold">{{ imot?.floorRegulation }}</div>
          </div>
          <div class="row" *ngIf="imot?.floorRegulation !== '-' && imot?.floorRegulation === 'НЕ'">
            <div class="col">Регулация:</div>
            <div class="col" style="font-weight: bold">{{ imot?.floorRegulation }}</div>
          </div>
          <div class="row" *ngIf="imot?.floorMax !== '-'">
            <div class="col">Общо етажи:</div>
            <div class="col" style="font-weight: bold">{{ imot?.floorMax }}</div>
          </div>
          <div class="row" *ngIf="imot?.yard !== '-'">
            <div class="col">Двор:</div>
            <div class="col" style="font-weight: bold">{{ imot?.yard }} {{ imot?.metric }}</div>
          </div>
          <div class="row" *ngIf="imot?.phoneElect !== '-' && imot?.title === 'ПАРЦЕЛ'">
            <div class="col">Ток:</div>
            <div class="col" style="font-weight: bold">{{ imot?.phoneElect }}</div>
          </div>
          <div class="row" *ngIf="imot?.phoneElect !== '-' && imot?.title !== 'ПАРЦЕЛ'">
            <div class="col">ГАЗ:</div>
            <div class="col" style="font-weight: bold">{{ imot?.phoneElect }}</div>
          </div>

          <div class="row" *ngIf="imot?.tecWatter !== '-' && imot?.title === 'ПАРЦЕЛ'">
            <div class="col">Вода:</div>
            <div class="col" style="font-weight: bold">{{ imot?.tecWatter }}</div>
          </div>
          <div class="row" *ngIf="imot?.tecWatter !== '-' && imot?.title !== 'ПАРЦЕЛ'">
            <div class="col">ТЕЦ:</div>
            <div class="col" style="font-weight: bold">{{ imot?.tecWatter }}</div>
          </div>
          <div class="row" *ngIf="imot?.ekstri !== '-'">
            <div class="col">Екстри:</div>
          </div>
          <div class="row" *ngIf="imot?.ekstri !== '-'">
            <div class="col" style="font-weight: bold">{{ imot?.ekstri }}</div>
          </div>
          <div class="row mt-4 blever" style="border-radius: 5px; margin-right: auto; margin-left: auto">
            <div class="col">
              <div class="row" *ngIf="imot?.agencyname == '-'" style="font-weight: bold">
                <div class="col">
                  Частно лице:
                  <mat-divider></mat-divider>
                </div>
              </div>

              <div class="row" *ngIf="imot?.agencyname !== '-'" style="font-weight: bold">
                <div class="col-12">
                  Агенция:
                  <mat-divider></mat-divider>
                </div>
                <div class="col-12">
                  {{ imot?.agencyname }}
                </div>
                <div class="col-12">
                  {{ imot?.agencycity }}
                </div>
              </div>

              <div class="row" style="font-size: larger; font-weight: bold">
                <div class="col-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-telephone-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                    />
                  </svg>
                </div>
                <div class="col">
                  <a href="tel:{{ imot?.phone }}">{{ imot?.phone }}</a>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row mt-2 blever pb-2 pt-1"
            *ngIf="itsfavouriteimot"
            style="margin-left: auto; margin-right: auto; border-radius: 5px"
          >
            <div class="col">
              <div class="row mt-1">
                <div class="col" style="font-weight: bold">
                  Опций на запазения имот
                  <mat-divider></mat-divider>
                </div>
              </div>

              <div class="row mt-1">
                <div class="col">
                  <a class="alink" type="button" (click)="belejkacollapse = !belejkacollapse">
                    Напомняне
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-caret-down-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z" />
                      <path
                        d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2z"
                      />
                    </svg>
                  </a>
                </div>
              </div>

              <div class="row mt-1 collapse" [ngbCollapse]="belejkacollapse">
                <div class="col">
                  <div class="row">
                    <div class="col">
                      <div class="row">
                        <div class="col">
                          {{ belejkaText }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          {{ belejkaChas }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          {{ belejkaData }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1" *ngIf="noBelejka">
                    <div class="col">
                      <a class="btn btn-primary" type="button" (click)="editbelejka = !editbelejka">
                        Създай напомняне
                      </a>
                    </div>
                  </div>
                  <div class="row mt-1" *ngIf="!noBelejka">
                    <div class="col">
                      <a class="btn btn-primary" type="button" (click)="editbelejka = !editbelejka">
                        Редактирай напомнянето
                      </a>
                    </div>
                  </div>
                  <div class="row mt-1" *ngIf="!noBelejka">
                    <div class="col">
                      <a class="btn btn-primary" type="button" (click)="deletebelejka()"> Изтрий напомнянето </a>
                    </div>
                  </div>
                  <div class="row mt-1 collapse" [ngbCollapse]="editbelejka">
                    <form [formGroup]="editformbelejka">
                      <div class="col">
                        <div class="row mt-1">
                          <div class="col">
                            <textarea class="form-control" aria-label="With textarea" formControlName="text"></textarea>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col">
                            <div class="24hr-example">
                              <mat-form-field>
                                <mat-label style="color: black">Час</mat-label>
                                <input
                                  matInput
                                  aria-label="24hr format"
                                  formControlName="chas"
                                  [ngxTimepicker]="fullTime"
                                  [format]="24"
                                  readonly
                                />
                                <ngx-material-timepicker #fullTime></ngx-material-timepicker>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col">
                            <mat-form-field>
                              <mat-label style="color: black">Дата</mat-label>
                              <input matInput [matDatepicker]="picker" formControlName="data" />
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col">
                            <button class="btn btn-primary" (click)="savebelejka()">Запази напомнянето</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- <div class="row mt-1">
                            <div class="col">
                                <a class="alink" type="button"  (click)="contactcolapse = !contactcolapse" >
                                    Контакти <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-square" viewBox="0 0 16 16">
                                        <path d="M3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z"/>
                                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2z"/>
                                      </svg>
                                </a>
                                
                            </div>
                        </div>
                        <div class="row mt-1 p-1 collapse" [ngbCollapse]="contactcolapse"  >
                            
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <div class="row blever mt-2" *ngFor="let n of usercontacts; index as i" style="border-radius: 5px;cursor: pointer;" >
                                            <div class="col" (click)="openContact(i)">
                                                
                                                {{n.contactStatus}}
                                            </div>
                                            <div class="col" (click)="openContact(i)">
                                                
                                                {{n.contactPhone}}
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="row mt-1 centercont">
                                    <div class="col" style="text-align-last: center;">
                                            <a class="btn btn-primary" (click)="addContact()" type="button"  >
                                                Добави контакт
                                            </a>
                                            
                                    </div>
                                </div> 
                                        
                                    
                            </div>
                                
                            
                        </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col" style="font-weight: bold">Описание на имота:</div>
      </div>
      <div class="row">
        <div class="col">
          <hr />
        </div>
      </div>

      <div class="row">
        <div class="col" [innerHTML]="imot?.opisanie"></div>
      </div>
    </div>
  </div>

  <app-scroll-to-top></app-scroll-to-top>
</div>
