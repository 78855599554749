import { Directive, ElementRef, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../Authguard/auth.service';

@Directive({
  selector: '[appIsUser]'
})
export class IsUserDirective {

  constructor(public elementRef: ElementRef, private renderer: Renderer2, private auth: AuthService, 
    private templateRef: TemplateRef<void>,private _viewContainer: ViewContainerRef) { 

      this._viewContainer.clear();
    //this.elementRef.nativeElement.style.display = 'none';
    
    if(this.auth.isAuthenticated()){
     
      if(auth.isUser()){
      //this.renderer.setStyle(this.elementRef.nativeElement,'visibility','hidden');
      //this.elementRef.nativeElement.style.display =  "block";
      this._viewContainer.createEmbeddedView(this.templateRef);
      }else{
        this._viewContainer.clear();
      }
  }

    
  }

}
