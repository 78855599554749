import { RequestsService } from 'src/app/services/requests.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.css']
})
export class ContactDialogComponent implements OnInit {

  nako="testvame tova"
  forret:any
  editC = true;
  editformcontact = new FormGroup({
    name:new FormControl(""),
    tel:new FormControl(""),
    status:new FormControl(""),
    email:new FormControl('')
  })
  constructor(@Inject(MAT_DIALOG_DATA) public data:any,private req:RequestsService,private dialogRef: MatDialogRef<ContactDialogComponent> ) { }

  ngOnInit(): void {
   
   
    this.editformcontact.setValue({
      name:this.data.contactName,
      tel:this.data.contactPhone,
      status:this.data.contactStatus,
      email:this.data.contactEmail
    })
  }

  deletecontact(){
    if (window.confirm("Искате ли да изтриете контакта")) {
      this.req.deleteuserimotcontact(this.data.id).subscribe((result:any)=>{

        this.dialogRef.close()
        
       },(err)=>{
         console.log(err);
         
       })
      
    }
    
     
  }
  
  savecontact(){
    
    


    let payload={
      contactid:this.data.id,
      contactName:this.editformcontact.get("name")?.value,
      contactPhone:this.editformcontact.get("tel")?.value,
      contactEmail:this.editformcontact.get("email")?.value,
      contactStatus:this.editformcontact.get("status")?.value
    }
    this.req.edituserimotcontact(payload).subscribe((result:any)=>{
      console.log(result);
      this.forret = payload;
      
      this.editC = true
    },(err)=>{
      console.log(err);
      
    })
    
  }

}
