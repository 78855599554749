import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IsMobileService } from 'src/app/services/is-mobile.service';
import { RequestsService } from 'src/app/services/requests.service';

@Component({
  selector: 'app-tip-imotmodal',
  templateUrl: './tip-imotmodal.component.html',
  styleUrls: ['./tip-imotmodal.component.css']
})
export class TipImotmodalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,private req:RequestsService,private dialogRef: MatDialogRef<TipImotmodalComponent>,
  private mobile:IsMobileService ) { }



  @HostListener('window:resize', ['$event'])
      onResize(event:any) {
      event.target.innerWidth;
      
      if(event.target.innerWidth<990){
        this.formobile=true;
      }else{
        this.formobile=false;
      }
      
      }
      
  formobile = false
  tipimots:any[]=[]
  numofcheked = 0;
  textforshow=false;
  forRetTipImots:any[]=[];

  ngOnInit(): void {
    if(this.mobile.isMobile()){
      this.formobile=true;
     }else{
      this.formobile=false;
     }

     let arr:any[] = this.data.type;
      this.tipimots=arr.map(e=>{
      
        let ret;
        ret={
          raion:e,
          ischek:false
        }
        if(this.data.from =="all"){

        
          if(sessionStorage.getItem("typeimot")==e && e!=""){
            
            this.forRetTipImots.push(e)
            this.numofcheked++;
            ret={
              raion:e,
              ischek:true
            }
          
          }
          else if(sessionStorage.getItem("typeimot0")==e && e!=""){
            this.forRetTipImots.push(e)
            this.numofcheked++;
            ret={
              raion:e,
              ischek:true
            }
          
          }else if(sessionStorage.getItem("typeimot1")==e && e!=""){
            this.forRetTipImots.push(e)
            this.numofcheked++;
            ret={
              raion:e,
              ischek:true
            }
          
          }else if(sessionStorage.getItem("typeimot2")==e && e!=""){
            this.forRetTipImots.push(e)
            this.numofcheked++;
            ret={
              raion:e,
              ischek:true
            }
            
          }else if(sessionStorage.getItem("typeimot3")==e && e!=""){
            this.forRetTipImots.push(e)
            this.numofcheked++;
            ret={
              raion:e,
              ischek:true
            }
            
          }else if(sessionStorage.getItem("typeimot4")==e && e!=""){
            this.forRetTipImots.push(e)
            this.numofcheked++;
            ret={
              raion:e,
              ischek:true
            }
            
          }
          if(this.numofcheked==6){
            this.textforshow=true;
            
          }
            return ret
        }
        if(this.data.from =="zap"){

        
          if(sessionStorage.getItem("Zaptypeimot")==e && e!=""){
            
            this.forRetTipImots.push(e)
            this.numofcheked++;
            ret={
              raion:e,
              ischek:true
            }
          
          }
          else if(sessionStorage.getItem("Zaptypeimot0")==e && e!=""){
            this.forRetTipImots.push(e)
            this.numofcheked++;
            ret={
              raion:e,
              ischek:true
            }
          
          }else if(sessionStorage.getItem("Zaptypeimot1")==e && e!=""){
            this.forRetTipImots.push(e)
            this.numofcheked++;
            ret={
              raion:e,
              ischek:true
            }
          
          }else if(sessionStorage.getItem("Zaptypeimot2")==e && e!=""){
            this.forRetTipImots.push(e)
            this.numofcheked++;
            ret={
              raion:e,
              ischek:true
            }
            
          }else if(sessionStorage.getItem("Zaptypeimot3")==e && e!=""){
            this.forRetTipImots.push(e)
            this.numofcheked++;
            ret={
              raion:e,
              ischek:true
            }
            
          }else if(sessionStorage.getItem("Zaptypeimot4")==e && e!=""){
            this.forRetTipImots.push(e)
            this.numofcheked++;
            ret={
              raion:e,
              ischek:true
            }
            
          }
          if(this.numofcheked==6){
            this.textforshow=true;
            
          }
            return ret
        }
        return ret;

      })
  }
  checkCheckBoxvalue(chek:any,index:any){
    // console.log(index);
    
    // console.log(chek.target.checked);
    // console.log(chek.target.defaultValue);
    if(chek.target.checked){

      if(this.numofcheked<6){
        this.textforshow=false;
        this.forRetTipImots.push(chek.target.defaultValue)
        this.numofcheked++;
        this.tipimots[index].ischek=true;
        
      }
      if(this.numofcheked==6){
        this.textforshow=true;
        
      }
     
    }else{
      this.numofcheked--;
      this.textforshow=false;
      this.tipimots[index].ischek=false;
      this.forRetTipImots=this.forRetTipImots.filter((e)=>{
        return e!=chek.target.defaultValue
      })
    }
    
  }
  ok(){
    
    if(this.data.from =="all"){
      switch(this.forRetTipImots.length){
        case 0:{
          sessionStorage.setItem('typeimot', "" );
          sessionStorage.setItem('typeimot0',"" );
          sessionStorage.setItem('typeimot1',"" );
          sessionStorage.setItem('typeimot2',"");
          sessionStorage.setItem('typeimot3',"" );
          sessionStorage.setItem('typeimot4',"" );
        }break;
        case 1:{
          sessionStorage.setItem('typeimot', this.forRetTipImots[0] );
          sessionStorage.setItem('typeimot0',"" );
          sessionStorage.setItem('typeimot1',"" );
          sessionStorage.setItem('typeimot2',"");
          sessionStorage.setItem('typeimot3',"" );
          sessionStorage.setItem('typeimot4',"" );
        }
        break;
        case 2:{
          sessionStorage.setItem('typeimot', this.forRetTipImots[0] );
          sessionStorage.setItem('typeimot0',this.forRetTipImots[1] );
          sessionStorage.setItem('typeimot1',"" );
          sessionStorage.setItem('typeimot2',"");
          sessionStorage.setItem('typeimot3',"" );
          sessionStorage.setItem('typeimot4',"" );
        }
        break;
        case 3:{
          sessionStorage.setItem('typeimot', this.forRetTipImots[0] );
          sessionStorage.setItem('typeimot0',this.forRetTipImots[1] );
          sessionStorage.setItem('typeimot1',this.forRetTipImots[2] );
          sessionStorage.setItem('typeimot2',"");
          sessionStorage.setItem('typeimot3',"" );
          sessionStorage.setItem('typeimot4',"" );
        }break;
        case 4:{
          sessionStorage.setItem('typeimot', this.forRetTipImots[0] );
          sessionStorage.setItem('typeimot0',this.forRetTipImots[1] );
          sessionStorage.setItem('typeimot1',this.forRetTipImots[2] );
          sessionStorage.setItem('typeimot2',this.forRetTipImots[3]);
          sessionStorage.setItem('typeimot3',"" );
          sessionStorage.setItem('typeimot4',"" );
        }break;
        case 5:{
          sessionStorage.setItem('typeimot', this.forRetTipImots[0] );
          sessionStorage.setItem('typeimot0',this.forRetTipImots[1] );
          sessionStorage.setItem('typeimot1',this.forRetTipImots[2] );
          sessionStorage.setItem('typeimot2',this.forRetTipImots[3]);
          sessionStorage.setItem('typeimot3',this.forRetTipImots[4] );
          sessionStorage.setItem('typeimot4',"" );
        }break;
        case 6:{
          sessionStorage.setItem('typeimot', this.forRetTipImots[0] );
          sessionStorage.setItem('typeimot0',this.forRetTipImots[1] );
          sessionStorage.setItem('typeimot1',this.forRetTipImots[2] );
          sessionStorage.setItem('typeimot2',this.forRetTipImots[3]);
          sessionStorage.setItem('typeimot3',this.forRetTipImots[4] );
          sessionStorage.setItem('typeimot4',this.forRetTipImots[5] );
        }break;
        

      }
    }
    if(this.data.from =="zap"){
      switch(this.forRetTipImots.length){
        case 0:{
          sessionStorage.setItem('Zaptypeimot', "" );
          sessionStorage.setItem('Zaptypeimot0',"" );
          sessionStorage.setItem('Zaptypeimot1',"" );
          sessionStorage.setItem('Zaptypeimot2',"");
          sessionStorage.setItem('Zaptypeimot3',"" );
          sessionStorage.setItem('Zaptypeimot4',"" );
        }break;
        case 1:{
          sessionStorage.setItem('Zaptypeimot', this.forRetTipImots[0] );
          sessionStorage.setItem('Zaptypeimot0',"" );
          sessionStorage.setItem('Zaptypeimot1',"" );
          sessionStorage.setItem('Zaptypeimot2',"");
          sessionStorage.setItem('Zaptypeimot3',"" );
          sessionStorage.setItem('Zaptypeimot4',"" );
        }
        break;
        case 2:{
          sessionStorage.setItem('Zaptypeimot', this.forRetTipImots[0] );
          sessionStorage.setItem('Zaptypeimot0',this.forRetTipImots[1] );
          sessionStorage.setItem('Zaptypeimot1',"" );
          sessionStorage.setItem('Zaptypeimot2',"");
          sessionStorage.setItem('Zaptypeimot3',"" );
          sessionStorage.setItem('Zaptypeimot4',"" );
        }
        break;
        case 3:{
          sessionStorage.setItem('Zaptypeimot', this.forRetTipImots[0] );
          sessionStorage.setItem('Zaptypeimot0',this.forRetTipImots[1] );
          sessionStorage.setItem('Zaptypeimot1',this.forRetTipImots[2] );
          sessionStorage.setItem('Zaptypeimot2',"");
          sessionStorage.setItem('Zaptypeimot3',"" );
          sessionStorage.setItem('Zaptypeimot4',"" );
        }break;
        case 4:{
          sessionStorage.setItem('Zaptypeimot', this.forRetTipImots[0] );
          sessionStorage.setItem('Zaptypeimot0',this.forRetTipImots[1] );
          sessionStorage.setItem('Zaptypeimot1',this.forRetTipImots[2] );
          sessionStorage.setItem('Zaptypeimot2',this.forRetTipImots[3]);
          sessionStorage.setItem('Zaptypeimot3',"" );
          sessionStorage.setItem('Zaptypeimot4',"" );
        }break;
        case 5:{
          sessionStorage.setItem('Zaptypeimot', this.forRetTipImots[0] );
          sessionStorage.setItem('Zaptypeimot0',this.forRetTipImots[1] );
          sessionStorage.setItem('Zaptypeimot1',this.forRetTipImots[2] );
          sessionStorage.setItem('Zaptypeimot2',this.forRetTipImots[3]);
          sessionStorage.setItem('Zaptypeimot3',this.forRetTipImots[4] );
          sessionStorage.setItem('Zaptypeimot4',"" );
        }break;
        case 6:{
          sessionStorage.setItem('Zaptypeimot', this.forRetTipImots[0] );
          sessionStorage.setItem('Zaptypeimot0',this.forRetTipImots[1] );
          sessionStorage.setItem('Zaptypeimot1',this.forRetTipImots[2] );
          sessionStorage.setItem('Zaptypeimot2',this.forRetTipImots[3]);
          sessionStorage.setItem('Zaptypeimot3',this.forRetTipImots[4] );
          sessionStorage.setItem('Zaptypeimot4',this.forRetTipImots[5] );
        }break;
        

      }
    }
   
    
    this.dialogRef.close(this.forRetTipImots)
    
  }
  

}
