import { RequestsService } from './../../services/requests.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private reqser:RequestsService) { }

  err:boolean =true;
  error:any;
  

  loginform = new FormGroup({
    name:new FormControl('',Validators.required),
    pass:new FormControl('',Validators.required),
    
    
  })

  ngOnInit(): void {
  }
  login(){
    if(this.loginform.status==="VALID"){
      console.log(this.loginform.get("name")?.value);
      console.log(this.loginform.get("pass")?.value);
      
     
      this.reqser.login(this.loginform.get("name")?.value,this.loginform.get("pass")?.value).then((err)=>{
        if(err){
         // console.log(err);
          
        let v = Object.values(err.error);
        this.error = v;
        console.log(err.error);
      }
      });
      

     
    }else{
  
      console.log("ne e vyvedeno pole");
     // this.wrongpass = true;
      
    }
  }

}
