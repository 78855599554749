import { AuthService } from './../../Authguard/auth.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @ViewChild('defaultNavbar1') colapmenu!:ElementRef;
  constructor(private router:Router,private auth:AuthService) { }
  isCollapsed = false;
  isloged = false;
  ngOnInit(): void {
    this.logged();

    
  }

 

  loguot(){
    
      localStorage.clear();
      //location.reload();
      //console.log(this.router.url)
      if(this.router.url==="/"){
        location.reload();
      }
      this.router.navigate(['']);
      
  
    
  }

  logged(){
    if(this.auth.isAuthenticated()){
      this.isloged=false;
    }else{
      this.isloged=true;
    }
    
  }

  routeto(site: any){
    
    let newLocation = `/obqvi/${site}`;

    this.router
    .routeReuseStrategy
    .shouldReuseRoute = function () {
        return false;
      };
    this.router
    .navigateByUrl(newLocation)
    .then(
    (worked) => {
        //works only because we hooked 
        //the routeReuseStrategy.shouldReuseRoute above
    },
    (error) => {
    
      }
    );

  }
  

}
