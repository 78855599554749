import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IsMobileService } from 'src/app/services/is-mobile.service';
import { RequestsService } from 'src/app/services/requests.service';
import { SearchcatService } from 'src/app/services/searchcat.service';
import { ChoseContactSearchComponent } from '../chose-contact-search/chose-contact-search.component';

@Component({
  selector: 'app-contacts-tip-raion-modal',
  templateUrl: './contacts-tip-raion-modal.component.html',
  styleUrls: ['./contacts-tip-raion-modal.component.css'],
})
export class ContactsTipRaionModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private req: RequestsService,
    private dialogRef: MatDialogRef<ContactsTipRaionModalComponent>,
    private mobile: IsMobileService,
    private getCat: SearchcatService
  ) {}
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    event.target.innerWidth;

    if (event.target.innerWidth < 990) {
      this.formobile = true;
      this.dialogRef.updateSize('100%');
    } else {
      this.dialogRef.updateSize('40%');
      this.formobile = false;
    }
  }
  formobile: any;
  raions: any = [];
  tipimots: any = [];
  numofcheked = 0;
  textforshow = false;
  raionOrTip = false;

  ngOnInit(): void {
    if (this.mobile.isMobile()) {
      this.dialogRef.updateSize('100%');
      this.formobile = true;
    } else {
      this.dialogRef.updateSize('40%');
      this.formobile = false;
    }
    //data.type =0 shte e raion a data.type=1 shte e tip
    if (this.data.type == 0) {
      this.raionOrTip = true;
      let arrOfCheked = this.data.cheked.split(',');

      this.data.test.forEach((element: any, index: any) => {
        this.raions.push({
          raion: element,
          cheked: false,
        });
        for (let i = 0; i <= arrOfCheked.length; i++) {
          if (element == arrOfCheked[i]) {
            this.raions[index].cheked = true;
          }
        }
      });
    } else {
      //tuka za tipimot syshtoto
      this.raionOrTip = false;
      let arrOfCheked = this.data.cheked.split(',');

      this.data.test.forEach((element: any, index: any) => {
        this.tipimots.push({
          raion: element,
          cheked: false,
        });
        for (let i = 0; i <= arrOfCheked.length; i++) {
          if (element == arrOfCheked[i]) {
            this.tipimots[index].cheked = true;
          }
        }
      });
    }
  }
  checkCheckBoxvalue(chek: any, index: any) {
    // console.log(index);

    // console.log(chek.target.checked);
    // console.log(chek.target.defaultValue);
    if (this.data.type == 0) {
      if (chek.target.checked) {
        if (this.numofcheked < 5) {
          this.textforshow = false;
          this.numofcheked++;
          this.raions[index].cheked = true;
        }
        if (this.numofcheked == 4) {
          this.textforshow = true;
        }
      } else {
        this.numofcheked--;
        this.textforshow = false;
        this.raions[index].cheked = false;
      }
    } else {
      if (chek.target.checked) {
        if (this.numofcheked < 5) {
          this.textforshow = false;
          this.numofcheked++;
          this.tipimots[index].cheked = true;
        }
        if (this.numofcheked == 4) {
          this.textforshow = true;
        }
      } else {
        this.numofcheked--;
        this.textforshow = false;
        this.tipimots[index].cheked = false;
      }
    }
  }
  ok() {
    if (this.data.type == 0) {
      let ret: any = [];
      this.raions.forEach((el: any) => {
        if (el.cheked) {
          ret.push(el.raion);
        }
      });
      ret = ret.join(',');

      this.dialogRef.close(ret);

      //za raioni
    } else {
      //za tip imot
      let ret: any = [];
      this.tipimots.forEach((el: any) => {
        if (el.cheked) {
          ret.push(el.raion);
        }
      });
      ret = ret.join(',');

      this.dialogRef.close(ret);
    }
  }
}
