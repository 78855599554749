import { RequestsService } from 'src/app/services/requests.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-add-imot-contact',
  templateUrl: './add-imot-contact.component.html',
  styleUrls: ['./add-imot-contact.component.css']
})
export class AddImotContactComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,private req:RequestsService,private dialogRef: MatDialogRef<AddImotContactComponent>) { }

  addformcontact = new FormGroup({
    name:new FormControl(""),
    tel:new FormControl(""),
    status:new FormControl(""),
    email:new FormControl('')
  })

  ngOnInit(): void {
  }
  savecontact(){
    let payload={
      userid:localStorage.getItem("userid"),
      imotid:this.data,
      contactName:this.addformcontact.get("name")?.value,
      contactPhone:this.addformcontact.get("tel")?.value,
      contactEmail:this.addformcontact.get("email")?.value,
      contactStatus:this.addformcontact.get("status")?.value
    }
    this.req.adduserimotcontact(payload).subscribe((result:any)=>{
      console.log(result);
      this.dialogRef.close()
    },(err)=>{
      console.log(err);
      
    })
    
    
  }

}
