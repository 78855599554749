<h1 mat-dialog-title>Тип имоти </h1>
<div mat-dialog-content>
    <div class="container-fluid">
        <div class="row" *ngIf="!formobile">

            <div class="col">
                <div class="col-12" *ngFor="let r of tipimots; let i = index;">

                    <div class="form-check" *ngIf="tipimots.length/2>i">
                        <input class="form-check-input" [disabled]="textforshow && !r.ischek" [checked]="r.ischek"
                            type="checkbox" value="{{r.raion}}" (change)="checkCheckBoxvalue($event,i)"
                            id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            {{r.raion}}
                        </label>
                    </div>

                </div>
            </div>
            <div class="col">
                <div class="col-12" *ngFor="let r of tipimots; let i = index;">

                    <div class="form-check" *ngIf="tipimots.length/2<i">
                        <input class="form-check-input" [disabled]="textforshow && !r.ischek" [checked]="r.ischek"
                            type="checkbox" value="{{r.raion}}" (change)="checkCheckBoxvalue($event,i)"
                            id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            {{r.raion}}
                        </label>
                    </div>

                </div>
            </div>

        </div>
        <div class="row" *ngIf="formobile">
            <div class="col-12" *ngFor="let r of tipimots; let i = index;">
                <div class="form-check">
                    <input class="form-check-input" [disabled]="textforshow && !r.ischek" [checked]="r.ischek"
                        type="checkbox" value="{{r.raion}}" (change)="checkCheckBoxvalue($event,i)"
                        id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                        {{r.raion}}
                    </label>
                </div>
            </div>
        </div>

    </div>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close="false">Затвори</button>

    <button mat-button (click)="ok()" cdkFocusInitial>ОК</button>
</div>