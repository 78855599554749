import { OlxredirectComponent } from './components/olxredirect/olxredirect.component';
import { MyContactsComponent } from './components/my-contacts/my-contacts.component';
import { ObshtiusloviqComponent } from './components/obshtiusloviq/obshtiusloviq.component';
import { ZapazeniobqviComponent } from './components/zapazeniobqvi/zapazeniobqvi.component';
import { AdminpageComponent } from './components/adminpage/adminpage.component';
import { UserpageComponent } from './components/userpage/userpage.component';
import { ImotviewComponent } from './components/imotview/imotview.component';
import { ShowDbobqviComponent } from './components/show-dbobqvi/show-dbobqvi.component';
import { ShowobqviComponent } from './components/showobqvi/showobqvi.component';
import { HomeComponent } from './components/home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardGuard } from './Authguard/auth-guard.guard';

const routes: Routes = [
  { path: '', component: HomeComponent },
  //{path:'obqvi/:site',component:ShowobqviComponent},
  { path: 'obqvidb', component: ShowDbobqviComponent, canActivate: [AuthGuardGuard] },
  { path: 'imot/:id', component: ImotviewComponent, canActivate: [AuthGuardGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'userpage', component: UserpageComponent, canActivate: [AuthGuardGuard] },
  { path: 'adminpage', component: AdminpageComponent, canActivate: [AuthGuardGuard] },
  { path: 'zapisaniob', component: ZapazeniobqviComponent, canActivate: [AuthGuardGuard] },
  { path: 'mycontacts', component: MyContactsComponent, canActivate: [AuthGuardGuard] },
  { path: 'obshtiuslovia', component: ObshtiusloviqComponent },
  { path: 'olxredirect', component: OlxredirectComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
