<h1 mat-dialog-title>Подробности за контакт </h1>
<div mat-dialog-content>
    <div class="container-fluid">
       
        <div class="row">
            <div class="col">
                Име:
            </div>
            <div class="col">
                
                {{editformcontact.controls['name'].value}}
            </div>
            
        </div>
        <div class="row mt-1">
            <div class="col">
                Телефон:
            </div>
            <div class="col">
               <a href="tel:{{editformcontact.controls['tel'].value}}" >{{editformcontact.controls['tel'].value}}</a>
            </div>
            
        </div>
        <div class="row mt-1">
            <div class="col">
                Имейл:
            </div>
            <div class="col">
                {{editformcontact.controls['email'].value}}
            </div>
            
        </div>
        <div class="row mt-1">
           
            <div class="col">
                Статус:
            </div>
            <div class="col">
                {{editformcontact.controls['status'].value}}
            </div>
        </div>
        <div class="row mt-2">
            <div class="col mt-1">
                <button mat-raised-button (click)="editC = !editC">Редактирай контакт</button>
            </div>
            <div class="col mt-1 mb-1">
                <button mat-raised-button (click)="deletecontact()">Изтрий контакт</button>
            </div>
            
        </div>
        <div class="row mt-1 collapse" [ngbCollapse]="editC"  >
                            
            <div class="col m-1">
                <form [formGroup]="editformcontact">
                    <div class="row">
                        <label >Име:</label>
                       <input class="form-control" type="text" formControlName="name"> 
                    </div>
                    <div class="row">
                        <label >Телефон:</label>
                       <input class="form-control" type="text" formControlName="tel"> 
                    </div>
                    <div class="row">
                        <label >Имейл:</label>
                       <input class="form-control" type="text" formControlName="email"> 
                    </div>
                    <div class="row">
                        <label >Статус:</label>
                        <select class="form-control"   formControlName="status"  >
                            <option selected value="" >Избери:</option>       
                            <option  value="Собственик">Собственик</option>
                            <option  value="Купувач">Купувач</option>
                            <option  value="Трети лица">Трети лица</option>
                            </select> 
                    </div>
                    <div class="row">
                        <button mat-raised-button (click)="savecontact()">Запази контакта</button>
                    </div>
                </form>   
                        
                    
            </div>
                
            
        </div>
    </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Затвори</button>
  
  <button mat-button mat-dialog-close cdkFocusInitial>ОК</button>
</div>
