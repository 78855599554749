import { AddImotContactComponent } from './../../dialogs/add-imot-contact/add-imot-contact.component';
import { ContactDialogComponent } from './../../dialogs/contact-dialog/contact-dialog.component';
import { FormGroup, FormControl } from '@angular/forms';
import { RequestsService } from './../../services/requests.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { GalleryImage } from 'ngx-doe-gallery';
import { ActivatedRoute, Params } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-imotview',
  templateUrl: './imotview.component.html',
  styleUrls: ['./imotview.component.css'],
})
export class ImotviewComponent implements OnInit {
  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    sessionStorage.setItem('backbutton', 'true');
  }

  constructor(private req: RequestsService, private route: ActivatedRoute, private dialogContact: MatDialog) {}
  imot: any;
  param: any;
  images: any[] = [];
  pictures: any[] = [];
  brpics: any;
  cenanakvadrat: any;
  itsfavouriteimot = false;
  belejkacollapse = true;
  editbelejka = true;
  contactcolapse = true;
  data: any;
  belejkaText: any;
  belejkaChas: any;
  belejkaData: any;
  noBelejka = false;
  usercontacts: any[] = [];

  editformbelejka = new FormGroup({
    text: new FormControl(''),
    chas: new FormControl(''),
    data: new FormControl(''),
  });

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.route.params.subscribe(
      (params: Params) => {
        this.param = params.id;
      },
      (err) => {
        console.log(err);
      }
    );

    this.req.getfromdbbyid(this.param).subscribe(
      (result: any) => {
        let userid = localStorage.getItem('userid');
        this.req.getuserfav(userid).subscribe(
          (res: any) => {
            let arr: any[] = [];
            arr = res;

            arr.forEach((element) => {
              if (element.imotid == this.param) {
                this.itsfavouriteimot = true;
                this.belejkaText = element.belejkaText;
                this.belejkaChas = element.belejkaChas;

                if (element.belejkaData == 'null' || element.belejkaData == '' || element.belejkaData == null) {
                  this.belejkaData = '';
                } else {
                  this.belejkaData = new Date(element.belejkaData).toLocaleDateString();
                }

                this.editformbelejka.setValue({
                  text: element.belejkaText,
                  chas: element.belejkaChas,
                  data: element.belejkaData,
                });
              }
            });

            if (this.belejkaText == null || this.belejkaText == '') {
              this.noBelejka = true;
            }

            this.req.getuserimotcontact(userid, this.param).subscribe(
              (result3: any) => {
                this.usercontacts = result3;
              },
              (err) => {
                console.log(err);
              }
            );
          },
          (err) => {
            console.log(err);
          }
        );

        this.imot = result[0];
        let split = this.imot.allpictures.split(',');
        let split2 = JSON.parse(this.imot.allpictures);

        this.brpics = split2.length;
        this.pictures = split2;
        this.images = [];

        let pr = Number(this.imot.price);
        if (!isNaN(pr)) {
          this.imot.price = Number(this.imot.price);
          let cenkv = Number(this.imot.price) / Number(this.imot.quadrature);
          this.cenanakvadrat = cenkv.toFixed(2);
          this.imot.price = pr.toLocaleString();
        }

        for (let i = 0; i < this.brpics; i++) {
          this.images.push(new GalleryImage(this.pictures[i], this.pictures[i]));
        }
      },
      (err) => {
        console.log(err);
      }
    );

    this.images = [
      new GalleryImage(''),

      //cdn3.focus.bg/imot/photosimotbg/1/872/big/1j160494502648872_ym.jpg,//cdn3.focus.bg/imot/photosimotbg/1/872/big/1j160494502648872_Hh.jpg,//cdn3.focus.bg/imot/photosimotbg/1/872/big/1j160494502648872_eP.jpg,//cdn3.focus.bg/imot/photosimotbg/1/872/big/1j160494502648872_Pa.jpg
    ];
  }

  checkImot() {
    console.log(this.imot.id);
    this.req.checkImotForReal(this.imot.id).subscribe(
      (res: any) => {
        console.log('ok');
        window.location.reload();
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  openContact(id: any) {
    let userid = localStorage.getItem('userid');
    let dialogOtg = this.dialogContact.open(ContactDialogComponent, { data: this.usercontacts[id] });
    dialogOtg.afterClosed().subscribe((result) => {
      this.req.getuserimotcontact(userid, this.param).subscribe(
        (result3: any) => {
          this.usercontacts = result3;
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }
  addContact() {
    let dialogAdd = this.dialogContact.open(AddImotContactComponent, { data: this.param, disableClose: true });
    dialogAdd.afterClosed().subscribe((result) => {
      let userid = localStorage.getItem('userid');
      this.req.getuserimotcontact(userid, this.param).subscribe(
        (result3: any) => {
          this.usercontacts = result3;
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }
  savebelejka() {
    let userid = localStorage.getItem('userid');
    let imotid = this.param;
    let text = this.editformbelejka.get('text')?.value;
    let time = this.editformbelejka.get('chas')?.value;
    let date = this.editformbelejka.get('data')?.value;

    this.req.setuserfavbelejka(userid, imotid, text, time, date).subscribe(
      (result: any) => {
        this.editbelejka = true;
        this.noBelejka = false;
        console.log(result);
        this.belejkaText = text;
        this.belejkaChas = time;
        console.log(date);

        if (date == 'null' || date == '' || date == null) {
          this.belejkaData = '';
        } else {
          this.belejkaData = new Date(date).toLocaleDateString();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  deletebelejka() {
    let userid = localStorage.getItem('userid');
    let imotid = this.param;
    let text = '';
    let time = '';
    let date = '';

    this.req.setuserfavbelejka(userid, imotid, text, time, date).subscribe(
      (result: any) => {
        this.editbelejka = true;
        console.log(result);
        this.belejkaText = text;
        this.belejkaChas = time;
        this.belejkaData = date;
        this.editformbelejka.setValue({
          text: text,
          chas: time,
          data: date,
        });
        this.noBelejka = true;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  putimotfav() {
    let userid = localStorage.getItem('userid');
    let imotid = this.param;
    this.req.adduserfav(userid, imotid).subscribe(
      (res: any) => {
        //console.log(res);
        this.itsfavouriteimot = true;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  removefromfav() {
    let userid = localStorage.getItem('userid');
    let imotid = this.param;
    this.req.removeuserfav(userid, imotid).subscribe(
      (res: any) => {
        //console.log(res);
        this.itsfavouriteimot = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
