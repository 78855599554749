import { SearchcatService } from 'src/app/services/searchcat.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestsService } from 'src/app/services/requests.service';
import { AddImotContactComponent } from '../add-imot-contact/add-imot-contact.component';
import { ContactsTipRaionModalComponent } from '../contacts-tip-raion-modal/contacts-tip-raion-modal.component';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.css'],
})
export class AddContactComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private req: RequestsService,
    private dialogRef: MatDialogRef<AddContactComponent>,
    private catGet: SearchcatService,
    private dialog: MatDialog
  ) {}

  addformcontact = new FormGroup({
    name: new FormControl(''),
    tel: new FormControl(''),
    email: new FormControl(''),
    grad: new FormControl(''),
    raion: new FormControl(''),
    tip: new FormControl(''),
    kvOt: new FormControl(''),
    kvDo: new FormControl(''),
  });
  towns: any = [];
  raions: any = [];
  tipImots: any = [];

  ngOnInit(): void {
    this.towns = this.catGet.getgradoblast();
    this.tipImots = this.catGet.gettypeimot();
  }
  openRaionModal() {
    if (this.addformcontact.get('grad')?.value == '') {
      window.alert('Не сте избрали област или град');
    } else {
      let dialogOtg = this.dialog.open(ContactsTipRaionModalComponent, {
        data: { test: this.raions, cheked: '', type: 0 },
      });
      dialogOtg.afterClosed().subscribe(
        (result) => {
          // console.log(result);
          this.addformcontact.patchValue({
            raion: result,
          });
          //this.raions = result;
          //this.someValueIsChanged = true;
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  openTipModal() {
    let dialogOtg = this.dialog.open(ContactsTipRaionModalComponent, {
      data: { test: this.tipImots, cheked: '', type: 1 },
    });
    dialogOtg.afterClosed().subscribe(
      (result) => {
        // console.log(result);
        this.addformcontact.patchValue({
          tip: result,
        });
        // this.tipImots = result;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  valueChanged() {
    this.raions = this.catGet.getRaionsFromGrad(this.addformcontact.get('grad')?.value);
  }
  savecontact() {
    if (this.addformcontact.get('kvOt')?.value == null || this.addformcontact.get('kvOt')?.value == undefined)
      this.addformcontact.patchValue({ kvOt: '' });
    if (this.addformcontact.get('kvDo')?.value == null || this.addformcontact.get('kvDo')?.value == undefined)
      this.addformcontact.patchValue({ kvDo: '' });
    let payload = {
      userid: localStorage.getItem('userid'),
      contactName: this.addformcontact.get('name')?.value,
      contactPhone: this.addformcontact.get('tel')?.value,
      contactEmail: this.addformcontact.get('email')?.value,
      contactGrad: this.addformcontact.get('grad')?.value,
      contactRaion: this.addformcontact.get('raion')?.value,
      contactTip: this.addformcontact.get('tip')?.value,
      contactKvOt: this.addformcontact.get('kvOt')?.value,
      contactKvDo: this.addformcontact.get('kvDo')?.value,
    };
    console.log(this.addformcontact.get('kvDo')?.value);

    this.req.adduserimotcontact(payload).subscribe(
      (result: any) => {
        console.log(result);
        this.dialogRef.close();
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
