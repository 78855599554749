import { RequestsService } from './../../services/requests.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-showobqvi',
  templateUrl: './showobqvi.component.html',
  styleUrls: ['./showobqvi.component.css']
})
export class ShowobqviComponent implements OnInit {
  

  constructor( private req:RequestsService, private route:ActivatedRoute) { }

  obqvi:any={
    "title":[],
    "link":[],
    "img":[],
    "tel":[],
    'price':[],
    "chasnititle":[],
    "chasnilink":[],
    "chasniimg":[],
    "chasnitel":[],
    'chasniprice':[]
  };
  param: any;
  allactive = true;
  chasniactive = false;
  newcontent=true;
  pleasewait = true;
  povtoreniq =0;
  map!:any;
  lastparamimotbg=0;
  hasChasna=false;
  private subscriptions = new Subscription();

  ngOnInit(): void {

    
    this.route.params.subscribe((params:Params)=>{
      
      
      this.param = params.site;
    },(err)=>{
      console.log(err);
    });

    if(this.param =='alobg'){
      
      this.promisefnforsubscription(1,'1');
      this.map = "ALO.bg"
    }
    if(this.param=="imotbg"){
      this.promisefnforsubscription(3,1);
      this.map = "imot.bg"
    }
    if(this.param=="fromdb"){
      this.promisefnforsubscription(4,1);
      this.map = "База данни"
    }


  }

 

  promisefnforsubscription(val:number,param:any){
    
    switch(val){
      case 1:{
       

        
          this.subscriptions.add(this.req.getfromalo(param).subscribe((result1:any)=>{
            this.obqvi.title = result1[0];
            this.obqvi.link = result1[1];
            this.obqvi.img = result1[2];
            
            this.obqvi.chasnititle = result1[3];
            this.obqvi.chasnilink = result1[4];
            this.obqvi.chasniimg = result1[5];
            
             if(result1[0].length>1){
               
               
              this.newcontent = true;
             }else{
              this.newcontent = false;
             }
            
             
            
          
          },(err)=>{
            this.newcontent = false;
            console.log(err);
            
          }));
          
      
            let promise = new Promise((resolve,reject)=>{
              
            
              
            setTimeout(() => {
              
              
              if(!this.newcontent){
                reject("nqma text");
              }else{
                resolve("ima text")
              }
            }, 5000);
            });
        
            promise.then((res) =>{
              console.log(res)
              this.subscriptions.unsubscribe();
              this.pleasewait = false;
              
            },(rej)=>{
              console.log(rej)
              this.promisefnforsubscription(val,param);
              this.pleasewait = true;
              this.povtoreniq ++;
              if(this.povtoreniq==20){
                //this.nqmavruzkasdfz=true;
                this.povtoreniq=0;
                this.subscriptions.unsubscribe();
                this.pleasewait = false;
                //this.text='nqma';
              }
              
            });
          
        

      }
      break;
      case 2:{
        this.req.getfromimotbg('1').subscribe((result:any)=>{
          this.obqvi.title = result[0];
          this.obqvi.link = result[1];
          this.obqvi.img = result[2];
          
          this.obqvi.tel = result[3];
          this.obqvi.chasnititle = result[4];
          this.obqvi.chasnilink = result[5];
          this.obqvi.chasniimg = result[6];
          if(result[0].length>1){
            
            
            this.newcontent = true;
           }else{
            this.newcontent = false;
           }
        },(err)=>{
          this.newcontent = false;
          console.log(err);
          
        })
        
    
          let promise2 = new Promise((resolve,reject)=>{
            
          
            
          setTimeout(() => {
            
          
          
            if(!this.newcontent){
              reject("nqma text");
            }else{
              resolve("ima text")
            }
          }, 35000);
          });
      
          promise2.then((res) =>{
           // console.log(res)
            this.subscriptions.unsubscribe();
            this.pleasewait = false;
            
          },(rej)=>{
          //  console.log(rej)
            this.promisefnforsubscription(val,param);
            this.pleasewait = true;
            this.povtoreniq ++;
            if(this.povtoreniq==20){
              //this.nqmavruzkasdfz=true;
              this.povtoreniq=0;
              this.subscriptions.unsubscribe();
              this.pleasewait = false;
              //this.text='nqma';
            }
            
          });

      }
      break;
      case 3:{
        this.lastparamimotbg=param;
        this.req.getapifromimot(param).subscribe((result:any)=>{
         
          let link = 'https://www.imot.bg/pcgi/imot.cgi?act=5&adv='
          for(let i=0;i<result.length;i++){
            this.obqvi.title.push(result[i].type_home+','+result[i].quadrature+' '+result[i].metric+','+result[i].town+','+result[i].raion)
            this.obqvi.link.push(link+result[i].id);
            this.obqvi.img.push(result[i].pictures[0])
            this.obqvi.tel.push(result[i].phone)
            this.obqvi.price.push(result[i].price+" "+result[i].currency)
            
            
            //console.log(result[i].agency_id+"s noimer"+i);
            if(!result[i].agency_id){
            this.obqvi.chasnititle.push(result[i].type_home+','+result[i].quadrature+' '+result[i].metric+','+result[i].town+','+result[i].raion)
            this.obqvi.chasnilink.push(link+result[i].id);
            this.obqvi.chasniimg.push(result[i].pictures[0])
            this.obqvi.chasnitel.push(result[i].phone)
            this.obqvi.chasniprice.push(result[i].price+" "+result[i].currency)
            
              
            }
           
            
          }
          
          //console.log(result[0].id.length);
          //console.log(this.obqvi.chasnititle.length>0);
          
          if(this.obqvi.chasnititle.length>0){
            this.hasChasna = true;
          }
          
          if(this.obqvi.chasnititle.length==0){
            this.hasChasna = false;
          }
          
          
          if(result.length>0){
            
            
            this.newcontent = true;
           }else{
             
            this.newcontent = false;
           }
        },(err)=>{
          this.newcontent = false;
          console.log(err);
          
        })
        
    
          let promise2 = new Promise((resolve,reject)=>{
            
          
            
          setTimeout(() => {
            
          
          
            if(!this.newcontent){
              reject("nqma text");
              
            }else{
              resolve("ima text")
            }
          }, 1500);
          });
      
          promise2.then((res) =>{
           // console.log(res)
            this.subscriptions.unsubscribe();
            this.pleasewait = false;
            
          },(rej)=>{
          //  console.log(rej)
            this.promisefnforsubscription(val,param);
            this.pleasewait = true;
            this.povtoreniq ++;
            if(this.povtoreniq==20){
              //this.nqmavruzkasdfz=true;
              this.povtoreniq=0;
              this.subscriptions.unsubscribe();
              this.pleasewait = false;
              //this.text='nqma';
            }
            
          });
      }
      break;
      case 4:{
        this.req.getfromdb().subscribe((result:any)=>{
          console.log(result);
          
        },(err)=>{
          console.log(err);
          
        })
      }
      break;
    }
    
   

    
   
  
  }
  chasniobqvi(){
    if(this.param =='alobg'){
    this.allactive = false;
    this.chasniactive = true;
    }
    if(this.param =='imotbg'){
      
      this.allactive = false;
      this.chasniactive = true;
      
      }
  }
  allobqvi(){
    if(this.param =='alobg'){
    this.chasniactive = false;
    this.allactive = true;
    }
    if(this.param =='imotbg'){
      this.chasniactive = false;
      this.allactive = true;
      }
  }
  nextPage(){
    if(this.param =='alobg'){
      this.req.getfromalo('2').subscribe((result:any)=>{

        this.obqvi.title = result[0];
        this.obqvi.link = result[1];
        this.obqvi.img = result[2];
        this.obqvi.chasnititle = result[3];
        this.obqvi.chasnilink = result[4];
        this.obqvi.chasniimg = result[5];

        window.scrollTo(0,0);
        
      },(err)=>{
        console.log(err);
        
      })
    }
    if(this.param =='imotbg'){
      
      this.obqvi={
        "title":[],
        "link":[],
        "img":[],
        "tel":[],
        'price':[],
        "chasnititle":[],
        "chasnilink":[],
        "chasniimg":[],
        "chasnitel":[],
        'chasniprice':[]
      };
      
      this.promisefnforsubscription(3,this.lastparamimotbg+1);
      this.map = "imot.bg"
      
      this.pleasewait = true;
      
      
      setTimeout(() => {
       
        
        if(this.chasniactive){
          if(!this.hasChasna){
            
            this.nextPage();
          }else{
            
          }
        }
      }, 4000);
      if(this.obqvi.chasnititle.length==0){
        this.pleasewait = true;
      }else{
        this.pleasewait = false;
      }
      
      

    }
  }
  prevPage(){
    if(this.param =='alobg'){
      this.req.getfromalo('1').subscribe((result:any)=>{

        this.obqvi.title = result[0];
        this.obqvi.link = result[1];
        this.obqvi.img = result[2];
        this.obqvi.chasnititle = result[3];
        this.obqvi.chasnilink = result[4];
        this.obqvi.chasniimg = result[5];
        window.scrollTo(0,0);
      },(err)=>{
        console.log(err);
        
      })
    }
    if(this.param =='imotbg'){
      if(this.lastparamimotbg==1){
        return;
      }
      this.obqvi={
        "title":[],
        "link":[],
        "img":[],
        "tel":[],
        'price':[],
        "chasnititle":[],
        "chasnilink":[],
        "chasniimg":[],
        "chasnitel":[],
        'chasniprice':[]
      };
      
      this.promisefnforsubscription(3,this.lastparamimotbg-1);
      this.map = "imot.bg"
      setTimeout(() => {
        
        if(this.chasniactive){
          if(!this.hasChasna){
            this.prevPage();
          }else{
            this.pleasewait = false;
          }
        }
      }, 4000);
    }
  }
}
