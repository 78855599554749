import { RaionmodalComponent } from './../../dialogs/raionmodal/raionmodal.component';
import { SearchcatService } from './../../services/searchcat.service';
import { RequestsService } from './../../services/requests.service';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { element } from 'protractor';
import { MatDialog } from '@angular/material/dialog';
import { TipImotmodalComponent } from 'src/app/dialogs/tip-imotmodal/tip-imotmodal.component';
import { IsMobileService } from 'src/app/services/is-mobile.service';
import { ChoseContactSearchComponent } from 'src/app/dialogs/chose-contact-search/chose-contact-search.component';

@Component({
  selector: 'app-show-dbobqvi',
  templateUrl: './show-dbobqvi.component.html',
  styleUrls: ['./show-dbobqvi.component.css'],
})
export class ShowDbobqviComponent implements OnInit {
  constructor(
    private req: RequestsService,
    private cat: SearchcatService,
    private dialog: MatDialog,
    private mobile: IsMobileService
  ) {}

  colapsefiltermobile = true;
  formobile = false;
  allactive = true;
  chasniactive = false;
  pleasewait = true;
  povtoreniq = 0;
  obqviall: any[] = [];
  obqvisort: any[] = [];
  obqvichasni: any[] = [];
  page!: number;
  scrollposition: any;
  typeimotcat!: any;
  gradoblastcat!: any;
  raioncat!: any;
  privateFilter: any;
  userimotsid: any[] = [];
  chosenRaioni: any;
  chosenTipImot: any;
  forContactsearch: any[] = [];

  tyrsachkadanni = new FormGroup({
    typeimot: new FormControl(''),
    typeimot0: new FormControl(''),
    typeimot1: new FormControl(''),
    typeimot2: new FormControl(''),
    typeimot3: new FormControl(''),
    typeimot4: new FormControl(''),
    oblastgrad: new FormControl(''),
    raion: new FormControl(''),
    raion0: new FormControl(''),
    raion1: new FormControl(''),
    raion2: new FormControl(''),
    raion3: new FormControl(''),
    raion4: new FormControl(''),
    raion5: new FormControl(''),
    raion6: new FormControl(''),
    tipobqva: new FormControl(''),
    telefon: new FormControl(''),
    sortby: new FormControl(''),
    kv_ot: new FormControl(''),
    kv_do: new FormControl(''),
  });
  @HostListener('window:scroll', ['$event']) onScrollEvent($event: any) {
    //  sessionStorage.scrollPos = window.scrollY
    this.scrollposition = window.scrollY;
    // console.log(this.scrollposition);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    event.target.innerWidth;

    if (event.target.innerWidth < 990) {
      this.formobile = true;
    } else {
      this.formobile = false;
    }
  }
  @ViewChild('Forscroll') heightofdiv!: ElementRef;

  ngOnInit(): void {
    if (this.mobile.isMobile()) {
      this.formobile = true;
    } else {
      this.formobile = false;
    }

    this.typeimotcat = this.cat.gettypeimot();
    this.gradoblastcat = this.cat.getgradoblast();

    if (sessionStorage.getItem('tipObqva') == null) sessionStorage.setItem('tipObqva', '');
    if (sessionStorage.getItem('typeimot') == null) sessionStorage.setItem('typeimot', '');

    if (sessionStorage.getItem('typeimot0') == null) sessionStorage.setItem('typeimot0', '');
    if (sessionStorage.getItem('typeimot1') == null) sessionStorage.setItem('typeimot1', '');
    if (sessionStorage.getItem('typeimot2') == null) sessionStorage.setItem('typeimot2', '');
    if (sessionStorage.getItem('typeimot3') == null) sessionStorage.setItem('typeimot3', '');
    if (sessionStorage.getItem('typeimot4') == null) sessionStorage.setItem('typeimot4', '');

    if (sessionStorage.getItem('oblastgrad') == null) sessionStorage.setItem('oblastgrad', '');
    if (sessionStorage.getItem('raion') == null) sessionStorage.setItem('raion', '');

    if (sessionStorage.getItem('raion0') == null) sessionStorage.setItem('raion0', '');
    if (sessionStorage.getItem('raion1') == null) sessionStorage.setItem('raion1', '');
    if (sessionStorage.getItem('raion2') == null) sessionStorage.setItem('raion2', '');
    if (sessionStorage.getItem('raion3') == null) sessionStorage.setItem('raion3', '');
    if (sessionStorage.getItem('raion4') == null) sessionStorage.setItem('raion4', '');
    if (sessionStorage.getItem('raion5') == null) sessionStorage.setItem('raion5', '');
    if (sessionStorage.getItem('raion6') == null) sessionStorage.setItem('raion6', '');

    if (sessionStorage.getItem('telefon') == null) sessionStorage.setItem('telefon', '');
    if (sessionStorage.getItem('sortby') == null) sessionStorage.setItem('sortby', '0');
    if (sessionStorage.getItem('kv_ot') == null) sessionStorage.setItem('kv_ot', '');
    if (sessionStorage.getItem('kv_do') == null) sessionStorage.setItem('kv_do', '');

    this.tyrsachkadanni.patchValue({
      typeimot: sessionStorage.getItem('typeimot'),
      typeimot0: sessionStorage.getItem('typeimot0'),
      typeimot1: sessionStorage.getItem('typeimot1'),
      typeimot2: sessionStorage.getItem('typeimot2'),
      typeimot3: sessionStorage.getItem('typeimot3'),
      typeimot4: sessionStorage.getItem('typeimot4'),
      oblastgrad: sessionStorage.getItem('oblastgrad'),
      raion: sessionStorage.getItem('raion'),
      raion0: sessionStorage.getItem('raion0'),
      raion1: sessionStorage.getItem('raion1'),
      raion2: sessionStorage.getItem('raion2'),
      raion3: sessionStorage.getItem('raion3'),
      raion4: sessionStorage.getItem('raion4'),
      raion5: sessionStorage.getItem('raion5'),
      raion6: sessionStorage.getItem('raion6'),
      tipobqva: sessionStorage.getItem('tipObqva'),
      telefon: sessionStorage.getItem('telefon'),
      sortby: sessionStorage.getItem('sortby'),
      kv_ot: sessionStorage.getItem('kv_ot'),
      kv_do: sessionStorage.getItem('kv_do'),
    });

    //tova e pod vypros dali da go ima
    this.getraions(this.tyrsachkadanni.get('oblastgrad')?.value);

    let pl = {
      userid: localStorage.getItem('userid'),
      oblastgrad: this.tyrsachkadanni.get('oblastgrad')?.value,
      raion: sessionStorage.getItem('raion'),
      raion0: sessionStorage.getItem('raion0'),
      raion1: sessionStorage.getItem('raion1'),
      raion2: sessionStorage.getItem('raion2'),
      raion3: sessionStorage.getItem('raion3'),
      raion4: sessionStorage.getItem('raion4'),
      raion5: sessionStorage.getItem('raion5'),
      raion6: sessionStorage.getItem('raion6'),
      tipObqva: this.tyrsachkadanni.get('tipobqva')?.value,
      typeimot: sessionStorage.getItem('typeimot'),
      typeimot0: sessionStorage.getItem('typeimot0'),
      typeimot1: sessionStorage.getItem('typeimot1'),
      typeimot2: sessionStorage.getItem('typeimot2'),
      typeimot3: sessionStorage.getItem('typeimot3'),
      typeimot4: sessionStorage.getItem('typeimot4'),
      telefon: this.tyrsachkadanni.get('telefon')?.value,
      sortby: this.tyrsachkadanni.get('sortby')?.value,
      kv_ot: this.tyrsachkadanni.get('kv_ot')?.value,
      kv_do: this.tyrsachkadanni.get('kv_do')?.value,
    };

    if (sessionStorage.getItem('chosenTipImot') != null) {
      let m = sessionStorage.getItem('chosenTipImot');
      let t = m?.split(',');
      this.chosenTipImot = t;
    }
    if (sessionStorage.getItem('chosenRaioni') != null) {
      let m = sessionStorage.getItem('chosenRaioni');
      let t = m?.split(',');
      this.chosenRaioni = t;
    }

    this.page = environment.lastusedpage;

    this.req.getfilteredimots(pl).subscribe(
      (result: any) => {
        this.obqvisort = result;

        this.pleasewait = false;
        setTimeout(() => {
          //console.log(environment.scrollpos);

          window.scrollTo(0, environment.scrollpos);
        }, 200);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  savescrollpos() {
    environment.scrollpos = this.scrollposition;
  }

  openContactsfilter() {
    let t = 'test12334';
    let dialogOtg = this.dialog.open(ChoseContactSearchComponent, {
      data: { test: t },
    });
    dialogOtg.afterClosed().subscribe((result) => {
      this.clearfilter();
      if (result == undefined) return;
      if (result.ok != '1') return;
      let data = result.data;
      if (data.oblastGrad === null) {
        data.oblastGrad = '';
      }
      if (data.raion === null) {
        data.raion = '';
      }
      if (data.tipImot === null) {
        data.tipImot = '';
      }
      if (data.kvadraturaOt === null) {
        data.kvadraturaOt = '';
      }
      if (data.kvadraturaDo === null) {
        data.kvadraturaDo = '';
      }

      //console.log(data.raion);
      if (data.raion == null || data.raion == undefined) data.raion = '';
      let arrRaion = data.raion.split(',');
      // console.log(arrRaion.length);
      if (arrRaion.length > 1) {
        sessionStorage.setItem('raion', arrRaion[0]);
        for (let i = 1; i < arrRaion.length; i++) {
          sessionStorage.setItem(`raion${i - 1}`, arrRaion[i]);
        }
        this.chosenRaioni = arrRaion;
      } else {
        sessionStorage.setItem('raion', data.raion);
        this.chosenRaioni = [arrRaion];
      }
      sessionStorage.setItem('chosenRaioni', data.raion);

      if (data.tipImot == null || data.tipImot == undefined) data.tipImot = '';
      let arrTip = data.tipImot.split(',');
      // console.log(arrTip.length);
      if (arrTip.length > 1) {
        sessionStorage.setItem('typeimot', arrTip[0]);
        for (let j = 1; j < arrTip.length; j++) {
          sessionStorage.setItem(`typeimot${j - 1}`, arrTip[j]);
        }
        this.chosenTipImot = arrTip;
      } else {
        sessionStorage.setItem('typeimot', data.tipImot);
        this.chosenTipImot = [arrTip];
      }
      sessionStorage.setItem('chosenTipImot', data.tipImot);
      sessionStorage.setItem('oblastgrad', data.oblastGrad);
      sessionStorage.setItem('kv_ot', data.kvadraturaOt);
      sessionStorage.setItem('kv_do', data.kvadraturaDo);
      this.getraions(data.oblastGrad);
    });
  }
  openTipImotModal() {
    let dialogOtg = this.dialog.open(TipImotmodalComponent, {
      data: { type: this.typeimotcat, from: 'all' },
    });
    dialogOtg.afterClosed().subscribe((result) => {
      //console.log(result);

      if (result != 'false') {
        this.chosenTipImot = result;
        sessionStorage.setItem('chosenTipImot', result);
        this.filter(0);
      }
    });
  }

  openRaionmodal() {
    if (this.tyrsachkadanni.get('oblastgrad')?.value == '') {
      window.alert('Не сте избрали област или град');
    } else {
      let dialogOtg = this.dialog.open(RaionmodalComponent, {
        data: { type: this.raioncat, from: 'all' },
      });
      dialogOtg.afterClosed().subscribe((result) => {
        // console.log(result);

        if (result != 'false') {
          //console.log(result);

          this.chosenRaioni = result;
          sessionStorage.setItem('chosenRaioni', result);
          this.filter(0);
        }
      });
    }
  }

  savefav(imotid: any) {
    let userid = localStorage.getItem('userid');

    this.req.adduserfav(userid, imotid).subscribe(
      (res: any) => {
        //console.log(res);
        this.obqvisort.forEach((ele) => {
          if (ele.id == imotid) {
            ele.favour = true;
          }
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }
  deletefav(imotid: any) {
    let userid = localStorage.getItem('userid');

    this.req.removeuserfav(userid, imotid).subscribe(
      (res: any) => {
        //console.log(res);
        this.obqvisort.forEach((ele) => {
          if (ele.id == imotid) {
            ele.favour = false;
          }
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  privatefilter() {
    this.clearfilter();
    this.req.getuserfilter(localStorage.getItem('userid')).subscribe(
      (res) => {
        this.privateFilter = res;

        sessionStorage.setItem('tipObqva', this.privateFilter.filterTipObqva);
        sessionStorage.setItem('oblastgrad', this.privateFilter.filterGrad);

        sessionStorage.setItem('raion', this.privateFilter.filterRaion);
        sessionStorage.setItem('typeimot', this.privateFilter.filterTipImot);
        this.getraions(this.privateFilter.filterGrad);
        sessionStorage.setItem('chosenRaioni', this.privateFilter.filterRaion);
        sessionStorage.setItem('chosenTipImot', this.privateFilter.filterTipImot);
        this.chosenRaioni = [this.privateFilter.filterRaion];
        this.chosenTipImot = [this.privateFilter.filterTipImot];
      },
      (err) => {
        console.log(err);
      }
    );
  }
  newSearch() {
    this.clearfilter();
    this.filter(0);
  }
  clearfilter() {
    this.tyrsachkadanni.patchValue({
      typeimot: '',
      typeimot0: '',
      typeimot1: '',
      typeimot2: '',
      typeimot3: '',
      typeimot4: '',
      oblastgrad: '',
      raion: '',
      raion0: '',
      raion1: '',
      raion2: '',
      raion3: '',
      raion4: '',
      raion5: '',
      raion6: '',
      tipobqva: '',
      telefon: '',
      sortby: '0',
      kv_ot: '',
      kv_do: '',
    });
    sessionStorage.setItem('oblastgrad', '');
    sessionStorage.setItem('raion', '');
    sessionStorage.setItem('raion0', '');
    sessionStorage.setItem('raion1', '');
    sessionStorage.setItem('raion2', '');
    sessionStorage.setItem('raion3', '');
    sessionStorage.setItem('raion4', '');
    sessionStorage.setItem('raion5', '');
    sessionStorage.setItem('raion6', '');
    sessionStorage.setItem('typeimot', '');
    sessionStorage.setItem('typeimot0', '');
    sessionStorage.setItem('typeimot1', '');
    sessionStorage.setItem('typeimot2', '');
    sessionStorage.setItem('typeimot3', '');
    sessionStorage.setItem('typeimot4', '');
    sessionStorage.setItem('telefon', '');
    sessionStorage.setItem('tipObqva', '');
    sessionStorage.setItem('sortby', '0');
    this.chosenTipImot = [];
    this.chosenRaioni = [];
    sessionStorage.setItem('chosenTipImot', '');
    sessionStorage.setItem('chosenRaioni', '');
    sessionStorage.setItem('kv_ot', '');
    sessionStorage.setItem('kv_do', '');
  }
  changeFilter() {
    if (this.tyrsachkadanni.get('oblastgrad')?.value != '') {
      let val = this.tyrsachkadanni.get('oblastgrad')?.value;
      val = val.toLowerCase();
      let t = sessionStorage.getItem('oblastgrad');
      let k = t?.toLocaleLowerCase();

      if (k != val) {
        this.tyrsachkadanni.patchValue({
          raion: '',
        });
        this.chosenRaioni = [];
        this.chosenTipImot = [];
        console.log('not going here');

        sessionStorage.setItem('chosenRaioni', '');
        sessionStorage.setItem('chosenTipImot', '');
        sessionStorage.setItem('raion', '');
        sessionStorage.setItem('raion0', '');
        sessionStorage.setItem('raion1', '');
        sessionStorage.setItem('raion2', '');
        sessionStorage.setItem('raion3', '');
        sessionStorage.setItem('raion4', '');
        sessionStorage.setItem('raion5', '');
        sessionStorage.setItem('raion6', '');
        sessionStorage.setItem('typeimot', '');
        sessionStorage.setItem('typeimot0', '');
        sessionStorage.setItem('typeimot1', '');
        sessionStorage.setItem('typeimot2', '');
        sessionStorage.setItem('typeimot3', '');
        sessionStorage.setItem('typeimot4', '');
        sessionStorage.setItem('telefon', '');
        sessionStorage.setItem('kv_ot', '');
        sessionStorage.setItem('kv_do', '');
        sessionStorage.setItem('sortby', '0');
        this.tyrsachkadanni.patchValue({
          kv_ot: '',
          kv_do: '',
          telefon: '',
        });
      }
      sessionStorage.setItem('oblastgrad', this.tyrsachkadanni.get('oblastgrad')?.value);
    } else {
      sessionStorage.setItem('oblastgrad', '');
    }

    if (this.tyrsachkadanni.get('tipobqva')?.value != '') {
      sessionStorage.setItem('tipObqva', this.tyrsachkadanni.get('tipobqva')?.value);
    } else {
      sessionStorage.setItem('tipObqva', '');
    }

    if (this.tyrsachkadanni.get('telefon')?.value != '') {
      sessionStorage.setItem('telefon', this.tyrsachkadanni.get('telefon')?.value);
    } else {
      sessionStorage.setItem('telefon', '');
    }

    if (this.tyrsachkadanni.get('sortby')?.value != '') {
      sessionStorage.setItem('sortby', this.tyrsachkadanni.get('sortby')?.value);
    } else {
      sessionStorage.setItem('sortby', '');
    }

    if (this.tyrsachkadanni.get('kv_ot')?.value != '') {
      sessionStorage.setItem('kv_ot', this.tyrsachkadanni.get('kv_ot')?.value);
    }
    if (this.tyrsachkadanni.get('kv_ot')?.value == null) {
      sessionStorage.setItem('kv_ot', '');
    }

    if (this.tyrsachkadanni.get('kv_do')?.value != '') {
      sessionStorage.setItem('kv_do', this.tyrsachkadanni.get('kv_do')?.value);
    }
    if (this.tyrsachkadanni.get('kv_do')?.value == null) {
      sessionStorage.setItem('kv_do', '');
    }
    this.getraions(sessionStorage.getItem('oblastgrad'));
  }
  filter(getraion: Number) {
    let pl = {
      userid: localStorage.getItem('userid'),
      //oblastgrad: this.tyrsachkadanni.get('oblastgrad')?.value,
      oblastgrad: sessionStorage.getItem('oblastgrad'),
      raion: sessionStorage.getItem('raion'),
      raion0: sessionStorage.getItem('raion0'),
      raion1: sessionStorage.getItem('raion1'),
      raion2: sessionStorage.getItem('raion2'),
      raion3: sessionStorage.getItem('raion3'),
      raion4: sessionStorage.getItem('raion4'),
      raion5: sessionStorage.getItem('raion5'),
      raion6: sessionStorage.getItem('raion6'),
      //tipObqva: this.tyrsachkadanni.get('tipobqva')?.value,
      tipObqva: sessionStorage.getItem('tipObqva'),
      typeimot: sessionStorage.getItem('typeimot'),
      typeimot0: sessionStorage.getItem('typeimot0'),
      typeimot1: sessionStorage.getItem('typeimot1'),
      typeimot2: sessionStorage.getItem('typeimot2'),
      typeimot3: sessionStorage.getItem('typeimot3'),
      typeimot4: sessionStorage.getItem('typeimot4'),
      // telefon: this.tyrsachkadanni.get('telefon')?.value,
      telefon: sessionStorage.getItem('telefon'),
      // sortby: this.tyrsachkadanni.get('sortby')?.value,
      sortby: sessionStorage.getItem('sortby'),
      // kv_ot: this.tyrsachkadanni.get('kv_ot')?.value,
      kv_ot: sessionStorage.getItem('kv_ot'),
      //kv_do: this.tyrsachkadanni.get('kv_do')?.value,
      kv_do: sessionStorage.getItem('kv_do'),
    };
    this.tyrsachkadanni.patchValue({
      typeimot: sessionStorage.getItem('typeimot'),
      typeimot0: sessionStorage.getItem('typeimot0'),
      typeimot1: sessionStorage.getItem('typeimot1'),
      typeimot2: sessionStorage.getItem('typeimot2'),
      typeimot3: sessionStorage.getItem('typeimot3'),
      typeimot4: sessionStorage.getItem('typeimot4'),
      oblastgrad: sessionStorage.getItem('oblastgrad'),
      raion: sessionStorage.getItem('raion'),
      raion0: sessionStorage.getItem('raion0'),
      raion1: sessionStorage.getItem('raion1'),
      raion2: sessionStorage.getItem('raion2'),
      raion3: sessionStorage.getItem('raion3'),
      raion4: sessionStorage.getItem('raion4'),
      raion5: sessionStorage.getItem('raion5'),
      raion6: sessionStorage.getItem('raion6'),
      tipobqva: sessionStorage.getItem('tipObqva'),
      telefon: sessionStorage.getItem('telefon'),
      sortby: sessionStorage.getItem('sortby'),
      kv_ot: sessionStorage.getItem('kv_ot'),
      kv_do: sessionStorage.getItem('kv_do'),
    });

    this.req.getfilteredimots(pl).subscribe(
      (result: any) => {
        this.obqvisort = result;
        this.colapsefiltermobile = true;

        // if (this.formobile == false) {
        //   setTimeout(() => {
        //     this.heightofdiv.nativeElement.scrollIntoView({
        //       behavior: 'smooth',
        //       block: 'center',
        //     });
        //   }, 100);
        // }

        if (getraion == 0) {
          this.page = 1;
          environment.lastusedpage = 1;
        }

        this.pleasewait = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getraions(val: any) {
    val = val.toLowerCase();
    switch (val) {
      case 'област пловдив':
        {
          this.raioncat = this.cat.getPlovdivoblast();
        }
        break;
      case 'град пловдив':
        {
          this.raioncat = this.cat.getPlovdivgrad();
        }
        break;
      case 'град благоевград':
        {
          this.raioncat = this.cat.getBlagoevgradgrad();
        }
        break;
      case 'област благоевград':
        {
          this.raioncat = this.cat.getBlagoevgradoblast();
        }
        break;
      case 'град бургас':
        {
          this.raioncat = this.cat.getBurgasgrad();
        }
        break;
      case 'област бургас':
        {
          this.raioncat = this.cat.getBurgasoblast();
        }
        break;
      case 'град варна':
        {
          this.raioncat = this.cat.getVarnagrad();
        }
        break;
      case 'област варна':
        {
          this.raioncat = this.cat.getVarnaoblast();
        }
        break;
      case 'град велико търново':
        {
          this.raioncat = this.cat.getVelikoTyrnovograd();
        }
        break;
      case 'област велико търново':
        {
          this.raioncat = this.cat.getVelikotyrnovooblast();
        }
        break;
      case 'град видин':
        {
          this.raioncat = this.cat.getVidingrad();
        }
        break;
      case 'област видин':
        {
          this.raioncat = this.cat.getVidinoblast();
        }
        break;
      case 'град враца':
        {
          this.raioncat = this.cat.getVracagrad();
        }
        break;
      case 'област враца':
        {
          this.raioncat = this.cat.getVracaoblast();
        }
        break;
      case 'град габрово':
        {
          this.raioncat = this.cat.getGabrovograd();
        }
        break;
      case 'област габрово':
        {
          this.raioncat = this.cat.getGabrovooblast();
        }
        break;
      case 'град добрич':
        {
          this.raioncat = this.cat.getDobrichgrad();
        }
        break;
      case 'област добрич':
        {
          this.raioncat = this.cat.getDobrichoblast();
        }
        break;
      case 'град кърджали':
        {
          this.raioncat = this.cat.getKurdjaligrad();
        }
        break;
      case 'област кърджали':
        {
          this.raioncat = this.cat.getKurdjalioblast();
        }
        break;
      case 'град кюстендил':
        {
          this.raioncat = this.cat.getKustendilgrad();
        }
        break;
      case 'област кюстендил':
        {
          this.raioncat = this.cat.getKustendiloblast();
        }
        break;
      case 'град ловеч':
        {
          this.raioncat = this.cat.getLovechgrad();
        }
        break;
      case 'област ловеч':
        {
          this.raioncat = this.cat.getLovechoblast();
        }
        break;
      case 'град монтана':
        {
          this.raioncat = this.cat.getMontanagrad();
        }
        break;
      case 'област монтана':
        {
          this.raioncat = this.cat.getMontanaoblast();
        }
        break;
      case 'град пазарджик':
        {
          this.raioncat = this.cat.getPazardjikgrad();
        }
        break;
      case 'област пазарджик':
        {
          this.raioncat = this.cat.getPazardjikoblast();
        }
        break;
      case 'град перник':
        {
          this.raioncat = this.cat.getPernikgrad();
        }
        break;
      case 'област перник':
        {
          this.raioncat = this.cat.getPernikoblast();
        }
        break;
      case 'град плевен':
        {
          this.raioncat = this.cat.getPlevengrad();
        }
        break;
      case 'област плевен':
        {
          this.raioncat = this.cat.getPlevenoblast();
        }
        break;
      case 'град разград':
        {
          this.raioncat = this.cat.getRazgradgrad();
        }
        break;
      case 'област разград':
        {
          this.raioncat = this.cat.getRazgradoblast();
        }
        break;
      case 'град русе':
        {
          this.raioncat = this.cat.getRusegrad();
        }
        break;
      case 'област русе':
        {
          this.raioncat = this.cat.getRuseoblast();
        }
        break;
      case 'град силистра':
        {
          this.raioncat = this.cat.getSilistragrad();
        }
        break;
      case 'област силистра':
        {
          this.raioncat = this.cat.getSilistraoblast();
        }
        break;
      case 'град сливен':
        {
          this.raioncat = this.cat.getSlivengrad();
        }
        break;
      case 'област сливен':
        {
          this.raioncat = this.cat.getSlivenoblast();
        }
        break;
      case 'град смолян':
        {
          this.raioncat = this.cat.getSmolqngrad();
        }
        break;
      case 'област смолян':
        {
          this.raioncat = this.cat.getSmolqnoblast();
        }
        break;
      case 'град софия':
        {
          this.raioncat = this.cat.getSofiagrad();
        }
        break;
      case 'област софия':
        {
          this.raioncat = this.cat.getSofiaoblast();
        }
        break;
      case 'град стара загора':
        {
          this.raioncat = this.cat.getStarazagoragrad();
        }
        break;
      case 'област стара загора':
        {
          this.raioncat = this.cat.getStarazagoraoblast();
        }
        break;
      case 'град търговище':
        {
          this.raioncat = this.cat.getTyrgovishtegrad();
        }
        break;
      case 'област търговище':
        {
          this.raioncat = this.cat.getTyrgovishteoblast();
        }
        break;
      case 'град хасково':
        {
          this.raioncat = this.cat.getHaskovograd();
        }
        break;
      case 'област хасково':
        {
          this.raioncat = this.cat.getHaskovooblast();
        }
        break;
      case 'град шумен':
        {
          this.raioncat = this.cat.getShumengrad();
        }
        break;
      case 'област шумен':
        {
          this.raioncat = this.cat.getShumenoblast();
        }
        break;
      case 'град ямбол':
        {
          this.raioncat = this.cat.getQmbolgrad();
        }
        break;
      case 'област ямбол':
        {
          this.raioncat = this.cat.getQmboloblast();
        }
        break;
    }

    this.filter(1);
  }

  onPageChange(page: number) {
    this.page = page;
    environment.lastusedpage = page;

    window.scrollTo(0, 0);
  }
}
