import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private jwtHelper = new JwtHelperService();
  constructor() {}

  public isAuthenticated(): boolean {
    const token :any = localStorage.getItem('token');
    // Check whether the token is expired and return
    // true or false
    
    return !this.jwtHelper.isTokenExpired(token);
    

  }

  public gettoken(){
    return localStorage.getItem('token');
  }
  public isAdmin():boolean{

    const role = localStorage.getItem('userrole');

    if(role==="admin"){
      return true;
    }else{
      return false;
    }

    
  }
  public isUser():boolean{

    const role = localStorage.getItem('userrole');

    if(role==="manager"){
      return true;
    }
    if(role==="user"){
      return true;
    }
    else{
      return false;
    }

    
  }
 
}
