<div class="container-fluid">
  <app-menu></app-menu>
  <div class="container-fluid" style="top: 66px; background-color: white">
    <div class="row centercont m-2" style="font-weight: bolder; font-size: x-large">
      <!-- Намирате се в {{map}} -->
      Запазени имоти
    </div>
  </div>
  <div class="row">
    <div class="col-lg-2 mb-3 pb-3" style="height: fit-content; height: -moz-fit-content">
      <div class="container-fluid sticky-top" *ngIf="!formobile" style="top: 90px">
        <div class="row blever">
          <div class="col">
            <div class="row centercontent m-1">
              <button class="btn btn-primary m-1" (click)="newSearch()">Ново търсене</button>
            </div>
          </div>
        </div>
        <div class="row blever">
          <div class="col">
            <div class="row centercontent m-1">
              <button class="btn btn-primary m-1" (click)="openContactsfilter()">Филтър по контакт</button>
            </div>
          </div>
        </div>
        <div class="row blever">
          <div class="col">
            <div class="row centercontent m-1">
              <button class="btn btn-primary" (click)="privatefilter()">Личен филтър</button>
              <a routerLink="/userpage" class="m-2">Настрой личен филтър</a>
            </div>
          </div>
        </div>

        <form [formGroup]="tyrsachkadanni">
          <div class="row blever" style="border-radius: 10px">
            <div class="col">
              <div class="row m-1">
                <label> Тип обява:</label>

                <select class="form-control" (change)="changeFilter()" formControlName="tipobqva">
                  <option selectед value="">Всички обяви</option>
                  <option select value="chasna">Само частни обяви</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row blever" style="border-radius: 10px">
            <div class="col">
              <div class="row m-1">
                <label>Област или областен град:</label>
                <!-- <input type="text" class="form-control" #y (change)="searchinobqvi(y.value,'title')"> -->
                <!-- <select class="form-control"  #g (change)="searchinobqvi(g.value,'town')" formControlName="oblastgrad"  >   -->
                <select class="form-control" #g (change)="changeFilter()" formControlName="oblastgrad">
                  <option selected value="">Всички</option>
                  <option *ngFor="let gr of gradoblastcat" value="{{ gr }}">{{ gr }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row blever" style="border-radius: 10px">
            <div class="col">
              <div class="row m-1">
                <button class="btn btn-primary m-1" (click)="openRaionmodal()">Райони</button>
              </div>
              <div class="row m-1" *ngFor="let r of chosenRaioni">
                {{ r }}
              </div>
            </div>
          </div>
          <div class="row blever" style="border-radius: 10px">
            <div class="col">
              <div class="row m-1">
                <button class="btn btn-primary m-1" (click)="openTipImotModal()">Тип имот</button>
              </div>
              <div class="row m-1" *ngFor="let r of chosenTipImot">
                {{ r }}
              </div>
            </div>
          </div>
          <div class="row blever" style="border-radius: 10px">
            <div class="col">
              <div class="row m-1">
                <label>Квадратура в кв.м.:</label>
              </div>

              <div class="row m-1">
                <label>от:</label>
                <input class="form-control" type="number" (change)="changeFilter()" formControlName="kv_ot" />
              </div>
              <div class="row m-1">
                <label>до:</label>
                <input class="form-control" type="number" (change)="changeFilter()" formControlName="kv_do" />
              </div>
            </div>
          </div>
          <div class="row blever" style="border-radius: 10px">
            <div class="col">
              <div class="row m-1">
                <label>Търси по телефон:</label>

                <input class="form-control" type="tel" (change)="changeFilter()" formControlName="telefon" />
              </div>
            </div>
          </div>
          <div class="row blever" style="border-radius: 10px">
            <div class="col">
              <div class="row m-1">
                <label> Сортирай по:</label>

                <select class="form-control" (change)="changeFilter()" formControlName="sortby">
                  <option selectед value="0">Най-нови</option>
                  <option select value="1">Цена</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row blever" style="border-radius: 10px; text-align: -webkit-center">
            <div class="col">
              <button class="btn btn-primary m-1" (click)="changeFilter()">Филтрирай</button>
            </div>
          </div>
        </form>
      </div>
      <div class="row centercont">
        <button class="btn btn-primary m-1" (click)="colapsefiltermobile = !colapsefiltermobile">
          Филтър
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-caret-down-square"
            viewBox="0 0 16 16"
          >
            <path d="M3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z" />
            <path
              d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2z"
            />
          </svg>
        </button>
      </div>
      <div class="container-fluid sticky-top collapse" [ngbCollapse]="colapsefiltermobile" style="top: 90px">
        <div class="row blever">
          <div class="col">
            <div class="row centercontent m-1">
              <button class="btn btn-primary m-1" (click)="clearfilter()">Ново търсене</button>
            </div>
          </div>
        </div>

        <div class="row blever">
          <div class="col">
            <div class="row centercontent m-1">
              <button class="btn btn-primary m-1" (click)="openContactsfilter()">Филтър по контакт</button>
            </div>
          </div>
        </div>
        <div class="row blever">
          <div class="col">
            <div class="row centercontent m-1">
              <button class="btn btn-primary" (click)="privatefilter()">Личен филтър</button>
              <a routerLink="/userpage" class="m-2">Настрой личен филтър</a>
            </div>
          </div>
        </div>

        <form [formGroup]="tyrsachkadanni">
          <div class="row blever" style="border-radius: 10px">
            <div class="col">
              <div class="row m-1">
                <label> Тип обява:</label>

                <select class="form-control" (change)="changeFilter()" formControlName="tipobqva">
                  <option selectед value="">Всички обяви</option>
                  <option select value="chasna">Само частни обяви</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row blever" style="border-radius: 10px">
            <div class="col">
              <div class="row m-1">
                <label>Област или областен град:</label>
                <!-- <input type="text" class="form-control" #y (change)="searchinobqvi(y.value,'title')"> -->
                <!-- <select class="form-control"  #g (change)="searchinobqvi(g.value,'town')" formControlName="oblastgrad"  >   -->
                <select class="form-control" #g (change)="getraions(g.value)" formControlName="oblastgrad">
                  <option selected value="">Всички</option>
                  <option *ngFor="let gr of gradoblastcat" value="{{ gr }}">{{ gr }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row blever" style="border-radius: 10px">
            <div class="col">
              <div class="row m-1">
                <button class="btn btn-primary m-1" (click)="openRaionmodal()">Райони</button>
              </div>
              <div class="row m-1" *ngFor="let r of chosenRaioni">
                {{ r }}
              </div>
            </div>
          </div>
          <div class="row blever" style="border-radius: 10px">
            <div class="col">
              <div class="row m-1">
                <button class="btn btn-primary m-1" (click)="openTipImotModal()">Тип имот</button>
              </div>
              <div class="row m-1" *ngFor="let r of chosenTipImot">
                {{ r }}
              </div>
            </div>
          </div>
          <div class="row blever" style="border-radius: 10px">
            <div class="col">
              <div class="row m-1">
                <label>Квадратура в кв.м.:</label>
              </div>

              <div class="row m-1">
                <label>от:</label>
                <input class="form-control" type="number" (change)="changeFilter()" formControlName="kv_ot" />
              </div>
              <div class="row m-1">
                <label>до:</label>
                <input class="form-control" type="number" (change)="changeFilter()" formControlName="kv_do" />
              </div>
            </div>
          </div>
          <div class="row blever" style="border-radius: 10px">
            <div class="col">
              <div class="row m-1">
                <label>Търси по телефон:</label>

                <input class="form-control" type="tel" (change)="changeFilter()" formControlName="telefon" />
              </div>
            </div>
          </div>
          <div class="row blever" style="border-radius: 10px">
            <div class="col">
              <div class="row m-1">
                <label> Сортирай по:</label>

                <select class="form-control" (change)="changeFilter()" formControlName="sortby">
                  <option selectед value="0">Най-нови</option>
                  <option select value="1">Цена</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row blever" style="border-radius: 10px; text-align: -webkit-center">
            <div class="col">
              <button class="btn btn-primary m-1" (click)="changeFilter()">Филтрирай</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="col-lg-10">
      <div class="row" #Forscroll>
        <div class="spinner-border" *ngIf="pleasewait"></div>
        <div *ngIf="pleasewait">Претърсваме сайта за Вас. Моля изчакайте...</div>
      </div>
      <div class="row centercont" style="margin-left: 0px">
        <div class="col-xl-6" *ngFor="let obqvi of obqvisort | paginate: { itemsPerPage: 20, currentPage: page }">
          <div class="row" style="min-height: 207px">
            <div
              class="col p-1 blever hovercol"
              style="border-radius: 5px; overflow-wrap: anywhere; margin-right: 10px"
            >
              <div
                class="row bor m-1"
                style="background-color: white; border-radius: 5px; min-height: 181px; cursor: pointer"
              >
                <div
                  class="col-sm"
                  style="max-width: 230px"
                  (click)="savescrollpos()"
                  [routerLink]="['/imot', obqvi.id]"
                >
                  <img class="fill" [src]="obqvi.picture" />
                </div>
                <div class="col-sm">
                  <div class="row" style="font-weight: bolder; padding-right: 10px; font-size: x-large">
                    <div
                      class="col-11"
                      style="text-align: right"
                      (click)="savescrollpos()"
                      [routerLink]="['/imot', obqvi.id]"
                    >
                      {{ obqvi.price }} {{ obqvi.currency }}
                    </div>
                    <div class="col-1" style="padding: 0" *ngIf="!obqvi.favour" (click)="savefav(obqvi.id)">
                      <svg
                        style="vertical-align: baseline"
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        class="bi bi-star"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"
                        />
                      </svg>
                    </div>
                    <div class="col-1" style="padding: 0" *ngIf="obqvi.favour" (click)="deletefav(obqvi.id)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        class="bi bi-star-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
                        />
                      </svg>
                    </div>
                  </div>

                  <div class="row" (click)="savescrollpos()" [routerLink]="['/imot', obqvi.id]">
                    <!-- <a [attr.href]="obqvi.link" target="_blank"><p style="font-size: large;" > 
                                                {{obqvi.title}},{{obqvi.quadrature}}кв.м.,{{obqvi.town}},{{obqvi.raion}}
                                                </p></a> -->
                    <p style="font-size: large; font-weight: bold">
                      {{ obqvi.title }},{{ obqvi.quadrature }}кв.м.,{{ obqvi.town }},{{ obqvi.raion }}
                    </p>
                  </div>

                  <div class="row" (click)="savescrollpos()" [routerLink]="['/imot', obqvi.id]">
                    Телефон: {{ obqvi.phone }}
                  </div>
                  <div class="row" (click)="savescrollpos()" [routerLink]="['/imot', obqvi.id]">
                    Дата и час: {{ obqvi.data }} в {{ obqvi.time }}
                  </div>
                  <div
                    class="row"
                    *ngIf="obqvi.wichsite == 1"
                    (click)="savescrollpos()"
                    [routerLink]="['/imot', obqvi.id]"
                  >
                    Обява на imot.bg
                  </div>
                  <div
                    class="row"
                    *ngIf="obqvi.wichsite == 2"
                    (click)="savescrollpos()"
                    [routerLink]="['/imot', obqvi.id]"
                  >
                    Обява на olx.bg
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="p-1 blever hovercol" style="border-radius: 5px" *ngFor="let obqvi of obqvisort | paginate: { itemsPerPage: 20, currentPage: page} ">
                    <div class="row bor m-1" style="background-color: white;border-radius: 5px;" >
                        <div class="col-sm" style="max-width: 230px;" [routerLink]="['/imot',obqvi.id]">
                            <img class="fill" [src]="obqvi.picture">
                        </div>
                        <div class="col-sm " [routerLink]="['/imot',obqvi.id]" (click)="savescrollpos()">
                            <div class="row">
                                <a [attr.href]="obqvi.link" target="_blank"><p style="font-size: large;" > 
                                    {{obqvi.title}},{{obqvi.quadrature}}кв.м.,{{obqvi.town}},{{obqvi.raion}}
                                    </p></a>
                            </div>
                            <div class="row">
                                {{obqvi.price}} {{obqvi.currency}}
                            </div>
                            <div class="row">
                                Телефон: {{obqvi.phone}}
                            </div>
                            <div class="row">
                                Дата и час: {{obqvi.data}} в {{obqvi.time}}
                            </div>
                        </div>
                        <div class="col-sm-3">
                            ако е запазен да излиза бележка
                            ако не да излиза запази бутон
                        </div>

                         <div class="col-sm-3">
                            <div class="row">
                                <button class="btn btn-primary m-1">Запази</button>
                            </div>
                            <div class="row">
                                <button class="btn btn-primary m-1">Премахни</button>
                            </div>
                        </div> 
                        
                        
                    
                    </div>
                </div> -->
    </div>
  </div>
  <div class="row" style="justify-content: center">
    <pagination-controls
      #t
      (pageChange)="onPageChange($event)"
      (pageBoundsCorrection)="page = $event"
      previousLabel="Предишни"
      nextLabel="Следващи"
    ></pagination-controls>
  </div>
  <div class="row mt-4 mb-4"></div>
  <app-scroll-to-top></app-scroll-to-top>
</div>
