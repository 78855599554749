<h1 mat-dialog-title>Райони</h1>
<div mat-dialog-content *ngIf="raionOrTip">
  <div class="container-fluid">
    <div class="row" *ngIf="!formobile">
      <div class="col">
        <div class="col-12" *ngFor="let r of raions; let i = index">
          <div class="form-check" *ngIf="raions.length / 2 > i">
            <input
              class="form-check-input"
              [checked]="r.cheked"
              type="checkbox"
              value="{{ r.raion }}"
              id="flexCheckDefault"
              (change)="checkCheckBoxvalue($event, i)"
            />
            <label class="form-check-label" for="flexCheckDefault">
              {{ r.raion }}
            </label>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="col-12" *ngFor="let r of raions; let i = index">
          <div class="form-check" *ngIf="raions.length / 2 < i">
            <input
              class="form-check-input"
              [checked]="r.cheked"
              type="checkbox"
              value="{{ r.raion }}"
              id="flexCheckDefault"
              (change)="checkCheckBoxvalue($event, i)"
            />
            <label class="form-check-label" for="flexCheckDefault">
              {{ r.raion }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="formobile">
      <div class="col-12" *ngFor="let r of raions; let i = index">
        <div class="form-check">
          <input
            class="form-check-input"
            [checked]="r.cheked"
            type="checkbox"
            value="{{ r.raion }}"
            id="flexCheckDefault"
            (change)="checkCheckBoxvalue($event, i)"
          />
          <label class="form-check-label" for="flexCheckDefault">
            {{ r.raion }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="textforshow">
    <p style="color: red">Не може повече от четири</p>
  </div>
</div>
<!-- ot tuk zapochva za tip -->
<div mat-dialog-content *ngIf="!raionOrTip">
  <div class="container-fluid">
    <div class="row" *ngIf="!formobile">
      <div class="col">
        <div class="col-12" *ngFor="let r of tipimots; let i = index">
          <div class="form-check" *ngIf="tipimots.length / 2 > i">
            <input
              class="form-check-input"
              [checked]="r.cheked"
              type="checkbox"
              value="{{ r.raion }}"
              id="flexCheckDefault"
              (change)="checkCheckBoxvalue($event, i)"
            />
            <label class="form-check-label" for="flexCheckDefault">
              {{ r.raion }}
            </label>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="col-12" *ngFor="let r of tipimots; let i = index">
          <div class="form-check" *ngIf="tipimots.length / 2 < i">
            <input
              class="form-check-input"
              [checked]="r.cheked"
              type="checkbox"
              value="{{ r.raion }}"
              id="flexCheckDefault"
              (change)="checkCheckBoxvalue($event, i)"
            />
            <label class="form-check-label" for="flexCheckDefault">
              {{ r.raion }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="formobile">
      <div class="col-12" *ngFor="let r of tipimots; let i = index">
        <div class="form-check">
          <input
            class="form-check-input"
            [checked]="r.cheked"
            type="checkbox"
            value="{{ r.raion }}"
            id="flexCheckDefault"
            (change)="checkCheckBoxvalue($event, i)"
          />
          <label class="form-check-label" for="flexCheckDefault">
            {{ r.raion }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="textforshow">
    <p style="color: red">Не може повече от четири</p>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="ok()">Затвори</button>

  <button mat-button (click)="ok()" cdkFocusInitial>ОК</button>
</div>
