import { IsMobileService } from './../../services/is-mobile.service';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestsService } from 'src/app/services/requests.service';

@Component({
  selector: 'app-raionmodal',
  templateUrl: './raionmodal.component.html',
  styleUrls: ['./raionmodal.component.css'],
})
export class RaionmodalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private req: RequestsService,
    private dialogRef: MatDialogRef<RaionmodalComponent>,
    private mobile: IsMobileService
  ) {}

  raions: any[] = [];
  formobile = false;
  numofcheked = 0;
  textforshow = false;
  forRetRaions: any[] = [];
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    event.target.innerWidth;

    if (event.target.innerWidth < 990) {
      this.formobile = true;
    } else {
      this.formobile = false;
    }
  }
  ngOnInit(): void {
    if (this.mobile.isMobile()) {
      this.formobile = true;
    } else {
      this.formobile = false;
    }

    let arr: any[] = this.data.type;

    this.raions = arr.map((e) => {
      let ret;
      ret = {
        raion: e,
        ischek: false,
      };
      if (this.data.from == 'all') {
        if (sessionStorage.getItem('raion') == e && e != '') {
          this.forRetRaions.push(e);
          this.numofcheked++;
          ret = {
            raion: e,
            ischek: true,
          };
        } else if (sessionStorage.getItem('raion0') == e && e != '') {
          this.forRetRaions.push(e);
          this.numofcheked++;
          ret = {
            raion: e,
            ischek: true,
          };
        } else if (sessionStorage.getItem('raion1') == e && e != '') {
          this.forRetRaions.push(e);
          this.numofcheked++;
          ret = {
            raion: e,
            ischek: true,
          };
        } else if (sessionStorage.getItem('raion2') == e && e != '') {
          this.forRetRaions.push(e);
          this.numofcheked++;
          ret = {
            raion: e,
            ischek: true,
          };
        } else if (sessionStorage.getItem('raion3') == e && e != '') {
          this.forRetRaions.push(e);
          this.numofcheked++;
          ret = {
            raion: e,
            ischek: true,
          };
        } else if (sessionStorage.getItem('raion4') == e && e != '') {
          this.forRetRaions.push(e);
          this.numofcheked++;
          ret = {
            raion: e,
            ischek: true,
          };
        } else if (sessionStorage.getItem('raion5') == e && e != '') {
          this.forRetRaions.push(e);
          this.numofcheked++;
          ret = {
            raion: e,
            ischek: true,
          };
        } else if (sessionStorage.getItem('raion6') == e && e != '') {
          this.forRetRaions.push(e);
          this.numofcheked++;
          ret = {
            raion: e,
            ischek: true,
          };
        }
        if (this.numofcheked == 6) {
          this.textforshow = true;
        }
        return ret;
      }
      if (this.data.from == 'zap') {
        if (sessionStorage.getItem('Zapraion') == e && e != '') {
          this.forRetRaions.push(e);
          this.numofcheked++;
          ret = {
            raion: e,
            ischek: true,
          };
        } else if (sessionStorage.getItem('Zapraion0') == e && e != '') {
          this.forRetRaions.push(e);
          this.numofcheked++;
          ret = {
            raion: e,
            ischek: true,
          };
        } else if (sessionStorage.getItem('Zapraion1') == e && e != '') {
          this.forRetRaions.push(e);
          this.numofcheked++;
          ret = {
            raion: e,
            ischek: true,
          };
        } else if (sessionStorage.getItem('Zapraion2') == e && e != '') {
          this.forRetRaions.push(e);
          this.numofcheked++;
          ret = {
            raion: e,
            ischek: true,
          };
        } else if (sessionStorage.getItem('Zapraion3') == e && e != '') {
          this.forRetRaions.push(e);
          this.numofcheked++;
          ret = {
            raion: e,
            ischek: true,
          };
        } else if (sessionStorage.getItem('Zapraion4') == e && e != '') {
          this.forRetRaions.push(e);
          this.numofcheked++;
          ret = {
            raion: e,
            ischek: true,
          };
        } else if (sessionStorage.getItem('Zapraion5') == e && e != '') {
          this.forRetRaions.push(e);
          this.numofcheked++;
          ret = {
            raion: e,
            ischek: true,
          };
        } else if (sessionStorage.getItem('Zapraion6') == e && e != '') {
          this.forRetRaions.push(e);
          this.numofcheked++;
          ret = {
            raion: e,
            ischek: true,
          };
        }
        if (this.numofcheked == 6) {
          this.textforshow = true;
        }
        return ret;
      }
      return ret;
    });
  }
  checkCheckBoxvalue(chek: any, index: any) {
    // console.log(index);

    // console.log(chek.target.checked);
    // console.log(chek.target.defaultValue);
    if (chek.target.checked) {
      if (this.numofcheked < 8) {
        this.textforshow = false;
        this.forRetRaions.push(chek.target.defaultValue);
        this.numofcheked++;
        this.raions[index].ischek = true;
      }
      if (this.numofcheked == 8) {
        this.textforshow = true;
      }
    } else {
      this.numofcheked--;
      this.textforshow = false;
      this.raions[index].ischek = false;
      this.forRetRaions = this.forRetRaions.filter((e) => {
        return e != chek.target.defaultValue;
      });
    }
  }
  ok() {
    if (this.data.from == 'all') {
      switch (this.forRetRaions.length) {
        case 0:
          {
            sessionStorage.setItem('raion', '');
            sessionStorage.setItem('raion0', '');
            sessionStorage.setItem('raion1', '');
            sessionStorage.setItem('raion2', '');
            sessionStorage.setItem('raion3', '');
            sessionStorage.setItem('raion4', '');
            sessionStorage.setItem('raion5', '');
            sessionStorage.setItem('raion6', '');
          }
          break;
        case 1:
          {
            sessionStorage.setItem('raion', this.forRetRaions[0]);
            sessionStorage.setItem('raion0', '');
            sessionStorage.setItem('raion1', '');
            sessionStorage.setItem('raion2', '');
            sessionStorage.setItem('raion3', '');
            sessionStorage.setItem('raion4', '');
            sessionStorage.setItem('raion5', '');
            sessionStorage.setItem('raion6', '');
          }
          break;
        case 2:
          {
            sessionStorage.setItem('raion', this.forRetRaions[0]);
            sessionStorage.setItem('raion0', this.forRetRaions[1]);
            sessionStorage.setItem('raion1', '');
            sessionStorage.setItem('raion2', '');
            sessionStorage.setItem('raion3', '');
            sessionStorage.setItem('raion4', '');
            sessionStorage.setItem('raion5', '');
            sessionStorage.setItem('raion6', '');
          }
          break;
        case 3:
          {
            sessionStorage.setItem('raion', this.forRetRaions[0]);
            sessionStorage.setItem('raion0', this.forRetRaions[1]);
            sessionStorage.setItem('raion1', this.forRetRaions[2]);
            sessionStorage.setItem('raion2', '');
            sessionStorage.setItem('raion3', '');
            sessionStorage.setItem('raion4', '');
            sessionStorage.setItem('raion5', '');
            sessionStorage.setItem('raion6', '');
          }
          break;
        case 4:
          {
            sessionStorage.setItem('raion', this.forRetRaions[0]);
            sessionStorage.setItem('raion0', this.forRetRaions[1]);
            sessionStorage.setItem('raion1', this.forRetRaions[2]);
            sessionStorage.setItem('raion2', this.forRetRaions[3]);
            sessionStorage.setItem('raion3', '');
            sessionStorage.setItem('raion4', '');
            sessionStorage.setItem('raion5', '');
            sessionStorage.setItem('raion6', '');
          }
          break;
        case 5:
          {
            sessionStorage.setItem('raion', this.forRetRaions[0]);
            sessionStorage.setItem('raion0', this.forRetRaions[1]);
            sessionStorage.setItem('raion1', this.forRetRaions[2]);
            sessionStorage.setItem('raion2', this.forRetRaions[3]);
            sessionStorage.setItem('raion3', this.forRetRaions[4]);
            sessionStorage.setItem('raion4', '');
            sessionStorage.setItem('raion5', '');
            sessionStorage.setItem('raion6', '');
          }
          break;
        case 6:
          {
            sessionStorage.setItem('raion', this.forRetRaions[0]);
            sessionStorage.setItem('raion0', this.forRetRaions[1]);
            sessionStorage.setItem('raion1', this.forRetRaions[2]);
            sessionStorage.setItem('raion2', this.forRetRaions[3]);
            sessionStorage.setItem('raion3', this.forRetRaions[4]);
            sessionStorage.setItem('raion4', this.forRetRaions[5]);
            sessionStorage.setItem('raion5', '');
            sessionStorage.setItem('raion6', '');
          }
          break;
        case 7:
          {
            sessionStorage.setItem('raion', this.forRetRaions[0]);
            sessionStorage.setItem('raion0', this.forRetRaions[1]);
            sessionStorage.setItem('raion1', this.forRetRaions[2]);
            sessionStorage.setItem('raion2', this.forRetRaions[3]);
            sessionStorage.setItem('raion3', this.forRetRaions[4]);
            sessionStorage.setItem('raion4', this.forRetRaions[5]);
            sessionStorage.setItem('raion5', this.forRetRaions[6]);
            sessionStorage.setItem('raion6', '');
          }
          break;

        case 8:
          {
            sessionStorage.setItem('raion', this.forRetRaions[0]);
            sessionStorage.setItem('raion0', this.forRetRaions[1]);
            sessionStorage.setItem('raion1', this.forRetRaions[2]);
            sessionStorage.setItem('raion2', this.forRetRaions[3]);
            sessionStorage.setItem('raion3', this.forRetRaions[4]);
            sessionStorage.setItem('raion4', this.forRetRaions[5]);
            sessionStorage.setItem('raion5', this.forRetRaions[6]);
            sessionStorage.setItem('raion6', this.forRetRaions[7]);
          }
          break;
      }
    }
    if (this.data.from == 'zap') {
      switch (this.forRetRaions.length) {
        case 0:
          {
            sessionStorage.setItem('Zapraion', '');
            sessionStorage.setItem('Zapraion0', '');
            sessionStorage.setItem('Zapraion1', '');
            sessionStorage.setItem('Zapraion2', '');
            sessionStorage.setItem('Zapraion3', '');
            sessionStorage.setItem('Zapraion4', '');
            sessionStorage.setItem('Zapraion5', '');
            sessionStorage.setItem('Zapraion6', '');
          }
          break;
        case 1:
          {
            sessionStorage.setItem('Zapraion', this.forRetRaions[0]);
            sessionStorage.setItem('Zapraion0', '');
            sessionStorage.setItem('Zapraion1', '');
            sessionStorage.setItem('Zapraion2', '');
            sessionStorage.setItem('Zapraion3', '');
            sessionStorage.setItem('Zapraion4', '');
            sessionStorage.setItem('Zapraion5', '');
            sessionStorage.setItem('Zapraion6', '');
          }
          break;
        case 2:
          {
            sessionStorage.setItem('Zapraion', this.forRetRaions[0]);
            sessionStorage.setItem('Zapraion0', this.forRetRaions[1]);
            sessionStorage.setItem('Zapraion1', '');
            sessionStorage.setItem('Zapraion2', '');
            sessionStorage.setItem('Zapraion3', '');
            sessionStorage.setItem('Zapraion4', '');
            sessionStorage.setItem('Zapraion5', '');
            sessionStorage.setItem('Zapraion6', '');
          }
          break;
        case 3:
          {
            sessionStorage.setItem('Zapraion', this.forRetRaions[0]);
            sessionStorage.setItem('Zapraion0', this.forRetRaions[1]);
            sessionStorage.setItem('Zapraion1', this.forRetRaions[2]);
            sessionStorage.setItem('Zapraion2', '');
            sessionStorage.setItem('Zapraion3', '');
            sessionStorage.setItem('Zapraion4', '');
            sessionStorage.setItem('Zapraion5', '');
            sessionStorage.setItem('Zapraion6', '');
          }
          break;
        case 4:
          {
            sessionStorage.setItem('Zapraion', this.forRetRaions[0]);
            sessionStorage.setItem('Zapraion0', this.forRetRaions[1]);
            sessionStorage.setItem('Zapraion1', this.forRetRaions[2]);
            sessionStorage.setItem('Zapraion2', this.forRetRaions[3]);
            sessionStorage.setItem('Zapraion3', '');
            sessionStorage.setItem('Zapraion4', '');
            sessionStorage.setItem('Zapraion5', '');
            sessionStorage.setItem('Zapraion6', '');
          }
          break;
        case 5:
          {
            sessionStorage.setItem('Zapraion', this.forRetRaions[0]);
            sessionStorage.setItem('Zapraion0', this.forRetRaions[1]);
            sessionStorage.setItem('Zapraion1', this.forRetRaions[2]);
            sessionStorage.setItem('Zapraion2', this.forRetRaions[3]);
            sessionStorage.setItem('Zapraion3', this.forRetRaions[4]);
            sessionStorage.setItem('Zapraion4', '');
            sessionStorage.setItem('Zapraion5', '');
            sessionStorage.setItem('Zapraion6', '');
          }
          break;
        case 6:
          {
            sessionStorage.setItem('Zapraion', this.forRetRaions[0]);
            sessionStorage.setItem('Zapraion0', this.forRetRaions[1]);
            sessionStorage.setItem('Zapraion1', this.forRetRaions[2]);
            sessionStorage.setItem('Zapraion2', this.forRetRaions[3]);
            sessionStorage.setItem('Zapraion3', this.forRetRaions[4]);
            sessionStorage.setItem('Zapraion4', this.forRetRaions[5]);
            sessionStorage.setItem('Zapraion5', '');
            sessionStorage.setItem('Zapraion6', '');
          }
          break;
        case 7:
          {
            sessionStorage.setItem('Zapraion', this.forRetRaions[0]);
            sessionStorage.setItem('Zapraion0', this.forRetRaions[1]);
            sessionStorage.setItem('Zapraion1', this.forRetRaions[2]);
            sessionStorage.setItem('Zapraion2', this.forRetRaions[3]);
            sessionStorage.setItem('Zapraion3', this.forRetRaions[4]);
            sessionStorage.setItem('Zapraion4', this.forRetRaions[5]);
            sessionStorage.setItem('Zapraion5', this.forRetRaions[6]);
            sessionStorage.setItem('Zapraion6', '');
          }
          break;

        case 8:
          {
            sessionStorage.setItem('Zapraion', this.forRetRaions[0]);
            sessionStorage.setItem('Zapraion0', this.forRetRaions[1]);
            sessionStorage.setItem('Zapraion1', this.forRetRaions[2]);
            sessionStorage.setItem('Zapraion2', this.forRetRaions[3]);
            sessionStorage.setItem('Zapraion3', this.forRetRaions[4]);
            sessionStorage.setItem('Zapraion4', this.forRetRaions[5]);
            sessionStorage.setItem('Zapraion5', this.forRetRaions[6]);
            sessionStorage.setItem('Zapraion6', this.forRetRaions[7]);
          }
          break;
      }
    }

    this.dialogRef.close(this.forRetRaions);
  }
}
