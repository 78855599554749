
<div class="container-lg">
    <app-menu></app-menu>
    <div class="container-fluid   sticky-top" style="top: 66px;background-color: white;">
        <div class="row centercontent m-2">
            Намирате се в {{map}}
        </div>

    </div>
    <div class="container-fluid sticky-top" style=" top:90px;background-color: darkgray;border-radius: 5px;
    box-shadow: 0 4px 12px -4px rgb(0 0 0 / 75%);">
        <div class="row centercontent m-2">
            Филтър:
        </div>
        <div class="row">
            <div class="col" >
                
                
                <button *ngIf="allactive" class="btn btn-success m-1" (click)="allobqvi()" >Всички обяви </button>
                <button *ngIf="!allactive" class="btn btn-primary m-1" (click)="allobqvi()" >Всички обяви </button>
            
                <button *ngIf="chasniactive" class="btn btn-success m-1" (click)="chasniobqvi()">Само частни обяви</button>
                <button *ngIf="!chasniactive" class="btn btn-primary m-1" (click)="chasniobqvi()">Само частни обяви</button>
            </div>
        </div>   
    </div>
    
    <div class="row m-2">
        <div class="spinner-border" *ngIf="pleasewait"></div>
        <div  *ngIf="pleasewait">
            Претърсваме сайта за Вас.
            Моля изчакайте...
        </div>
    </div>
    <div *ngIf="allactive" class="blever" style="background-color:darkgray;border-radius: 5px;">
        <div class="p-1 blever" style="border-radius: 5px" *ngFor="let title of obqvi.title; index as i ">
            <div class="row bor m-1" style="background-color: white;border-radius: 5px;">
                <div class="col-sm" style="max-width: 230px;">
                    <img class="fill" [src]="obqvi.img[i]">
                </div>
                <div class="col-sm ">
                    <div class="row">
                        <a [attr.href]="obqvi.link[i]" target="_blank"><p style="font-size: large;">  {{title}}</p></a>
                    </div>
                    <div class="row">
                        {{obqvi.price[i]}}
                    </div>
                    <div class="row">
                        Телефон: {{obqvi.tel[i]}}
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="row">
                        <button class="btn btn-primary m-1">Запази</button>
                    </div>
                    <div class="row">
                        <button class="btn btn-primary m-1">Премахни</button>
                    </div>
                </div>
                
                
            
            </div>
        </div>
    </div> 
    <div *ngIf="chasniactive" class="blever" style="background-color:darkgray;border-radius: 5px;">
        <div class="p-1 blever" style="border-radius: 5px" *ngFor="let titlech of obqvi.chasnititle; index as i ">
            <div class="row bor m-1" style="background-color: white;border-radius: 5px;">
                <div class="col-sm" style="max-width: 230px;">
                    <img class="fill" [src]="obqvi.chasniimg[i]">
                </div>
                <div class="col-sm ">
                    <div class="row">
                        <a [attr.href]="obqvi.chasnilink[i]" target="_blank"> <p style="font-size: large;"> {{titlech}}</p></a>
                    </div>
                    <div class="row">
                        {{obqvi.chasniprice[i]}}
                    </div>
                    <div class="row">
                        Телефон: {{obqvi.chasnitel[i]}}
                    </div>
                </div>
                

                <div class="col-sm-3">
                    <div class="row">
                        <button class="btn btn-primary m-1">Запази</button>
                    </div>
                    <div class="row">
                        <button class="btn btn-primary m-1">Премахни</button>
                    </div>
                </div>
                
            
            </div>
        </div>
    </div>  
    <div class="row m-2">
        <div class="col">
            <button class="btn btn-primary" (click)="prevPage()" >Предишна страница </button>
        </div>
        <div class="col">
            Страница: {{lastparamimotbg}}
        </div>
        <div class="col">
            <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" (click)="prevPage()">Предишна</a></li>
                  <li class="page-item"><a class="page-link" >1</a></li>
                  <li class="page-item"><a class="page-link" >2</a></li>
                  <li class="page-item"><a class="page-link" >3</a></li>
                  <li class="page-item"><a class="page-link" (click)="nextPage()">Следваща</a></li>
                </ul>
              </nav>
        </div>
        <div class="col">
            <button class="btn btn-primary" (click)="nextPage()" >Следваща страница </button>
        </div>
        
    </div> 
   <app-scroll-to-top></app-scroll-to-top>
</div>