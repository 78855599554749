<h1 mat-dialog-title>Избери контакт</h1>
<div mat-dialog-content>
  <div class="container-fluid" *ngIf="!formobile">
    <div class="row" *ngFor="let cont of contact">
      <div class="col-6" style="cursor: pointer" (click)="izbrankontakt(cont.id)">
        {{ cont.contactName }}
      </div>
      <div class="col">
        {{ cont.contactPhone }}
      </div>
    </div>
  </div>
  <div class="container-fluid" *ngIf="formobile">
    <div class="row" *ngFor="let cont of contact">
      <div class="col-6" style="cursor: pointer" (click)="izbrankontakt(cont.id)">
        {{ cont.contactName }}
      </div>
      <div class="col">
        {{ cont.contactPhone }}
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Затвори</button>

  <button mat-button mat-dialog-close cdkFocusInitial>ОК</button>
</div>
