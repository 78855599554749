<div class="row m-2" >
    <div class="col-sm" style="max-width: 300px;width: 300px;height: 105px;">
        <img src="../../../assets/logo/logo.png" routerLink='/' style="width: 100%; height: 100%; cursor: pointer;">
    </div>
    <div class="col-sm" *ngIf="isloged" style="align-self: flex-end;text-align: right;">
         <button class="btn btn-primary m-1 " routerLink='/login'> Вход</button>
         <button class="btn btn-primary" > Регистрация</button>
    </div>
    <div class="col-sm" *ngIf="!isloged" style="align-self: flex-end;text-align: right;">
      <button class="btn btn-primary m-1 " routerLink='/userpage'> 
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
      </svg></button>
      <button class="btn btn-primary m-1 " (click)="loguot()"> Изход</button>
      <button class="btn btn-primary m-1 " routerLink="/obshtiuslovia"> Общи условия</button>
    </div>
</div>


  <nav class="navbar navbar-expand-lg navbar-light sticky-top blever" style="border-radius: 5px;background-color: darkgray;z-index: 1500;">
    
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <!-- <li class="nav-item active">
          <a class="nav-link btn btn-primary m-1" type="button" (click)="routeto('alobg')">Най-нови в ALO.bg</a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn btn-primary m-1" type="button" (click)="routeto('imotbg')">Най-нови в Imot.bg</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link btn btn-primary m-1" type="button" routerLink="/obqvidb">Продава имот</a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn btn-primary m-1" type="button">Под наем</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link btn btn-primary m-1" type="button">Персонално търсене и филтри</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link btn btn-primary m-1" *appIsUser type="button" routerLink="/zapisaniob" >Запазени обяви</a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn btn-primary m-1" *appIsAdmin type="button" routerLink="/zapisaniob">Запазени обяви</a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn btn-primary m-1" *appIsUser type="button" >Собствени обяви</a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn btn-primary m-1" *appIsAdmin type="button" >Собствени обяви</a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn btn-primary m-1" *appIsUser type="button" routerLink="/mycontacts" >Мои контакти</a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn btn-primary m-1" *appIsAdmin type="button" routerLink="/mycontacts" >Мои контакти</a>
        </li>
      </ul>
    </div>
  </nav>
