import { Directive, ElementRef, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../Authguard/auth.service';

@Directive({
  selector: '[appIsAdmin]'
})
export class IsAdminDirective {

  constructor(public elementRef: ElementRef, private renderer: Renderer2, private auth: AuthService, 
    private templateRef: TemplateRef<void>,private _viewContainer: ViewContainerRef) {
      
   //this.elementRef.nativeElement.style.display = 'none';
    this._viewContainer.clear();

    
    if(this.auth.isAuthenticated()){
     
      if(this.auth.isAdmin()){
      //this.renderer.setStyle(this.elementRef.nativeElement,'visibility','hidden');
      //this.elementRef.nativeElement.style.display =  "block";
      this._viewContainer.createEmbeddedView(this.templateRef);
      }else{
        //this.elementRef.nativeElement.style.display = 'none';
        this._viewContainer.clear();
      }
    }
   }

}
