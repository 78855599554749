<div class="container-fluid pb-4">
  <app-menu></app-menu>
  <div class="container-lg blever backdeign mt-3">
    <div class="container-fluid whiteback p-2">
      <div class="row centercont">
        <div class="col">
          <h4><strong>Контакти</strong></h4>
        </div>
      </div>
      <div class="row centercont">
        <div class="col">
          <button class="btn btn-primary m-1" (click)="addContact()">Добави контакт</button>
        </div>
      </div>

      <div class="row table-responsive m-2 pr-2">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Име</th>
              <th scope="col">Телефон</th>
              <th scope="col">Имейл</th>
              <th scope="col">Изтрий</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th><input type="text" class="form-control" #ime (change)="searchkont('name', ime.value)" /></th>
              <th><input type="text" class="form-control" #tel (change)="searchkont('telefon', tel.value)" /></th>
              <th><input type="text" class="form-control" #mail (change)="searchkont('email', mail.value)" /></th>
              <th></th>
            </tr>
            <tr *ngFor="let kon of sortcontacts">
              <th>
                <a style="cursor: pointer" (click)="showContactInfo(kon.id)">{{ kon.contactName }}</a>
              </th>
              <th>{{ kon.contactPhone }}</th>
              <th>{{ kon.contactEmail }}</th>
              <th><button class="btn btn-primary m-1" (click)="deleteContact(kon.id)">Изтрий</button></th>
            </tr>
          </tbody>
          <tfoot>
            <tr></tr>
          </tfoot>
        </table>
      </div>
      <div class="row-sm">
        <!-- <button class="btn btn-dark" type="button" >Добави контрагент</button> -->
      </div>
    </div>
  </div>

  <app-scroll-to-top></app-scroll-to-top>
</div>
